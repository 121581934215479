import { useStyles } from "./styles";
import Button from "../../../components/fields/button";
import { useLazyQuery, useMutation } from "@apollo/client";
import { getFutureScanDetails, addFutureScan } from "../../../apollo/operations/futureScan";
import { useEffect, useState } from "react";
import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import IconComponent from "../../../components/fields/icon";
import { openToast } from "../../../components/toast";
import ModalPortal from "../../../containers/common/modalPortal";
import AddEmailPopup from "./addEmailPopup";
import Modal from "../../../containers/common/modalPortal/modal";
import { getAuthUrl } from "../../../apollo/operations/authEmail";
import { ReactComponent as DownArrow } from "../../../assets/svg/downArrow.svg";
import { mobileMaxWidth } from "../../../components/helpers/ResponsiveStyleHelper";

export default function ScanEmails() {
  const classes = useStyles();
  const [addedEmails, setAddedEmails] = useState([]);
  const [redirectUrl, setRedirectUrl] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [fetchAuthEmails, setFetchAuthEmails] = useState(false);
  const [showAddEmailPopup, setShowAddEmailPopup] = useState(false);
  const [displayPrivacyInfo, setDisplayPrivacyInfo] = useState(false);
  const [addEmail, { data: urlData }] = useLazyQuery(getAuthUrl);
  const [getAuthEmailsList, { data: emailsList }] = useLazyQuery(getFutureScanDetails);
  const [addFutureScanEmail, { data: updateOrAddFutureScan }] = useMutation(addFutureScan);

  const futureScanPeriod = [
    { id: 1, name: "1 Week", value: "1 Week" },
    { id: 2, name: "1 Month", value: "1 Month" },
    { id: 3, name: "3 Months", value: "3 Months" },
    { id: 4, name: "Custom", value: "Custom" },
  ];

  useEffect(() => {
    getAuthEmailsList();
  }, []);

  useEffect(() => {
    if (fetchAuthEmails) {
      addFutureScanEmail({
        variables: {
          emailList: [
            {
              scanEmail: userEmail,
              period: "",
            },
          ],
        },
      });
      setUserEmail("");
    }
  }, [fetchAuthEmails]);

  useEffect(() => {
    if (urlData) {
      if (urlData?.getAuthURL?.message.includes("already")) {
        openToast("error", "Already Added !", urlData.getAuthURL.message);
      } else {
        setRedirectUrl(urlData?.getAuthURL?.data?.url);
        setDisplayPrivacyInfo(true);
      }
    }
  }, [urlData]);

  useEffect(() => {
    if (emailsList?.getFutureScanDetails?.data?.length) {
      setAddedEmails(
        emailsList.getFutureScanDetails.data.map((item) => ({
          ...item,
          customDate:
            item.scanPeriod === "Custom" ? { from: item.fromDate, to: item.toDate } : false,
        })),
      );
    } else setAddedEmails([]);
  }, [emailsList]);

  useEffect(() => {
    if (updateOrAddFutureScan?.addFutureScanDetails) {
      if (updateOrAddFutureScan.addFutureScanDetails.statusCode === "200") {
        openToast(
          "success",
          updateOrAddFutureScan.addFutureScanDetails.message,
          "Future scan is successfully added",
        );
        getAuthEmailsList();
      } else {
        openToast("error", "Error", "There is an error, in adding future scan");
      }
    }
  }, [updateOrAddFutureScan]);

  function futurePeriodChange(value, i, customDateKey) {
    setAddedEmails((preVal) => {
      let newArr = JSON.parse(JSON.stringify(preVal));
      if (customDateKey) {
        newArr[i].isChanged = true;
        newArr[i].customDate[customDateKey] = new Date(value).toISOString();
        return newArr;
      }
      newArr[i].isChanged = true;
      newArr[i].scanPeriod = value;
      newArr[i].customDate = value === "Custom" ? {} : false;
      return newArr;
    });
  }

  function saveEmailList() {
    if (
      addedEmails.some(
        (item) =>
          item.isChanged &&
          (item.scanPeriod === "Custom" ? item.customDate?.from && item.customDate?.to : true),
      )
    ) {
      addFutureScanEmail({
        variables: {
          emailList: addedEmails
            .filter((item) => item.isChanged)
            .map((data) => ({
              scanEmail: data.email,
              period: data.scanPeriod,
              fromDate:
                data.scanPeriod === "Custom" ? new Date(data.customDate.from).toISOString() : null,
              toDate:
                data.scanPeriod === "Custom" ? new Date(data.customDate.to).toISOString() : null,
            })),
        },
      });
    } else {
      openToast("error", "Error", "Please Fill the Required Fields");
    }
  }

  function handleAccess(value) {
    setDisplayPrivacyInfo(false);
    if (value) {
      const myWindow = window.open(redirectUrl);
      localStorage.setItem("grantedEmailAccess", "false");
      const interval = setInterval(() => {
        if (myWindow.closed) {
          setFetchAuthEmails(true);
          clearInterval(interval);
        }
      }, 300);
    }
  }

  function handleSelect(email, isOpen) {
    setAddedEmails((preVal) => {
      let newArr = preVal;

      return newArr.map((item) => {
        item.isOpen = false;
        if (item.id === email.id && isOpen) item.isOpen = true;
        return item;
      });
    });
  }

  function formatDate(date) {
    return new Intl.DateTimeFormat("en-US", { month: "short", day: "numeric" }).format(
      new Date(date),
    );
  }

  function SelectArea({ itemData, index }) {
    return (
      <>
        {itemData.scanPeriod === "Custom" && itemData?.customDate && (
          <div onClick={() => handleSelect(itemData, true)} className={classes.selectForm}>
            {itemData.customDate.from && itemData.customDate.to
              ? `${formatDate(itemData.customDate.from)} - ${formatDate(itemData.customDate.to)}`
              : itemData.scanPeriod}
            <IconComponent iconTitle="DownArrow" />
          </div>
        )}
        <FormControl
          fullWidth
          style={{ opacity: !(itemData.scanPeriod === "Custom" && itemData?.customDate) ? 1 : 0 }}
        >
          <Select
            open={itemData.isOpen ?? false}
            onClose={() => handleSelect(itemData)}
            onOpen={() => handleSelect(itemData, true)}
            className={classes.datePicker}
            id={`${index}-demo-simple-select`}
            value={itemData?.scanPeriod}
            IconComponent={DownArrow}
            displayEmpty
          >
            <MenuItem value="" onClick={() => futurePeriodChange("", index)}>
              Choose Scan Period
            </MenuItem>
            {futureScanPeriod.map((period, i) => (
              <MenuItem
                key={i}
                value={period.value}
                onClick={() => futurePeriodChange(period.name, index)}
              >
                {period.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    );
  }

  return (
    <div className={classes.scanEmailContainer}>
      <div className={classes.settingCategoryHeader} />
      <div className={classes.subHeader}>Email Scan Periods</div>
      <div className={classes.emailContainer}>
        {Boolean(addedEmails?.length) &&
          addedEmails.map((email, index) => (
            <div className={classes.emailOuterContainer} key={`scan_email${index}`}>
              <div className={classes.emailHeading}>Email:</div>
              <div className={classes.emailContainerBody}>
                <div className={classes.emailDetail}>
                  <div className={classes.avatar}>
                    <img
                      className={classes.avatar}
                      src={email?.profileUrl}
                      onError={({ currentTarget }) => (currentTarget.style.display = "none")}
                    />
                  </div>
                  <div className={classes.mailBody}>{email?.email}</div>
                </div>
                <div className={classes.selectWrapper}>
                  {window.innerWidth > mobileMaxWidth && (
                    <SelectArea key={`${index}-web`} index={index} itemData={email} />
                  )}
                </div>
              </div>
              {!(email?.customDate && (!email?.customDate?.from || !email?.customDate?.to)) && (
                <div className={classes.selectWrapperMobile}>
                  {window.innerWidth <= mobileMaxWidth && (
                    <SelectArea key={`${index}-mobile`} index={index} itemData={email} />
                  )}
                </div>
              )}
              {email?.customDate && (!email?.customDate?.from || !email?.customDate?.to) && (
                <div className={classes.fromToDate}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className={classes.subInputValue}>
                      <label htmlFor="fromDate">From:</label>
                      <MobileDatePicker
                        className={classes.datePicker}
                        inputFormat="MM-DD-YYYY"
                        value={email?.customDate?.from ?? ""}
                        onChange={(value) => futurePeriodChange(value, index, "from")}
                        renderInput={(params) => <TextField {...params} placeholder="MM-DD-YYYY"/>}
                      />
                    </div>
                    <IconComponent iconTitle="DateSync" />
                    <div className={classes.subInputValue}>
                      <label htmlFor="toDate">To:</label>
                      <MobileDatePicker
                        className={classes.datePicker}
                        inputFormat="MM-DD-YYYY"
                        value={email?.customDate?.to ?? ""}
                        onChange={(value) => futurePeriodChange(value, index, "to")}
                        renderInput={(params) => <TextField {...params} placeholder="MM-DD-YYYY"/>}
                      />
                    </div>
                  </LocalizationProvider>
                </div>
              )}
            </div>
          ))}
      </div>
      <div className={classes.addButton} onClick={() => setShowAddEmailPopup(true)}>
        <IconComponent iconTitle="AddCircle" />
        Add another
      </div>
      {showAddEmailPopup && (
        <ModalPortal>
          <AddEmailPopup
            setShowPopup={setShowAddEmailPopup}
            addNewUserEmail={(email) => {
              addEmail({ variables: { email } });
              setFetchAuthEmails(false);
              setUserEmail(email);
            }}
          />
        </ModalPortal>
      )}
      {displayPrivacyInfo && (
        <ModalPortal>
          <Modal type={"privacyInfo"} handleButtonClick={handleAccess} />
        </ModalPortal>
      )}
      <div className={classes.buttonContainer}>
        <Button
          buttontype="primary"
          size="default"
          fontFamily="karla"
          onClick={saveEmailList}
          disabled={
            !addedEmails.some(
              (item) =>
                item.isChanged &&
                (item.scanPeriod === "Custom"
                  ? item.customDate?.from && item.customDate?.to
                  : true),
            )
          }
        >
          Save
        </Button>
        <Button buttontype="secondary" size="default" fontFamily="karla">
          Cancel
        </Button>
      </div>
    </div>
  );
}
