import { useStyles } from "./styles";
import "./styles.css";
import noImageLogo from "../../../../assets/images/no-image.jpg";
import IconComponent from "../../../../components/fields/icon";

export default function AddNewItemHome(props) {
  const { productsList, setProductsList, handleSelectItem } = props;
  const classes = useStyles();

  function handleImageClick(item, index) {
    handleSelectItem({ ...JSON.parse(JSON.stringify(item)) }, index);
  }

  function Selected() {
    return (
      <div className={classes.selectedVector}>
        <IconComponent className={classes.eclipseVector} iconTitle={"EclipseVector"} />
        <IconComponent className={classes.addCheckBox} iconTitle={"BoldCheckBox"} />
      </div>
    );
  }

  function handleRemoveProduct(item, index) {
    if (item.selected) {
      handleSelectItem({ ...JSON.parse(JSON.stringify(item)) }, index);
    }
    productsList.splice(index, 1);
    setProductsList([...productsList]);
  }

  return (
    <>
      <div className={classes.imageGallery}>
        {productsList.map((item, index) => (
          <div className={classes.parent} key={index} onClick={() => handleImageClick(item, index)}>
            <div
              className={`${classes.galleryImgWrapper} ${
                item.selected ? classes.selectedProduct : ""
              }`}
            >
              <img
                className={classes.imgBody}
                src={`${item.src}`}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = noImageLogo;
                }}
              />
              <IconComponent
                iconTitle="CrossIcon"
                className={classes.crossIcon}
                onClick={(event) => {
                  event.stopPropagation();
                  handleRemoveProduct(item, index);
                }}
              />
              {item.selected && <Selected />}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
