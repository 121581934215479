import { makeStyles } from "@material-ui/styles";
import { mobileMediaStyle, tabMediaStyle } from "../../components/helpers/ResponsiveStyleHelper";

export const useStyles = makeStyles({
  settingsBody: {
    // fontFamily: "var(--primaryRegularFont)",
    fontFamily: "var(--secondaryHeaderFont)",
    padding: "40px 60px 40px",
  },

  header: {
    // fontFamily: "var(--primaryHeaderFont)",
    fontFamily: "inherit", 
    // fontSize: "32px",
    fontSize: "34px",
    lineHeight: "40px",
    fontWeight: "500",
    paddingBottom: "12px",
  },

  BodyWrapper: {
    display: "flex",
  },

  rightBody: {
    width: "82%",
    maxWidth: "calc( 100% - 300px)",
  },
  sectionHeader: {
    display: "none",
    fontSize: "16px",
    fontWeight: "400",
    letterSpacing: "0.48px",
    margin: "0 16px",
    paddingBottom: "12px",
  },
  sectionHr: {
    display: "none",
    border: "none",
    borderTop: "0.5px solid #D9D9D9",
  },

  ...tabMediaStyle({
    settingsBody: {
      padding: "30px 40px 30px",
    },
    header: {
      fontSize: "30px",
      lineHeight: "36px",
      paddingBottom: "8px",
    },
    rightBody: {
      maxWidth: "calc( 100% - 220px)",
    },
  }),

  ...mobileMediaStyle(
    {
      BodyWrapper: {
        flexDirection: "column",
      },
      header: {
        display: "none",
      },
      settingsBody: {
        padding: "24px 0",
      },
      sectionHeader: {
        display: "block",
      },
      rightBody: {
        width: "unset",
        maxWidth: "unset",
      },
      sectionHr: {
        display: "block",
      },
    },
    "@media (max-width:600px)",
  ),
});
