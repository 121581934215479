import { ModalContainer, Overflow } from "../../../components/modal";
import videoName from "../../../assets/video/how_it_works/index";
import { useStyles } from "./styles";

export default function UploadVideoTour({ modalResponse, title }) {
  const classes = useStyles();
  return (
    <Overflow onClick={() => modalResponse()}>
      <ModalContainer
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={classes.videoTourBox}
      >
        <div style={{paddingBottom:"10px"}}>
          <h3  className={classes.videoTourTiltle} >Steps to ‘Upload Your Image’</h3>
          <p className={classes.videoTourSubTiltle}>
            <em>Copy/Paste Site Image:</em>
          </p>
          <p className={classes.videoTourText}>
            1. Copy the site URL of the retail item you wish to add
          </p>

          <p className={classes.videoTourText}>
            {" "}
            2. Paste into the Milkloset URL box. Once the image(s) appear, choose your preferred
            image and proceed to fill out the details in the form.
          </p>

          <p className={classes.videoTourSubTiltle}>
            {" "}
            <em>Copy/Paste Image URL Address:</em>
          </p>

          <p className={classes.videoTourText}>
            {" "}
            1. Copy the Image URL address “Copy Image Address” by right clicking on the preferred
            image on the retail site
          </p>

          <p className={classes.videoTourText}>
            {" "}
            2. Paste image address into the Milkloset URL box. Once the image appears, choose your
            preferred image and proceed to fill out the details in the form.
          </p>

          <p className={classes.videoTourSubTiltle}>How to Video:</p>
        </div>
        <video  width="100%" height="100%" controls>
          <source src={videoName[title]} type="video/mp4" />
        </video>
      </ModalContainer>
    </Overflow>
  );
}
