import {
  AddClosetMenu,
  AddClosetNav,
  Header,
  MainContainer,
  Title,
} from "../../containers/AddYourStyle";
import { closetMenu } from "../../components/addYourStyle/constants";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

export default function AddYourStyle() {
  const [showNewItems, setShowNewItems] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    // check location path matches /addyourstyle
    if (location.pathname === "/addyourstyle") {
      navigate("/addyourstyle/scanemail");
      return;
    }
    if (location.pathname.includes("newitem")) setShowNewItems(true);
    else setShowNewItems(false);
  }, [location]);

  return (
    <MainContainer>
      {!showNewItems && (
        <Header>
          <Title>Choose Your Method</Title>
          <AddClosetNav>
            {closetMenu.map((menu, index) => (
              <AddClosetMenu key={index} available={menu.available}>
                <NavLink to={menu.to} className={({ isActive }) => (isActive ? "active" : "")}>
                  <img src={menu.svg} />
                  {menu.name}
                </NavLink>
              </AddClosetMenu>
            ))}
          </AddClosetNav>
        </Header>
      )}
      <Outlet />
    </MainContainer>
  );
}
