import { makeStyles } from "@material-ui/styles";
import { mobileMediaStyle } from "../../components/helpers/ResponsiveStyleHelper";

export const useStyles = makeStyles({
  dots: {
    "& li.slick-active button:before": {
      color: "red",
    },
  },
  paggination: {
    padding: "10px 0 10px",
    justifyContent: "right",
    display: "flex",
    fontSize: ".75rem",
    letterSpacing: "normal",
    color: "#5e5e5e",
  },
  arrowLeft: {
    padding: "0px 5px",
    rotate: "90deg",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#D9D9D9",
    },
  },
  arrowRight: {
    padding: "0px 5px",
    rotate: "-90deg",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#D9D9D9",
    },
  },
  parentContainer: {
    padding: "40px 60px",
  },
  title: {
    fontFamily: "var(--primaryHeaderFont)",
    fontWeight: "500",
    fontSize: "32px",
    lineHeight: "40px",
    width: "274px",
    height: "40px",
    left: "60px",
    top: "204px",
    padding: "40px 60px 60px 0px",
    color: "#000000",
  },
  selectedImage: {
    display: "flex",
  },
  singleItem: {
    padding: "10px",
    margin: "10px",
    position: "relative",
    "&:hover > i": {
      display: "flex",
    },
  },
  image: {
    borderRadius: "5px",
    height: "126px",
    width: "126px",
    objectFit: "contain",
  },
  CurationsContainer: {
    display: "flex",
    margin: 0,
    overflow: "auto",
    flexWrap: "wrap",
    height: "100%",
    "& > div": {
      "&:hover": {
        "& > svg": {
          opacity: 1,
          transition: "0.2s",
          cursor: "pointer",
        },
      },
    },
    "&::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#c1c1c1",
      borderRadius: "10px",
    },
  },
  sliderContainer: {
    "& .slick-slide": {
      position: "relative",
      margin: "0 10px",
      "& > div": {
        width: "357px",
        padding: "15px",
        height: "357px",
        border: "1px solid #000000",
        "&:has(.selected-item)": {
          border: "3px solid #fb5b01",
          padding: "13.5px",
        },
      },
    },
    "& .slick-list": {
      height: "390px !important",
    },
    "& .slick-arrow::before": {
      fontSize: "30px",
      color: "#000",
    },
    "& .slick-next": {
      right: "-35px",
    },
    "& .slick-prev": {
      left: "-35px",
    },
  },
  deleteIcon: {
    marginLeft: "auto",
  },
  InspiredContainer: {
    border: "1px solid #000000",
    height: "365px",
    width: "365px",
    // minHeight: "250px",
    display: "flex",
    padding: "11px",
    flexWrap: "wrap",
    position: "relative",

    overflow: "hidden",
    paddingTop: "unset",
  },
  outfitImage: {
    width: "fit-content",
    // height: "fit-content",
    maxHeight: "100%",
    maxWidth: "100%",
    objectFit: "contain",
  },
  InspiredOutfitImage: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
  wide: {
    width: "100px",
    height: "100px",
  },
  tall: {
    // width:"100px",
    height: "300px",
  },
  big: {
    // width:"100px",
    height: "300px",
  },
  vector: {
    position: "absolute",
    left: "50%",
    top: "50%",
    width: "20px",
    height: "20px",
    transform: "translate(-50%, -50%)",
    "& > svg": {
      width: "20px",
      height: "20px",
    },
  },
  dataNotFound: {
    display: "flex",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "24px",
    lineHeight: "28px",
    fontWeight: "500",
    color: "#CACACA",
    fontFamily: "var(--primaryRegularFont)",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // marginTop: "20px",
    gap: "20px",
    position: "relative",
  },
  circle: {
    width: "unset",
    height: "unset",
  },
  cross: {
    position: "absolute",
    display: "block",
    width: "unset",
    cursor: "pointer",
    right: 0,
    top: 0,
    height: "fit-content",
  },
  crossIcon: {
    width: "100%",
    height: "100%",
  },
  select: {
    fontSize: "20px",
    textAlign: "center",
    width: "100%",
    margin: 0,
  },
  save: {
    right: "0px",
    width: "130px",
    height: "47px",
    position: "absolute",
  },
  styleMeContainter: {
    width: "115px",
    height: "113px",
    background: "#FFFFFF",
    border: "1px solid #000000",
    cursor: "pointer",
  },
  itemsContainer: {
    display: "grid",
    maxHeight: "513px",
    gap: "15px",
    gridTemplateColumns:
      "calc(20% - 12px) calc(20% - 12px) calc(20% - 12px) calc(20% - 12px) calc(20% - 12px)",
    overflow: "auto",
    position: "relative",
    "&::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#c1c1c1",
      borderRadius: "10px",
    },
  },
  imageContainer: {
    display: "flex",
    width: "calc(100% - 10px*2)",
    height: "calc(100% - 10px*2)",
    justifyContent: "center",
    padding: "10px",
  },
  StyleMeimage: {
    objectFit: "contain",
    height: "100%",
    width: "100%",
  },
  actionField: {
    margin: "5px",
    position: "absolute",
  },
  trash: {
    position: "absolute",
    top: "0px",
    right: "0px",
    cursor: "pointer",
    display: "none",
    padding: "10px",
    height: "30px",
    width: "30px",
    "& > svg > path": {
      stroke: "rgb(251, 91, 1)",
    },
  },

  addItemButton: {
    position: "relative",
    height: "126px",
    width: "126px",
    backgroundColor: "#cacaca",
    margin: "10px",
    padding: "10px",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },

    "& .MuiInputBase-root ": {
      height: "100% !important",
    },

    "& .MuiFormControl-root": {
      height: "100% !important",
    },

    "& .MuiSelect-select": {
      height: "100% !important",
    },

    "& .MuiSvgIcon-root": {
      display: "none !important",
    },
  },
  addbutton: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  actionMyCuration: {
    width: "30px",
    height: "30px",
  },
  addItemContainer: {
    width: "743px",
    padding: "56px 40px",
  },
  svgContainer: {
    marginBottom: "18px",
    marginTop: "12px",
  },
  alignHeader: {
    marginBottom: "28px",
  },
  alignAction: {
    marginBottom: "30px",
    height: "47px",
  },
  curationActions: {
    padding: "20px 0 0 10px",
    display: "flex",
    gap: "15px",
  },

  ...mobileMediaStyle({
    addItemButton: {
      width: "unset",
      maxHeight: "62px",
      maxWidth: "62px",
      margin: 0,
      padding: 0,
      borderRadius: "3px",
    },
    trash: {
      padding: 0,
      width: "20px",
      height: "20px",
    },
    actionField: {
      position: "absolute",
      display: "flex",
      margin: 0,
      padding: "5px",

      "& svg": {
        width: "10px",
        height: "10px",
      },
    },
    StyleMeimage: {
      width: "100%",
      height: "100%",
      maxWidth: "unset",
      maxHeight: "unset",
    },
    itemsContainer: {
      gridTemplateColumns: "1fr 1fr 1fr 1fr",
      gap: "12px",
      maxHeight: "300px",
      "&::-webkit-scrollbar": {
        width: "2px",
        height: "2px",
      },
      marginBottom: "16px",
    },
    styleMeContainter: {
      width: "63px",
      height: "63px",
      margin: "0",
      position: "relative",
    },
    save: {
      width: "56px",
      height: "24px",
      fontSize: "10px",
      lineHeight: "unset",
      position: "relative",
    },
    containerStyleMobile: {
      margin: 0,
      padding: 0,
    },
    flexCenter: {
      margin: 0,
    },
    alignAction: {
      gap: "10px",
      marginBottom: "22px",
      height: "24px",
    },
    alignHeader: {
      marginBottom: "10px",
    },
    select: {
      margin: "0",
      fontSize: "18px",
      fontWeight: "600",
      lineHeight: "unset",
      justifyContent: "end",
    },
    dataNotFound: {
      fontSize: "16px",
    },
    CurationsContainer: {
      "&::-webkit-scrollbar": {
        width: "2px",
        height: "2px",
      },
    },
    image: {
      borderRadius: "3px",
      width: "100%",
      height: "100%",
    },
    singleItem: {
      padding: 0,
      margin: 0,
      height: "62px",
    },
    InspiredContainer: {
      margin: 0,
      width: "100%",
      height: "336px",
    },
    sliderContainer: {
      "& .slick-slide": {
        margin: "0 5px",
        "& > div": {
          width: "296px",
          height: "296px",
          padding: "10px",
          "&:has(.selected-item)": {
            border: "2px solid #fb5b01",
            padding: "9px",
          },
        },
      },
      "& .slick-list": {
        height: "318px !important",
      },
      "& .slick-arrow::before": {
        fontSize: "20px",
        color: "#000",
      },
      "& .slick-next": {
        right: "-8px",
      },
      "& .slick-prev": {
        left: "-10px",
        zIndex: 1,
      },
    },
    vector: {
      width: "14px",
      height: "14px",
      "& > svg": {
        width: "14px",
        height: "14px",
      },
    },
    outfitImage: {
      width: "auto",
      height: "auto",
    },
    actionMyCuration: {
      width: "16px",
      height: "16px",
    },
    addItemContainer: {
      width: "336px",
      padding: "24px 18px",
    },
    addbutton: {
      "& > i": {
        height: "20px",
        width: "20px",
      },
    },
    cross: {
      width: "24px",
      height: "24px",
    },
    svgContainer: {
      margin: 0,
      marginBottom: "16px",
    },
    curationActions: {
      gap: "10px",
      paddingTop: "15px",
    },
    imageContainer: {
      width: "calc(100% - 5px*2)",
      height: "calc(100% - 5px*2)",
      padding: "5px",
    },
  }),
});
