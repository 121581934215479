import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
  imageDiv: {
    height: "200px",
    width: "200px",
    position: "relative",
    marginBottom: "20px",
  },
  displayImage: {
    height: "200px",
    width: "200px",
    objectFit: "contain",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover" : {
      opacity: "0.5",
    }
    // boxShadow: "black 0px 0px 3px",
    // borderRadius: "5px",
  },
  cameraIcon: {
    position: "absolute",
    height: "200px",
    width: "200px",
    top: "0",
    left: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    "& > i > svg": {
      width: "20px",
      height: "20px",
    },
  },
  imageUploadWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  editBoxTitle: { margin: "16px 0", fontWeight: 600 },
  crossIcon: {
    position: "absolute",
    top: "4px",
    right: "3px",
    height: "15px",
    width: "15px",
    borderRadius: "35px",
    // backgroundColor: "#ffffff",
    zIndex: 1,
    "& > svg": {
      // transform: "rotate(45deg)",
    },
  },
  UploadImageIcon: {
    height: "200px",
    width: "200px",
    // position: "absolute",
    // top: "0px",
    // left: "0px",
    // height: "35px",
    // width: "28px",
    // borderRadius: "5px 0 0 0",
    // opacity: 0,
    zIndex: 1,
    "&:hover": {
        // backgroundColor: "rgba(255, 255, 255, 0.5)",
    //   opacity: 1,
    },
    "& > svg": {
      width: "25px",
      height: "25px",
    },
  },
});
