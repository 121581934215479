import IconComponent from "../../../components/fields/icon";
import { List } from "../../../containers/StyleMe";

export default function FootwearTag({ footwearList, removeCurationItem, parentIndex, classes }) {
  return (
    <div style={{ gridColumn: "2" }}>
      {footwearList.map((item, itemIndex) => (
        <List
          key={`index-${itemIndex}`}
          style={{ position: "relative", justifyContent: "center", display: "flex" }}
          data-category={item.category}
        >
          <IconComponent
            style={{ position: "absolute" }}
            iconTitle="CrossIcon"
            className={classes.trash}
            onClick={(event) => {
              event.stopPropagation();
              removeCurationItem(item.id, parentIndex);
            }}
          />

          <img
            style={{
              maxWidth: "100px",
              maxHeight: "100px",
              minWidth: "100px",
              minHeight: "",
              width: "100%",
              objectFit: "contain",
            }}
            className={classes.outfitImage}
            src={item.src}
            key={`src-${itemIndex}`}
          />
        </List>
      ))}
    </div>
  );
}
