import { useState, useEffect } from "react";
import JoinWaitList from "./JoinWaitList";
import WaitListJoined from "./WaitlistJoined";
import { useMutation } from "@apollo/client";
import { gqlJoinWaitList } from "../../apollo/operations/waitList.js";
import { openToast } from "../../components/toast/index.jsx";
import Loader from "../../components/loaderScreen";

const Waitlist = () => {
  const [joinWaitListGql, { data, loading, error }] = useMutation(gqlJoinWaitList);
  const [isFormSubmited, setIsFormSubmited] = useState(false);

  useEffect(() => {
    if (!loading && data) {
      if (data?.joinWaitList?.statusCode === "200") {
        openToast("success", "Successful!", data?.joinWaitList?.message);
        setIsFormSubmited(!isFormSubmited);
      } else {
        data?.joinWaitList?.message && openToast("error", "Failed!", data?.joinWaitList?.message);
      }
    }
    if (error) {
      console.log(error);
    }
  }, [data, error]);

  const handleFormSubmit = (formData) => {
    try {
      joinWaitListGql({
        variables: {
          addWaitListInfo: {
            ageRange: formData.age,
            email: formData.email,
            firstName: formData.firstName,
            gender: formData.gender,
            igHandle: formData.igHandle,
            lastName: formData.lastName,
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {loading && <Loader />}
      {isFormSubmited ? <WaitListJoined pageType=""/> : <JoinWaitList handleFormSubmit={handleFormSubmit} />}
    </>
  );
};

export default Waitlist;
