import { RouterProvider } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";

import routes from "./routes";
import ApolloClient from "./apollo/apolloClient";
import "react-toastify/dist/ReactToastify.css";
import ToastContainer from "./components/toast";

function App() {
  const apolloClient = ApolloClient();

  return (
    <ApolloProvider client={apolloClient}>
      <RouterProvider router={routes} />
      <ToastContainer />
    </ApolloProvider>
  );
}

export default App;
