import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./styles";
import { CategoryGrid, CategoryCard, CardOptions } from "../../../containers/dashboard";
import IconComponent from "../../../components/fields/icon";
import { useState, useEffect } from "react";
import {
  MenCategories,
  NeutralCategories,
  WomenCategories,
} from "../../../components/dashboard/constants";
import { useAuth } from "../../../hooks/auth";
import ModalPortal from "../../../containers/common/modalPortal";
import Modal from "../../../containers/common/modalPortal/modal";
import { updateSubCategories } from "../../../apollo/operations/user";
import { useMutation } from "@apollo/client";
import { setCategory } from "../../../store/categorySlice";

export default function HiddenCategory() {
  const { user } = useAuth();
  const gender = user?.gender?.toLowerCase();
  const classes = useStyles();
  const dispatch = useDispatch();
  const categoriesList = useSelector((state) => state.category.category);
  const hiddenCategories = getHiddenCategories(JSON.parse(JSON.stringify(categoriesList)));
  const [selectedCategory, setSelectedCategory] = useState("");
  const [openEditCard, setOpenEditCard] = useState("");
  const [displayUnhideCategoryPopup, setDisplayUnhideCategoryPopup] = useState(false);
  const [hideCategory] = useMutation(updateSubCategories);

  const handleOnClick = (event) => {
    const cardEle = document.getElementsByClassName("categoryEditCard")[0];
    if (cardEle) {
      const popUp = event.target.closest(".categoryEditCard");
      !popUp && setOpenEditCard("");
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleOnClick);
    return () => window.removeEventListener("click", handleOnClick);
  }, []);

  function getHiddenCategories(categoryList) {
    const hiddenList = [];
    categoryList?.forEach((category) => {
      if (category.hidden) {
        hiddenList.push(category);
      }
    });
    const category =
      gender === "male" ? MenCategories : gender === "female" ? WomenCategories : NeutralCategories;
    hiddenList.forEach((ele) => {
      let tempCategory = category.find((cat) => cat.name === ele.name);
      if (tempCategory) ele.img = tempCategory.img;
    });
    return hiddenList;
  }

  function handleOpenCard(name) {
    setOpenEditCard(name);
  }

  function handleUnhideCategory(e, ele) {
    setSelectedCategory(ele);
    setDisplayUnhideCategoryPopup(true);
    setOpenEditCard("");
  }

  function handleModalResponse(value) {
    if (value) {
      const tempCategoryList = JSON.parse(JSON.stringify(categoriesList));
      const subCate = tempCategoryList.find(
        (categoryEle) => categoryEle.name === selectedCategory.name,
      );
      if (subCate) {
        subCate.hidden = false;
      }
      // calling Api to store updated category list of user
      hideCategory({
        variables: {
          category: {
            subCategory: tempCategoryList,
          },
        },
      });
      dispatch(setCategory(tempCategoryList));
    }
    setDisplayUnhideCategoryPopup(false);
  }

  return (
    <>
      <div className={classes.hiddenCategoryHeader} />
      <div className={classes.hiddenCategoryBody}>
        <div className={classes.subHeader}>Hidden Category</div>
        {hiddenCategories.length > 0 ? (
          <CategoryGrid hidden>
            {hiddenCategories.map((ele, index) => (
              <CategoryCard key={index} img={ele.img} hidden>
                <div>
                  <p>{ele.name}</p>
                  <IconComponent
                    iconTitle={"ThreeDotsIcon"}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleOpenCard(ele.name);
                    }}
                  />
                  {ele.name === openEditCard ? (
                    <CardOptions className="categoryEditCard">
                      <p>Edit</p>
                      <p onClick={(e) => handleUnhideCategory(e, ele)}>Unhide</p>
                    </CardOptions>
                  ) : (
                    ""
                  )}
                </div>
              </CategoryCard>
            ))}
          </CategoryGrid>
        ) : (
          <div className={classes.emptyContainer}>
            <IconComponent iconTitle={"EmptyPage"} />
            <p className={classes.emptyHeader}> Empty Page</p>
            <p className={classes.emptyMsg}>
              Looks like you haven’t add any item on hidden category{" "}
            </p>
          </div>
        )}
      </div>
      {displayUnhideCategoryPopup && (
        <ModalPortal>
          <Modal type={"unhideCategory"} handleButtonClick={handleModalResponse} svgType="circle" />
        </ModalPortal>
      )}
    </>
  );
}
