import { useState, useEffect } from "react";
import LeftCategories from "./leftCategories";
import { useStyles } from "./styles";
import ClosetItems from "./ClosetItems";
import EmptyCloset from "./EmptyCloset";
import { useLocation, useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { getItemsFromCloset, updateItemDetails } from "../../../apollo/operations/items";
import { getChildCategories } from "../../../components/helpers/categoryHelper";
import { useSelector } from "react-redux";
import Loader from "../../../components/loaderScreen";
import { addFavorites, removeFavorites } from "../../../apollo/operations/favorite";
import { openToast } from "../../../components/toast";
import IconComponent from "../../../components/fields/icon";
import { actionDropDownSort, sortList } from "../../../components/helpers/sortHelper";
import ActionDropDown from "../../../components/fields/ActionDropDown";
import ModalPortal from "../../../containers/common/modalPortal";
import DndConfirm from "../../../components/dndConfirmPopup";

let draggedItem = null;
export default function Category() {
  const classes = useStyles();
  const [selectedItems, setSelectedItems] = useState([]);
  const [sortedItems, setSortedItems] = useState([]);
  const [sortName, setSortName] = useState("");
  const [dropdownToggle, setDropdownToggle] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const header = location.pathname.split("/")[3];
  const [getItems, { loading, data: ItemsList }] = useLazyQuery(getItemsFromCloset);
  const [updateItems, { data: updateItemResponse }] = useMutation(updateItemDetails);

  const [items, setItems] = useState([]);
  const [activeCategory, setActiveCategory] = useState("View All");
  const categoriesList = useSelector((state) => state.category.category);
  const curations = useSelector((state) => state.curation.curation);
  const [addFavorite, { data: favorite }] = useMutation(addFavorites);
  const [deleteFavorite, { data: deleteFavoriteData }] = useMutation(removeFavorites);
  const [subCategories, setSubCategories] = useState(
    categoriesList?.find((category) => category.name === header)?.subCategories ?? [],
  );
  const [confirmCateChange, setConfirmCateChange] = useState(false);

  useEffect(() => {
    fetchItemsAPI();
  }, [activeCategory, categoriesList]);

  useEffect(() => {
    setSortedItems(items);
    onToggleValueChange(sortList[2]);
  }, [items]);

  useEffect(() => {
    if (confirmCateChange && updateItemResponse) {
      if (updateItemResponse.updateItemDetails?.statusCode === "200") {
        openToast("success", "Updated successfully", "Your item has been updated successfully.");
      } else {
        openToast("error", "Update failed", "failed to update your item, try again later.");
      }
      setConfirmCateChange(false);
      fetchItemsAPI();
    }
  }, [updateItemResponse]);

  function fetchItemsAPI() {
    //get child categories
    let childCategoryLists = getChildCategories(categoriesList, activeCategory, header);
    getItems({ variables: { category: childCategoryLists } });
  }

  useEffect(() => {
    // check maincategory is hidden or not
    if (categoriesList?.find((ele) => ele.name === header)?.hidden) {
      navigate("/dashboard");
    }
    setSubCategories(
      categoriesList?.find((category) => category.name === header)?.subCategories ?? [],
    );
  }, [categoriesList]);

  useEffect(() => {
    setSelectedItems(curations?.filter((d) => d.mainCategory === header));
  }, [curations]);

  useEffect(() => {
    if (ItemsList?.getItemsFromCloset) {
      setItems(
        ItemsList.getItemsFromCloset.map((data) => ({
          ...data,
          isSelected: curations.some((item) => `${item.id}` === `${data.id}`),
          mainCategory: header,
        })),
      );
    }
  }, [ItemsList,loading]);

  function likedItem(value, outfitId) {
    updateItems({
      variables: {
        updateItems: {
          id: outfitId.toString(),
          isLiked: value,
        },
      },
    });

    if (value) {
      addFavorite({
        variables: {
          favoriteData: {
            itemId: outfitId.toString(),
            isLiked: value,
          },
        },
      });
    } else if (outfitId) {
      deleteFavorite({
        variables: {
          favoriteData: {
            itemId: outfitId.toString(),
          },
        },
      });
    }
  }

  useEffect(() => {
    if (deleteFavoriteData) {
      if (deleteFavoriteData.removeFavorites?.statusCode === "200") {
        openToast(
          "success",
          "Removed successfully",
          "Your favorite item has been removed successfully.",
        );
      } else {
        openToast("error", "Remove failed", "failed to removed from favorite, try again later.");
      }
    }
  }, [deleteFavoriteData]);

  useEffect(() => {
    if (favorite) {
      if (favorite?.addFavorites?.statusCode === "200") {
        openToast("success", "Added successfully", "Your item successfully added to favorite.");
      } else {
        openToast("error", "Failed to add", "failed to add item in to favorite, try again later.");
      }
    }
  }, [favorite]);

  function onToggleValueChange(selectedItem) {
    setSortedItems(
      actionDropDownSort(selectedItem, selectedItem.name.includes("to") ? "itemName" : "createdAt"),
    );
    setSortName(() => selectedItem.name);
  }

  function dragStarted(item) {
    // storing dragged item details
    draggedItem = item;
  }

  function onDropItem(item) {
    if (item.name !== draggedItem?.category && item.name !== "View All") {
      draggedItem.newCategory = item.name;
      setConfirmCateChange(true);
    }
  }

  function updatePopupResponse(value) {
    if (value) {
      updateItems({
        variables: {
          updateItems: {
            category: draggedItem.newCategory,
            id: draggedItem.id,
          },
        },
      });
    } else {
      setConfirmCateChange(false);
      draggedItem = null;
    }
  }

  return (
    <div className={classes.parentContainer}>
      <div className={classes.title}>{header}</div>
      <div className={classes.mainContainer}>
        <LeftCategories
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          subCategories={subCategories}
          header={header}
          onDropItem={onDropItem}
        />
        <div className={classes.rightContainer}>
          <div className={`${classes.buttonContainer} ${classes.desktopAction}`}>
            <button
              className={`${classes.Button} ${
                /* selectedItems.length > 0 ? classes.enabledShareMe : */ classes.disabledShareMe
              }`}
            >
              Share Me
            </button>

            <button
              className={`${classes.Button} ${
                selectedItems.length > 0 ? classes.enabledStyleMe : classes.disabledStyleMe
              }`}
              disabled={!selectedItems?.length}
              onClick={() => navigate("/styleme")}
            >
              Style Me
            </button>
          </div>
          {!loading ? (
            <>
              <div className={classes.rightHeader}>
                <div className={classes.itemsCount}>{sortedItems.length} ITEMS</div>
                <div
                  className={classes.sortOption}
                  onClick={() => setDropdownToggle((preVal) => !preVal)}
                >
                  Sort By {sortName}
                  <IconComponent iconTitle={dropdownToggle ? "UpArrow" : "DownArrow"} />
                  {dropdownToggle && (
                    <ActionDropDown
                      menuItemList={sortList}
                      setToggle={setDropdownToggle}
                      onValueChange={onToggleValueChange}
                    />
                  )}
                </div>
              </div>
              <div className={`${classes.buttonContainer} ${classes.mobileAction}`}>
                {!window.matchMedia("(max-width: 700px)") && (
                  <button
                    className={`${classes.Button} ${
                      selectedItems.length > 0 ? classes.enabledShareMe : classes.disabledShareMe
                    }`}
                  >
                    Share Me
                  </button>
                )}

                <button
                  className={`${classes.Button} ${
                    selectedItems.length > 0 ? classes.enabledStyleMe : classes.disabledStyleMe
                  }`}
                  disabled={!selectedItems?.length}
                  onClick={() => navigate("/styleme")}
                >
                  Style Me
                </button>
              </div>
              {sortedItems.length > 0 ? (
                <ClosetItems
                  items={sortedItems}
                  setItems={setItems}
                  fetchItemsAPI={fetchItemsAPI}
                  likedItem={likedItem}
                  dragStarted={dragStarted}
                />
              ) : (
                <EmptyCloset />
              )}
            </>
          ) : (
            <Loader />
          )}
        </div>
      </div>
      {confirmCateChange && (
        <ModalPortal>
          <DndConfirm
            modalResponse={updatePopupResponse}
            field={"category"}
            newValue={draggedItem.newCategory}
          />
        </ModalPortal>
      )}
    </div>
  );
}
