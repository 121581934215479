import { useState } from "react";
import { useStyles } from "./styles";
import IconComponent from "../fields/icon";
import { DropDownContainer, Header, SubHeader } from "./DropDownComponents";

export default function DropDownToggle({
  dropDownList,
  lookName,
  setSelectedList,
  setType,
  defaultName,
  orderToBeShown,
  actionValues,
  setSubCategoryToggle,
  subHeader = false,
}) {
  const classes = useStyles();
  const [toggle, setToggle] = useState(false);

  function SubSections({ listItems, handleClick }) {
    const displayList = listItems.filter((ele) => !ele.hidden);
    return displayList?.map((item, index) => (
      <div key={index}>
        <div
          className={`${classes.dropDownItem} ${item.isActive && classes.dropDownItemActive}`}
          onClick={() => handleClick(item, index)}
        >
          {item.name}
          {item.subCategories && (
            <div className={classes.subCategoryArrow}>
              <IconComponent iconTitle={item.isActive ? "UpArrow" : "DownArrow"} />
            </div>
          )}
        </div>
        {item.subCategories && item.isActive && 
          item.subCategories.map((subItem, index) => (
          <div
            key={index}
            className={`${classes.nestedDropDownItem} ${subItem.isActive && classes.dropDownItemActive}`}
            onClick={() => handleClick(subItem, index)}
          >
            {subItem.name}
          </div>))
        }
      </div>
    ));
  }

  return (
    <div className={classes.dropDownToggle}>
      <div className={classes.headerWrapper}>
        <Header
          onClick={() => {
            setToggle(!toggle);
          }}
        >
          {!subHeader && lookName && lookName.toLowerCase() !== "view all"
            ? lookName
            : defaultName }
          <IconComponent width="14px" height="14px" iconTitle={toggle ? "UpArrow" : "DownArrow"} />
        </Header>
        {subHeader && !toggle && lookName && lookName.toLowerCase() !== "view all" && (
          <SubHeader>{lookName}</SubHeader>
        )}
      </div>
      <DropDownContainer className={toggle && classes.curationDropDown}>
        {dropDownList && Array.isArray(dropDownList) ? (
          <SubSections
            listItems={dropDownList}
            handleClick={(item, i) => {
              setSelectedList(item, i);
              setToggle(false);
            }}
          />
        ) : (
          dropDownList &&
          orderToBeShown?.length &&
          orderToBeShown.map((key, i) =>
            i === 0 ? (
              <SubSections
                key={i}
                listItems={dropDownList[key]}
                handleClick={(item, index) => {
                  setType(item, index, false);
                  setToggle(false);
                }}
              />
            ) : (
              <>
                <div
                  className={`${classes.dropDownItem} ${
                    dropDownList[key]?.some((item) => item.isActive) && classes.active
                  }`}
                  onClick={() => setSubCategoryToggle(`${key}Toggle`)}
                >
                  {dropDownList[`${key}Name`]}
                  <IconComponent
                    iconTitle={dropDownList[`${key}Toggle`] ? "UpArrow" : "DownArrow"}
                  />
                </div>
                {dropDownList[`${key}Toggle`] && (
                  <SubSections
                    listItems={dropDownList[key]}
                    handleClick={(item, index) => {
                      setType(item, index, key === "curationType");
                      setToggle(false);
                    }}
                  />
                )}
              </>
            ),
          )
        )}
        {actionValues?.map((item, i) => (
          <div
            key={i}
            className={classes.dropDownItem}
            onClick={() => {
              item.iconClick(item);
              setToggle(false);
            }}
          >
            <IconComponent iconTitle={item.iconTitle} className={classes.actionIcon} />
            {item.displayName}
          </div>
        )) ?? []}
      </DropDownContainer>
    </div>
  );
}
