import { useStyles } from "./styles";
import { useState } from "react";
import IconComponent from "../../../components/fields/icon";
import { FavList } from "../../../containers/StyleMe";

export default function FavoriteLists(props) {
  const { outfits, index, likedItem } = props;
  const [isLiked, setIsLiked] = useState(false);
  const classes = useStyles();

  function handleLike(value) {
    setIsLiked(!isLiked);
    likedItem(value, outfits);
  }

  return (
    <div className={classes.FavoritesDetails}>
      <div className={classes.FavoritesListContainer} id={index}>
        <IconComponent
          className={`${classes.actionField} ${classes.likeVector}`}
          iconTitle="LikedVector"
          onClick={() => {
            handleLike(true);
          }}
        />
        <div className={classes.FavoritesListImages}>
          {outfits?.curationList?.length > 0 &&
            outfits.curationList.map((item, index) => (
              <FavList key={`index-${index}`}>
                <img className={classes.outfitImage} src={item.src} key={`src-${index}`} />
              </FavList>
            ))}
          {outfits?.itemList?.src && (
            <div className={classes.itemContainer}>
              <img className={classes.itemImg} src={outfits.itemList?.src} key={`src-${index}`} />
            </div>
          )}
        </div>
      </div>
      <div className={classes.itemDetails}>
        {outfits?.curationName
          ? outfits?.curationName
          : outfits?.itemName
          ? outfits?.itemName
          : outfits?.itemList?.itemName}
      </div>
    </div>
  );
}
