import { useEffect, useState } from "react";
import Modal from "../../../containers/common/modalPortal/modal";
import ModalPortal from "../../../containers/common/modalPortal";
import MailRow from "./MailRow";
import { useStyles } from "./styles";
import ScanPeriodModal from "./ScanPeriodModal";
import { useNavigate } from "react-router-dom";
import AddNewEmail from "./AddNewEmail";
import { useLazyQuery } from "@apollo/client";
import { getAuthEmails, getAuthUrl } from "../../../apollo/operations/authEmail";
import { openToast } from "../../../components/toast";
import { useDispatch, useSelector } from "react-redux";
import { setScanPeriod } from "../../../store/scanPeriodSlice";
import dayjs from "dayjs";
import AddYourStylePopup from "../AddYourStylePopup";

export default function ScanEmail() {
  const classes = useStyles();
  const [getAuthEmailsList, { data: emailsList }] = useLazyQuery(getAuthEmails);
  const [getUrl, { data: urlData }] = useLazyQuery(getAuthUrl);
  const [addedEmails, setAddedEmails] = useState([]);
  const [fetchAuthEmails, setFetchAuthEmails] = useState(false);
  const [displayPrivacyInfo, setDisplayPrivacyInfo] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("");
  const [displayScanInfo, setDisplayScanInfo] = useState(false);
  const navigate = useNavigate();
  const [selectedMail, setSelectedMail] = useState("");
  const dispatch = useDispatch();
  const { notShowAYSPopup } = useSelector((state) => state.showPopup);
  const scannedEmailResponse = useSelector((state) => state.scannedEmail.scannedEmail);
  let myWindow;
  let interval = null;

  useEffect(() => {
    if (emailsList) {
      setAddedEmails(emailsList.getAuthEmails);
    }
  }, [emailsList]);

  useEffect(() => {
    if (scannedEmailResponse.productsList && scannedEmailResponse.productsList.length > 0) {
      navigate("/addyourstyle/scanemail/newitem");
    }
  }, [scannedEmailResponse]);

  useEffect(() => {
    if (fetchAuthEmails) {
      getAuthEmailsList();
    }
  }, [fetchAuthEmails]);

  useEffect(() => {
    getAuthEmailsList();
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  useEffect(() => {
    if (urlData) {
      if (urlData.getAuthURL.message === "Email already Added by other user") {
        openToast("error", "Already Added !", "Email Already Added by other user");
      } else {
        setRedirectUrl(urlData.getAuthURL.data.url);
        setDisplayPrivacyInfo(true);
      }
    }
  }, [urlData]);

  function onClickMail(mailId) {
    setSelectedMail(mailId);
    if (!mailId.hasAccess) {
      getUrl({ variables: { email: mailId.email } });
      // displayPrivacyInfo comes true when getUrl gives response
    } else {
      setDisplayPrivacyInfo(true);
      if (displayScanInfo) {
        setDisplayScanInfo(false);
      }
    }
  }

  function onClickPrevious(mailId) {
    console.log("previous Button clicked with mailId - ", mailId);
    const { from, to } = JSON.parse(mailId.previousScan);
    dispatch(
      setScanPeriod({
        selectedMail: { email: mailId.email },
        scanDates: { toScanDate: dayjs(new Date(to)), fromScanDate: dayjs(new Date(from)) },
      }),
    );
    navigate("/addyourstyle/scanemail/newitem");
  }

  function handleButtonClick(value) {
    setDisplayPrivacyInfo(false);
    if (value) {
      if (selectedMail.hasAccess) {
        setDisplayScanInfo(true);
      } else {
        myWindow = window.open(redirectUrl);
        localStorage.setItem("grantedEmailAccess", "false");
        interval = setInterval(() => {
          // let access =localStorage.getItem("grantedEmailAccess");
          if (myWindow.closed) {
            clearInterval(interval);
            interval = null;
            setFetchAuthEmails(true);
          }
        }, 300);
      }
    }
  }

  function scanPeriodResponse(value) {
    if (typeof value === "object") {
      dispatch(setScanPeriod({ ...value, selectedMail: selectedMail }));
      navigate("/addyourstyle/scanemail/newitem");
    }
    setDisplayScanInfo(false);
  }

  function onMiklosetMail(mailId) {
    setSelectedMail({ email: mailId });
    setDisplayScanInfo(true);
  }

  return (
    <div className={classes.scanEmailBody}>
      <div className={classes.subHeader}>Scan Your Email Address</div>
      {/* NOTES: might be used in the future */}

      {/* <fieldset disabled style={{ border: "none", opacity: "40%" }}>
        <AddNewEmail setFetchAuthEmails={setFetchAuthEmails} onMiklosetDomain={onMiklosetMail} />
      </fieldset> */}

      <div className={classes.previousEmailsList}>
        {addedEmails?.map((email, index) => (
          <MailRow
            key={index}
            index={index}
            email={email}
            onClick={onClickMail}
            onClickPrevious={onClickPrevious}
            previousScan={email.previousScan}
          />
        ))}
      </div>
      {displayPrivacyInfo && (
        <ModalPortal>
          <Modal type={"privacyInfo"} handleButtonClick={handleButtonClick} />
        </ModalPortal>
      )}
      {displayScanInfo && (
        <ModalPortal>
          <ScanPeriodModal handleClick={scanPeriodResponse} />
        </ModalPortal>
      )}

      {!notShowAYSPopup && emailsList?.getAuthEmails && (
        <div id="addYourStyleModal">
          <AddYourStylePopup />
        </div>
      )}
    </div>
  );
}
