import { makeStyles } from "@material-ui/styles";
import { mobileMediaStyle, tabMediaStyle } from "../../components/helpers/ResponsiveStyleHelper";

export const useStyles = makeStyles({
  subText: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "30px",
    lineHeight: "40px",
    color: "black",

    marginBottom: "60px",
    textAlign: "center",
  },

  subTextCopy: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "28px",
    color: "black",
    marginBottom: "10px",
    textAlign: "center",
  },

  ageDropDown: {
    outline: "none",
    width: "100%",
    height: "54px",
    border: "1px solid rgb(0, 0, 0)",
    boxSizing: " border-box",
    padding: "5px 18px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: "10px",
    fontWeight: "400",
    fontSize: "17px",
  },

  inputBox: {
    display: "flex",
    width: "100%",
    padding: "20px",
    justifyContent: "center",
  },
  inputLink: {
    margin: "0px 10px",
    border: "1px solid",
    borderRadius: "10px",
    width: "100%",
    alignItems: "center",
    padding: "5px 16px",
    fontSize: "20px",
    height: "40px",
    marginTop: "5px",
  },
  copyButton: {
    backgroundColor: "rgb(251, 91, 1)",
    border: "0px",
    height: "53px",
    marginTop: "5px",
    marginLeft: "5px",
    marginRight: "6px",
    borderRadius: "10px",
    width: "60px",
    fontSize: "20px",
    color: "white",
    padding: "4px",
    marginBottom: "6px",
  },
  iconDiv: { display: "flex", gap: "10px" },
  ...tabMediaStyle({
    // subText: {
    //   fontSize: "20px",
    //   lineHeight: "24px",
    // },
    ageDropDown: {
      fontSize: "20px",
    },
  }),

  ...mobileMediaStyle(
    {
      ageDropDown: {
        fontSize: "14px",
      },
      //   subText: {
      //     fontSize: "30px",
      //     lineHeight: "24px",
      //     marginBottom: "50px",
      //   },
    },
    "@media (max-width:600px)",
  ),
});
