import styled from "styled-components";
import globalStyles from "../../styles/global";
// import AboutUsImage from "../../assets/images/aboutUs.png";
import AboutUsImage from "../../assets/images/aboutUs.jpg"

const { mediaQueries } = globalStyles;

export const AboutUsImageContainer = styled.div`
  height: 100%;
  min-height: 500px;
  width: 50%;
  background-image: url(${AboutUsImage});
  background-size: cover;
  background-position: center;

  @media (${mediaQueries.tablet}) {
    width: 100%;
    min-height: 350px;
  }

  @media (max-width: 600px) {
    min-height: 240px;
  }
`;
