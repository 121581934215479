import IconComponent from "../../../../components/fields/icon";
import SubCurations from "./SubCurations";

export default function AccessoriesTag({
  linearCategories,
  accessoriesList,
  removeCurationItem,
  parentIndex,
  classes,
}) {
  const subCategories = Object.keys(linearCategories).filter(
    (subItem) => linearCategories[subItem] === "Accessories",
  );

  const Beauty = accessoriesList.filter(
    (item) =>
      item.mainCategory === "Beauty"
  );
  const GroupList3 = [];
  for (let element of subCategories) {
    const arr2 = accessoriesList.filter((item) => item.category === element);
    GroupList3.push(...arr2); // Spread operator to add elements individually
  }

  const GroupList = [Beauty, GroupList3].filter((item) => item?.length);

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gridTemplateRows: "repeat(3, 1fr)",
        placeContent: "center",
        placeItems: "center",
        gridColumnGap: "0px",
        gridRowGap: "10px",
      }}
    >
      {GroupList.map((subItemList, itemIndex) => (
        <SubCurations
          key={itemIndex}
          subCategories={subCategories}
          subItemList={subItemList}
          removeCurationItem={removeCurationItem}
          parentIndex={parentIndex}
          classes={classes}
        />
      ))}
    </div>
  );
}
