import { useEffect } from "react";
import { createPortal } from "react-dom";

const modalRootEl = document.getElementById("modal-root");

export default function ModalPortal(props) {

      let el = document.createElement("div");

      useEffect(()=>{
        modalRootEl.appendChild(el);
        return ()=>{modalRootEl.removeChild(el)}
      },[])

      return createPortal( 
            props.children ,el
      ) 
}