import ScanEmail from "./Scan_Email.mp4";
import StyleMe from "./StyleMe.mp4";
import UploadImage from "./Upload_Image.mp4";
import UploadURL from "./Upload_URL.mp4";
import HowToMikloset from "./How_To_Use_Mikloset.mp4";
import UploadImageUrl from "./Upload_Image_URL.mp4";

const videoName = {
  "Scan Email": ScanEmail,
  "Upload Image": UploadImage,
  "Style Me": StyleMe,
  "Upload URL": UploadURL,
  "How To Mikloset": HowToMikloset,
  "Upload Image Url": UploadImageUrl,
};
export default videoName;
