import { useStyles } from "./styles";
import { memo, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import CurationListType from "./CurationListType";
import { openToast } from "../../components/toast";
import DropDownToggle from "../../components/MobieViewDropDown/DropDownToggle";
import { useDispatch, useSelector } from "react-redux";
import { actionDropDownSort } from "../../components/helpers/sortHelper";
import { setCurateData } from "../../store/curationSlice";
import Loader from "../../components/loaderScreen";
import ModalPortal from "../../containers/common/modalPortal";
import DndConfirm from "../../components/dndConfirmPopup";
import ViewCuration from "./ViewCuration";
import { curationCategoryList } from "./helperFunction";
import { removeCuration, updateCuration } from "../../apollo/operations/curation";

let draggedCuration = null;

function Curation({ loading }) {
  const dispatch = useDispatch();
  const { curateMeData } = useSelector((state) => state.curation);
  const [deleteCuration, { data: removeCurationDbData }] = useMutation(removeCuration);
  const [UpdateCuration, { data: updateCurationData }] = useMutation(updateCuration);
  const [confirmDrag, setConfirmDrag] = useState(false);

  const classes = useStyles();

  const [sortName, setSortName] = useState("");
  const [dropdownToggle, setDropdownToggle] = useState(false);
  const [lookName, setLookName] = useState("View All");

  useEffect(() => {
    if (removeCurationDbData?.removeCurations) {
      if (removeCurationDbData.removeCurations?.statusCode === "200") {
        openToast("success", "Successful!", "Curation deleted");
      } else {
        openToast("error", "Failed!", "Try Again");
      }
    }
  }, [removeCurationDbData]);

  function handleFilterView() {
    const tempList = curateMeData.filter(
      (item) => item.curationLook === lookName || lookName === "View All",
    );
    return tempList;
  }

  function handleCategoryName(curateItem) {
    setLookName(curateItem.name);
  }

  function handleUpdateCuration(newCurateData) {
    UpdateCuration({
      variables: {
        curationData: {
          ...newCurateData,
        },
      },
    });
    const newData = curateMeData.map((item) => {
      if (item?.id === Number(newCurateData?.curationId)) {
        newCurateData.id = item.id;
        delete newCurateData.curationId;
        return { ...item, ...newCurateData };
      }
      return item;
    });
    dispatch(setCurateData(newData));
  }

  function updateCurationsDetails(curation) {
    handleUpdateCuration(curation);
  }

  function removeCurationItem(index, curationData) {
    const mainIndex = curateMeData.findIndex((item) => item.id === curationData.id);
    let curList = [...curateMeData];
    curList?.splice(mainIndex, 1);

    dispatch(setCurateData(curList));
    deleteCuration({
      variables: {
        curationId: curationData.id.toString(),
      },
    });
  }

  function onToggleValueChange(selectedItem) {
    const sortedData = actionDropDownSort(
      selectedItem,
      selectedItem.name.includes("to") ? "curationName" : "createdAt",
    )(curateMeData);
    dispatch(setCurateData(sortedData));
    setSortName(() => selectedItem.name);
  }

  function dragStart(outfits) {
    draggedCuration = JSON.parse(JSON.stringify(outfits));
  }

  function onDropCuration(item, event) {
    event.target.style.backgroundColor = "";
    if (item.name !== draggedCuration.curationLook) {
      draggedCuration.newCurationLook = item.name;
      setConfirmDrag(true);
    }
  }

  function updatePopupResponse(value) {
    setConfirmDrag(false);
    if (value) {
      handleUpdateCuration({
        curationId: draggedCuration.id.toString(),
        curationLook: draggedCuration.newCurationLook,
      });
    } else {
      setConfirmDrag(false);
      draggedCuration = null;
    }
  }

  return (
    <>
      <div className={classes.parentContainer}>
        <div className={classes.title}>{"CURATIONS"}</div>
        <div className={classes.mainContainer}>
          <CurationListType
            list={curationCategoryList}
            handleCategoryName={handleCategoryName}
            onDropCuration={onDropCuration}
          />
          <DropDownToggle
            dropDownList={curationCategoryList}
            lookName={lookName}
            defaultName="My Curations"
            setSelectedList={handleCategoryName}
          />
          <div className={classes.rightContainer}>
            <hr className={classes.title} />
            {!loading ? (
              <ViewCuration
                ViewCurationData={handleFilterView()}
                removeCurationItem={removeCurationItem}
                updateCurationsDetails={updateCurationsDetails}
                dragStart={dragStart}
                handleUpdateCuration={handleUpdateCuration}
                onToggleValueChange={onToggleValueChange}
                setDropdownToggle={setDropdownToggle}
                dropdownToggle={dropdownToggle}
                sortName={sortName}
              />
            ) : (
              <Loader />
            )}
          </div>
        </div>
        {confirmDrag && (
          <ModalPortal>
            <DndConfirm
              modalResponse={updatePopupResponse}
              field={"look"}
              newValue={draggedCuration?.newCurationLook}
            />
          </ModalPortal>
        )}
      </div>
    </>
  );
}

export default memo(Curation);
