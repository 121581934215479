import { makeStyles } from "@material-ui/styles";
import { mobileMediaStyle, tabMediaStyle } from "../../../components/helpers/ResponsiveStyleHelper";

export const useStyles = makeStyles({
  hiddenCategoryHeader: {
    height: "100px",
    width: "100%",
    borderBottom : "1px solid #D9D9D9",
    boxSizing: "border-box",
  },

  subHeader: {
    padding: "40px 0 20px",
    fontSize: "20px",
    lineHeight: "23px",
    fontWeight: "400",
  },

  emptyContainer: {
    padding: "50px 0",
  },

  emptyHeader: {
    fontSize: "24px",
    lineHeight: "28px",
    fontWeight: "500",
    textAlign: "center",
    paddingTop: "18px",
    color: "#CACACA",
  },

  emptyMsg: {
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: "400",
    color: "#CACACA",
    textAlign: "center",
    paddingTop: "10px",
  }, 

  ...tabMediaStyle({
    hiddenCategoryHeader: {
      height: "75px",
    },
    subHeader: {
      padding: "30px 0 20px",
    },
  }),

  ...mobileMediaStyle({
    hiddenCategoryHeader: {
      display: "none",
    },
    hiddenCategoryBody: {
      padding: "16px",
    },
    subHeader: {
      display: "none",
    },
  }, "@media (max-width:600px)"),
})