import IconComponent from "../../../../components/fields/icon";
import { List } from "../../../../containers/StyleMe";

export default function SubCurations({
  subItemList,
  removeCurationItem,
  parentIndex,
  classes,
  subCategories,
}) {
  return (
    <div
      style={{
        display: "contents",
      }}
    >
      {subItemList?.map((item, itemIndex) => (
        <List
          key={`index-${itemIndex}`}
          style={{
            display: "flex",
            position: "relative",
            paddingBottom: "2px",
            justifyContent: "center",
          }}
          data-category={item?.category}
          data-maincategory={item?.mainCategory}
        >
          <IconComponent
            style={{ position: "absolute", right: "1%" }}
            iconTitle="CrossIcon"
            className={classes.trash}
            onClick={(event) => {
              event.stopPropagation();
              removeCurationItem(item?.id, parentIndex);
            }}
          />

          <img
            alt=""
            style={
              subCategories.some(
                (ListItem) => item?.category.includes(ListItem) || item.mainCategory === "Beauty",
              )
                ? {
                    maxWidth: "50px",
                    maxHeight: "50px",
                    minWidth: "50px",
                    minHeight: "",
                    width: "100%",
                    objectFit: "contain",
                  }
                : { width: "80px" }
            }
            className={classes.outfitImage}
            src={item?.src}
            key={`src-${itemIndex}`}
          />
        </List>
      ))}
    </div>
  );
}
