import styled from "styled-components";

import Icon from "./icon";
import globalStyle from "../../styles/global";

const { mediaQueries } = globalStyle;

const InputContainer = styled.div`
  // font-family: var(--primaryRegularFont);
  width: 100%;
  > p {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.03em;
    margin-bottom: 10px;
    user-select: none;
  }

  .errorText {
    display: flex;
    align-items: flex-end;
    user-select: none;

    > p {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.03em;
      color: #ff0000;
      margin-top: 12px;
      margin-left: 12px;
    }
  }
`;

const Field = styled.div`
  width: 100%;
  height: 100px;
  border: 1px solid ${({ error }) => (error ? "#ff0000" : "#000000")};
  box-sizing: border-box;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  border-radius: 10px;

  > textarea {
    font-family: var(--secondaryRegularFont);
    // font-family :inherit;
    outline: 0;
    background: inherit;
    border: none;
    resize: none;
    width: 100%;
    height: 100%;
    padding: 0;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.03em;
    color: #000000;
  }

  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px inset #ffffff !important;
    box-shadow: 0 0 0 30px inset #ffffff !important;
  }

  @media (${mediaQueries.mobile}) {
    padding: 10px 16px;
    & > textarea {
      font-size: 14px;
      line-height: unset;
    }
  }
`;

export default function TextArea({
  className,
  labelName,
  name,
  placeholder = "",
  onChange,
  id,
  value = "",
  icon,
  onIconClick,
  errorText = "",
}) {
  return (
    <InputContainer className={className}>
      {labelName && <p>{labelName}:</p>}
      <Field error={!!errorText}>
        <textarea name={name} onChange={onChange} placeholder={placeholder} id={id} value={value} />
        {icon && <Icon onClick={onIconClick} iconTitle={icon} />}
      </Field>
      {!!errorText && (
        <div className="errorText">
          <Icon iconTitle={"ErrorBoldIcon"} />
          <p>{errorText}</p>
        </div>
      )}
    </InputContainer>
  );
}
