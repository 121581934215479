import { useStyles } from "./styles";
import { useNavigate } from "react-router-dom";
import CurationLists from "../../components/CurationLists";
import IconComponent from "../../components/fields/icon";
import ActionDropDown from "../../components/fields/ActionDropDown";
import { sortList } from "../../components/helpers/sortHelper";

export default function ViewCuration(props) {
  const {
    ViewCurationData,
    removeCurationItem,
    updateCurationsDetails,
    handleUpdateCuration,
    onToggleValueChange,
    dragStart,
    setDropdownToggle,
    dropdownToggle,
    sortName,
  } = props;
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <>
      <div className={classes.rightHeader}>
        <div className={classes.itemsCount}>
          {ViewCurationData?.length ? ViewCurationData?.length : 0} ITEMS
        </div>
        <div className={classes.sortOption} onClick={() => setDropdownToggle((preVal) => !preVal)}>
          Sort By {sortName}
          <IconComponent iconTitle={dropdownToggle ? "UpArrow" : "DownArrow"} />
          {dropdownToggle && (
            <ActionDropDown
              menuItemList={sortList}
              setToggle={setDropdownToggle}
              onValueChange={onToggleValueChange}
            />
          )}
        </div>
      </div>
      {ViewCurationData?.length > 0 ? (
        <div className={classes.curationContainer}>
          {ViewCurationData.map(
            (outfits, index) =>
              outfits.curation.length > 0 && (
                <CurationLists
                  outfits={outfits}
                  key={index}
                  index={index}
                  sortedCuration={ViewCurationData}
                  removeCuration={removeCurationItem}
                  updateCurationsDetails={updateCurationsDetails}
                  handleCurateUpdate={handleUpdateCuration}
                  dragStart={dragStart}
                />
              ),
          )}
        </div>
      ) : (
        // <EmptyList title={"Curations"} />
        <div className={classes.infoContainer}>
          Curation is Empty, <span onClick={() => navigate("/styleme")}>click here</span> to
          navigate Style Me page and add Curation.
        </div>
      )}
    </>
  );
}
