import { useStyles } from "./styles";
import { mobileMaxWidth } from "../../components/helpers/ResponsiveStyleHelper";
import { useDispatch } from "react-redux";
import { setScanPeriod } from "../../store/scanPeriodSlice";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import ItemImage from "./ItemImage";
import IconComponent from "../../components/fields/icon";

export default function NotificationRow({
  notification,
  setDisplayDeleteNotification,
  setSelectedNotification,
  updateNotification,
  isToday,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const maxProductToShow = window.innerWidth <= mobileMaxWidth ? 1 : 3;

  function applyTimeDifference(timeValue) {
    const timeDiff = Math.abs(new Date() - new Date(timeValue));
    if (timeDiff / 36e5 < 1 && isToday) {
      return Math.floor(timeDiff / 6e4) === 0 ? "just now" : `${Math.floor(timeDiff / 6e4)}m ago`;
    } else return dayjs(new Date(timeValue)).format("hh:mm A");
  }

  function notificationClick(value) {
    // updating notification status
    notification.type !== "scheduledScan" && notification.isReply &&
      updateNotification({
        variables: {
          notificationId: notification.notificationId,
          isReply: false,
        },
      });

    if (value) {
      // code implementation for redirecting to respective screen based on notification type
      if (notification.type === "scheduledScan") {
        dispatch(
          setScanPeriod({
            selectedMail: { email: notification.body.email },
            scanDates: {
              fromScanDate: dayjs(new Date(notification.body.fromDate)),
              toScanDate: dayjs(new Date(notification.body.toDate)),
            },
          }),
        );
        navigate("/addyourstyle/scanemail/newitem");
      }
    }
  }

  function handleDeleteClick(e) {
    // Open the Modal for delete confirmation
    setDisplayDeleteNotification(true);
    setSelectedNotification(notification);
    e.stopPropagation();
  }

  function notificationProfile() {
    switch (notification.type) {
      case "scheduledScan":
        return <IconComponent className={classes.iconImage} iconTitle="Cart" />;
      case "AddItem":
      case "RemoveItem":
      case "PasswordReset":
      case "accessPermission":
        return <IconComponent className={classes.iconImage} iconTitle="Success" />;
      default:
        return <img className={classes.profileImage} src={notification.profileUrl} alt="profile" />;
      // <IconComponent iconTitle="NotificationLightIcon" className={classes.iconImage} />
    }
  }

  return (
    <div className={classes.notificationWrapper}>
      <div className={classes.logo}>{notificationProfile()}</div>
      {(notification.type === "AddItem" || notification.type === "RemoveItem") &&
        notification?.body?.productImageUrl
          .slice(0, maxProductToShow)
          ?.map((imageUrl, index) => (
            <ItemImage
              key={`${index}-itemImage`}
              index={index}
              totalCount={notification.body.productImageUrl.length}
              imageUrl={imageUrl}
              indexToShow={maxProductToShow - 1}
            />
          ))
      }
      <div className={classes.content}>
        <div className={classes.subHeader}>
          <div className={classes.subject}>{notification.subject}</div>
          <div className={classes.creationDetails}>
            {notification.type === "scheduledScan" && (
              <div className={classes.responseSection}>
                <div className={`${classes.responseButton} ${classes.addButton}`} onClick={() => notificationClick(true)}>
                  <IconComponent iconTitle="AddMark" width="100%" height="100%" />
                </div>
              </div>
            )}
            {notification.type === "accessPermission" && (
              <div className={classes.responseSection}>
                <div className={`${classes.responseButton} ${classes.acceptButton}`} onClick={() => notificationClick(true)}>
                  <IconComponent
                    iconTitle="GreenTick"
                    width="100%"
                    height="100%"
                  />
                </div>
                <div className={`${classes.responseButton} ${classes.rejectButton}`} onClick={() => notificationClick(false)}>
                  <IconComponent iconTitle="RedCross" width="100%" height="100%" />
                </div>
              </div>
            )}
            <IconComponent className={classes.clockSvg} iconTitle="Clock" />
            <div className={classes.dateAndTime}>{applyTimeDifference(notification.time)}</div>
            <IconComponent
              className={classes.deleteSvg}
              onClick={(e) => handleDeleteClick(e)}
              iconTitle="Trash"
            />
          </div>
        </div>
        <div className={classes.message}>{notification.message}</div>
      </div>
    </div>
  );
}
