import { useState } from "react";
import {
  ButtonContainter,
  CircleSvg,
  MainText,
  ModalContainer,
  Overflow,
  SvgContainer,
} from "../../components/modal";
import Button from "../../components/fields/button";
import IconComponent from "../../components/fields/icon";
import { useStyles } from "./styles";
import { moduleDetails, modules } from "../../components/HowItWork/constants";
import ModalPortal from "../../containers/common/modalPortal";
import VideeoTour from "./VideoTour";

export default function HowIsWork(props) {
  const { modalResponse } = props;
  const classes = useStyles();
  const [selectedModule, setSelectedModule] = useState(null);
  const [openVideoTour, setOpenVideoTour] = useState(null);

  function handleModuleClick(flow) {
    setSelectedModule(moduleDetails[flow.value]);
  }

  function handleVideoModal() {
    setOpenVideoTour(!openVideoTour);
  }

  function handleChoiceResult(value) {
    if (!value) {
      setSelectedModule(null);
    }
  }

  function Module(props) {
    const { flow } = props;
    return (
      <div className={classes.modules} onClick={() => handleModuleClick(flow)}>
        <p className={classes.moduleTitle}>{flow.title}</p>
        <p className={classes.moduleSummary}>{flow.summary}</p>
      </div>
    );
  }

  function Step(props) {
    const { step } = props;
    return (
      <div className={classes.stepContainer}>
        <p className={classes.stepHead}>{step.stepCount}</p>
        <p className={classes.stepBody}>
          {step.stepDescription.map((msg, index) => (
            <span
              className={
                msg.type === "highlight"
                  ? classes.highlight
                  : msg.type === "italic"
                  ? classes.italic
                  : msg.type === "bold"
                  ? classes.bold
                  : msg.type === "subTitle"
                  ? classes.subTitle
                  : classes.para
              }
              key={index}
            >
              {msg.content}
            </span>
          ))}
        </p>
      </div>
    );
  }

  return (
    <>
      {" "}
      <Overflow onClick={() => modalResponse(false)}>
        <ModalContainer
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {!selectedModule ? (
            <>
              <SvgContainer>
                <CircleSvg>
                  <IconComponent iconTitle={"QuestionMark"} />
                </CircleSvg>
              </SvgContainer>

              <MainText>{"How It Works?"}</MainText>

              <div className={classes.modulesWrapper}>
                {modules.map((module, index) => (
                  <Module flow={module} key={index} />
                ))}
              </div>
              <div
                className={classes.videoTour}
                style={{ color: "#FB5B01" }}
                onClick={handleVideoModal}
              >
                Video Tour
              </div>
            </>
          ) : (
            <>
              <MainText>{selectedModule.title}</MainText>
              <div className={classes.subText}>{selectedModule.description}</div>
              <div className={classes.stepBody}>
                {selectedModule.steps.map((step, index) => (
                  <Step step={step} key={index} />
                ))}
              </div>
              <ButtonContainter>
                <Button
                  buttontype="primary"
                  size="default"
                  onClick={() => {
                    handleVideoModal();
                    handleChoiceResult(true);
                  }}
                >
                  {"Video Tour"}
                </Button>
                <Button
                  buttontype="secondary"
                  size="default"
                  onClick={() => handleChoiceResult(false)}
                >
                  {"Cancel"}
                </Button>
              </ButtonContainter>
            </>
          )}
        </ModalContainer>
      </Overflow>
      {openVideoTour && (
        <ModalPortal>
          <VideeoTour
            modalResponse={handleVideoModal}
            title={selectedModule?.title || "How To Mikloset"}
          />
        </ModalPortal>
      )}
    </>
  );
}
