import { useRef, useState } from "react";
import {
  ButtonContainter,
  CircleSvg,
  MainText,
  ModalContainer,
  Overflow,
  SmallSvgContainer,
} from "../../../components/modal";
import Button from "../../../components/fields/button";
import IconComponent from "../../../components/fields/icon";
import { useStyles } from "./styles";
import CategoriesDropDown from "../../../components/categoriesDropDown";
import { useSelector } from "react-redux";
import Toggle from "../../../components/fields/toggle";
import ModalPortal from "../../../containers/common/modalPortal";
import LinkToItemPopUp from "./linkToItemPopUp";

export default function EditClosetItem(props) {
  const classes = useStyles();
  const { modalResponse, item } = props;
  const inputRef = useRef(null);
  const [itemDetails, setItemDetails] = useState(item);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const categoriesList = useSelector((state) => state.category.category);
  const [toggle /* , setToggle */] = useState(false);
  const [image, setImage] = useState("");
  const [openLinkPopUp, setopenLinkPopUp] = useState(null);

  function handleChoiceResult(value) {
    modalResponse(value, itemDetails);
  }
  function handleValueChange(value, type) {
    if (type === "size") {
      if (value.length > 7) {
        return;
      }
      value = value.toUpperCase();
    }
    setItemDetails({ ...itemDetails, [type]: value });
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleOpen(event) {
    return setAnchorEl(event.currentTarget);
  }

  function handleSelectImage() {
    inputRef.current.click();
  }

  function handleMenuClick(e) {
    setItemDetails({ ...itemDetails, category: e });
    handleClose();
  }

  function handleToggle() {
    // for now toggle is disabled till sell me flow don't implemented
    // setToggle(!toggle)
  }

  function handleFileUpload(e) {
    const { files } = e.target;
    if (files && files.length) {
      setImage(URL.createObjectURL(files[0]));
      itemDetails.image = files[0];
      setItemDetails({ ...itemDetails });
    }
  }
  function handleLinkModal(linkToItemStore) {
    if (typeof linkToItemStore === "string" && linkToItemStore.includes("http")) {
      handleValueChange(linkToItemStore, "productStoreUrl");
      console.log(linkToItemStore);
    }
    setopenLinkPopUp(!openLinkPopUp);
  }

  return (
    <>
      <Overflow>
        <ModalContainer>
          {!toggle && (
            <SmallSvgContainer>
              <CircleSvg>
                <IconComponent iconTitle={"EditLigthIcon"} />
              </CircleSvg>
            </SmallSvgContainer>
          )}

          <MainText>{"Edit"}</MainText>
          <div className={classes.imgContainer} onClick={handleSelectImage}>
            <input
              type="file"
              ref={inputRef}
              style={{ display: "none" }}
              onChange={handleFileUpload}
              accept="image/*"
            />
            <img
              className={classes.itemImage}
              src={image !== "" ? image : itemDetails.src}
              onError={({ currentTarget }) => (currentTarget.style.display = "none")}
            />
            <div className={classes.uploadLogo}>
              <IconComponent iconTitle={"CameraLinearIcon"} />
            </div>
          </div>

          <div className={classes.inputContainer}>
            <div className={classes.inputLabel}>
              {"Category:"}
              <span>{"*"}</span>
            </div>
            <div className={classes.selectInputWrapper}>
              <CategoriesDropDown
                selectedCategory={itemDetails.category}
                handleClose={handleClose}
                handleOpen={handleOpen}
                categoriesList={categoriesList}
                open={open}
                handleMenuClick={handleMenuClick}
              />
            </div>
          </div>

          <div className={classes.inputsParents}>
            <div className={classes.inputContainer}>
              <div className={classes.inputLabel}>{"Product name:"}</div>
              <div className={classes.inputWrapper}>
                <input
                  className={classes.textInput}
                  type="text"
                  value={itemDetails.itemName}
                  onChange={(e) => handleValueChange(e.target.value, "itemName")}
                />
              </div>
            </div>
            <div className={classes.inputContainer}>
              <div className={classes.inputLabel}>{"Brand name:"}</div>
              <div className={classes.inputWrapper}>
                <input
                  className={classes.textInput}
                  type="text"
                  value={itemDetails.brand}
                  onChange={(e) => handleValueChange(e.target.value, "brand")}
                />
              </div>
            </div>
          </div>

          <div className={classes.inputsParents}>
            <div className={classes.inputContainer}>
              <div className={classes.inputLabel}>{"Color:"}</div>
              <div className={classes.inputWrapper}>
                <input
                  className={classes.textInput}
                  type="text"
                  value={itemDetails.color}
                  onChange={(e) => handleValueChange(e.target.value, "color")}
                />
              </div>
            </div>
            <div className={classes.inputContainer}>
              <div className={classes.inputLabel}>{"Size:"}</div>
              <div className={classes.inputWrapper}>
                <input
                  className={classes.textInput}
                  type="text"
                  value={itemDetails.size}
                  onChange={(e) => handleValueChange(e.target.value, "size")}
                />
              </div>
            </div>
          </div>

          {itemDetails?.productStoreUrl ? (
            <div className={classes.toggleContainer}>
              <a
                href={itemDetails.productStoreUrl}
                target="_blank"
                rel="noreferrer"
                className={classes.anchorLink}
              >
                {"Link To Item"}
              </a>
            </div>
          ) : (
            <div className={classes.toggleContainer} onClick={handleLinkModal}>
              <div className={classes.anchorLink} style={{ cursor: "pointer" }}>
                Add Link To Item
              </div>
            </div>
          )}

          <div className={classes.toggleContainer}>
            <div className={classes.inputLabel}>{"Sell this item:"}</div>
            <Toggle onClick={handleToggle} active={toggle} />
          </div>

          {/* {toggle && (
          <>
            <div className={classes.inputContainer}>
              <div className={classes.inputLabel}>
                {"Price: "}
                <span>{"*"}</span>
              </div>
              <div className={classes.inputWrapper}>
                <input
                  className={classes.textInput}
                  type="text"
                  value={itemDetails.color}
                  onChange={(e) => handleValueChange(e.target.value, "price")}
                />
              </div>
            </div>
            <div className={classes.inputContainer}>
              <div className={classes.inputLabel}>
                {"Description: "}
                <span>{"*"}</span>
              </div>
              <div className={classes.inputWrapper}>
                <input
                  className={classes.textInput}
                  type="text"
                  value={itemDetails.size}
                  onChange={(e) => handleValueChange(e.target.value, "description")}
                />
              </div>
            </div>
          </>
        )} */}

          <ButtonContainter>
            <Button buttontype="primary" size="default" onClick={() => handleChoiceResult(true)}>
              {"Save"}
            </Button>
            <Button buttontype="secondary" size="default" onClick={() => handleChoiceResult(false)}>
              {"Cancel"}
            </Button>
          </ButtonContainter>
        </ModalContainer>
      </Overflow>
      {openLinkPopUp && (
        <ModalPortal>
          <LinkToItemPopUp modalResponse={handleLinkModal} />
        </ModalPortal>
      )}
    </>
  );
}
