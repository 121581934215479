import { useRef, useEffect } from "react";
import styled from "styled-components";
import { useNavigate, Link } from "react-router-dom";

import getUser from "../../hooks/useQueryMe";
import { ButtonLink } from "../../components/fields/button";
import globalStyles from "../../styles/global";
import videoSrcMp4 from "../../assets/images/rollingCloset.mp4";
import videoSrcWebm from "../../assets/images/rollingCloset.webm";

const { mediaQueries } = globalStyles;

const Container = styled.div`
  width: 100%;
  display: flex;
  height: 100dvh;
  overflow: auto;
  display: flex;
  flex-direction: column;

  video {
    width: 100dvw;
    height: 100dvh;
    object-fit: cover;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  @media screen and (${mediaQueries.mobile}) {
    background-position-x: -240px;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  flex-direction: column;
`;

const SubContainer = styled(Flex)`
  padding: 40px 50px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 100%);
  box-sizing: border-box;

  @media screen and (${mediaQueries.tablet}) {
    padding: 20px;
  }

  @media screen and (${mediaQueries.mobile}) {
    padding: 15px 5px;
    max-width: 95%;
  }
`;

const Heading = styled.h2`
  font-family: var(--primaryHeaderFont);
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 80px;
  color: white;
  margin-bottom: 30px;
  letter-spacing: 0.25em;

  @media screen and (${mediaQueries.tablet}) {
    font-size: 50px;
    line-height: 64px;
  }

  @media screen and (${mediaQueries.mobile}) {
    font-size: 36px;
    line-height: 45px;
  }
`;

const Sub = styled.p`
  font-family: var(--primaryRegularFont);
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: white;
  letter-spacing: 0.21em;
  margin-bottom: 80px;
  text-align: center;

  @media screen and (${mediaQueries.tablet}) {
    font-size: 20px;
    line-height: 24px;
  }

  @media screen and (${mediaQueries.mobile}) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 50px;
  }
`;

const ButtonContainer = styled(Flex)`
  row-gap: 24px;
  > a {
    font-size: 32px;
    line-height: 38px;
    border-width: 4px;
  }

  @media screen and (${mediaQueries.tablet}) {
    > a {
      width: 260px;
      height: 60px;
      font-size: 26px;
    }
  }

  @media screen and (${mediaQueries.mobile}) {
    row-gap: 15px;
    > a {
      width: 219px;
      height: 64px;
      font-size: 20px;
      line-height: 23px;
      border-width: 2px;
    }
  }
`;

const AboutContactUs = styled.p`
   {
    font-family: var(--primaryRegularFont);
    font-style: normal;
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 0 0 30px;
    font-weight: 400;
    color: #fff;
    font-size: 17px;
    cursor: default;
    > a {
      cursor: pointer;
      text-decoration: none;
      font-family: var(--primaryRegularFont);
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      color: white;
    }
  }
`;

const AboutContactChild = styled.p`
   {
    cursor: pointer;
    > a {
      text-decoration: none;
      font-family: var(--primaryRegularFont);
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 36px;
      display: flex;
      align-items: center;
      letter-spacing: 0.03em;
      color: white;
    }
  }
`;

export default function Landing({ landingType }) {
  const { currentUser } = getUser();
  const videRef = useRef(null);
  const navigate = useNavigate();

  const setPlayBack = () => {
    videRef.current.playbackRate = "0.7";
  };

  useEffect(() => {
    if (currentUser) {
      navigate("/dashboard");
    }
  }, [currentUser]);

  return (
    <Container>
      <video autoPlay loop muted preload="true" onCanPlay={setPlayBack} ref={videRef} playsInline>
        <source src={videoSrcMp4} type="video/mp4" />
        <source src={videoSrcWebm} type="video/webm" />
      </video>
      <SubContainer>
        <Heading>MIKLOSET</Heading>
        {!landingType ? (
          <>
            <Sub style={{ margin: "0px 0px 30px" }}>FASHION YOUR SEATBELT!</Sub>
            <Sub style={{ margin: "0px 0px" }}>A REVOLUTIONARY FASHION SOCIAL MEDIA </Sub>
            <Sub>NETWORK IS ABOUT TO BE UNLEASHED!</Sub>
            <ButtonContainer>
              <ButtonLink to="/waitlist" size="primary" buttontype="primary">
                Join Our Waitlist
              </ButtonLink>
            </ButtonContainer>
          </>
        ) : (
          <>
            <Sub>SHOP & SELL ITEMS WITHIN YOUR OWN CLOSET</Sub>
            <ButtonContainer>
              {/* <ButtonLink to="/signup" size="primary" buttontype="primary">
                Sign Up
              </ButtonLink>
              <ButtonLink to="/signin" size="primary" buttontype="default">
                Sign In
              </ButtonLink> */}
              <ButtonLink to="/betatesters/signup" size="primary" buttontype="primary">
                Sign Up
              </ButtonLink>
              <ButtonLink to="/betatesters/signin" size="primary" buttontype="default">
                Sign In
              </ButtonLink>
            </ButtonContainer>
          </>
        )}
      </SubContainer>
      <AboutContactUs>
        <Link to={"/aboutus"}>About Us</Link>
        <p>|</p>
        <Link to={"/contactus"}>Contact Us</Link>
      </AboutContactUs>
    </Container>
  );
}
