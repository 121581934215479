import { ModalContainer, Overflow } from "../../components/modal";
import videoName from "../../assets/video/how_it_works/index";
export default function VideeoTour({ modalResponse, title }) {
  
  return (
    <Overflow onClick={() => modalResponse()}>
      <ModalContainer
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={{ padding: "0px", minHeight: "fit-content" }}
      >
        <video width="100%" height="100%" controls>
          <source src={videoName[title]} type="video/mp4" />
        </video>
      </ModalContainer>
    </Overflow>
  );
}
