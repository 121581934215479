import { useStyles } from "../styles";
import { useEffect, useState } from "react";
import IconComponent from "../../../components/fields/icon";
import {
  DressContainer,
  InspiredList,
  LikeIcon,
  AccessoriesContainer,
  TopAccessoriesImage,
  Container,
  ImageContainer,
  DressImage,
  AccessoriesImage,
} from "../../../containers/StyleMe";

export default function Inspired({handleCurationType, outfits, index, showAddCurationsPopup, addOutfits, addCurationToFavorite, isLiked = false, removeFromFavGetInspired, id }) {
  const classes = useStyles();

  const [dress, setDress] = useState([]);
  const [topAccessories, setTopAccessories] = useState([]);
  const [midAccessories, setMidAccessories] = useState([]);
  const [bottomAccessories, setBottomAccessories] = useState([]);
  const [suitsOuterwear, setSuitsOuterwear] = useState([]);

  useEffect(() => {
    const dressData = outfits.filter((item) => item.position === "left");
    const accessoriesData = outfits.filter((item) => item.position === "right");

    const suitsOuterwearData = accessoriesData.filter((item) => item.priority === 3.5);
    const topAccessoriesData = accessoriesData.filter(
      (item) => item.coordinatePosition === "top" && item.priority !== 3.5,
    );
    const midAccessoriesData = accessoriesData.filter(
      (item) => item.coordinatePosition === "middle",
    );
    const bottomAccessoriesData = accessoriesData.filter(
      (item) => item.coordinatePosition === "bottom",
    );

    setDress(dressData);
    setSuitsOuterwear(suitsOuterwearData);
    setTopAccessories(topAccessoriesData);
    setMidAccessories(midAccessoriesData);
    setBottomAccessories(bottomAccessoriesData);
  }, [outfits]);

  function handlePopup(value) {
    showAddCurationsPopup(true, index);
    addOutfits({ outfits, isLiked: value, isAdded: false, index });
    handleCurationType("getInspired")
    
  }

  function removeCurationFromFavorite(id, index) {
    removeFromFavGetInspired(id, index);
  }
  
  return (
    <div className={classes.InspiredContainer} id={index}>
      <LikeIcon
        onClick={(event) => {
          event.stopPropagation();
          isLiked ? removeCurationFromFavorite(id, index) : handlePopup(true);
        }}
      >
        <IconComponent
          className={classes.vector}
          iconTitle={isLiked ? "LikedVector" : "UnLikedVector"}
        />
      </LikeIcon>
      <InspiredList>
        <DressContainer>
          {dress?.map((item, index) => (
            <ImageContainer key={index}>
              <DressImage src={item.src} alt={`Dress ${index + 1}`} />
            </ImageContainer>
          ))}
        </DressContainer>

        <AccessoriesContainer>
          <Container style={{gridTemplateColumns: `repeat(${suitsOuterwear.length ? 2 : 1}, 1fr)`}}>
            {suitsOuterwear?.map((item, index) => (
              <ImageContainer key={index}>
                <DressImage src={item.src} alt={`Dress ${index + 1}`} />
              </ImageContainer>
            ))}
            <Container style={{gridTemplateColumns: `repeat( ${topAccessories.length <= 3 ? Math.ceil(topAccessories.length/1) : Math.ceil(topAccessories.length/3)}, 1fr)`, gridTemplateRows: `repeat(${Math.ceil(topAccessories.length/(suitsOuterwear.length ? 2 : 3))}, 1fr)`}}>
              {topAccessories?.map((item, index) => (
                <ImageContainer key={index}>
                  <TopAccessoriesImage src={item.src} alt={`topAccessories ${index + 1}`} />
                </ImageContainer>
              ))}
            </Container>
          </Container>

          <Container>
            {midAccessories?.map((item, index) => (
              <ImageContainer key={index}>
                <AccessoriesImage src={item.src} alt={`midAccessories ${index + 1}`} />
              </ImageContainer>
            ))}
          </Container>
          <Container>
            {bottomAccessories?.map((item, index) => (
              <ImageContainer key={index}>
                <AccessoriesImage src={item.src} alt={`bottomAccessories ${index + 1}`} />
              </ImageContainer>
            ))}
          </Container>
        </AccessoriesContainer>
      </InspiredList>
    </div>
  );
}
