import { ModalContainer, Overflow, SubText } from "../../components/modal";
import { useStyles } from "./styles";
import addYourStylePopup from "../../assets/images/addYourStylePopup.png";
import IconComponent from "../../components/fields/icon";
import { useEffect, useState } from "react";
import { getAuthEmails } from "../../apollo/operations/authEmail";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfile } from "../../apollo/operations/user";
import { setNotShowAYSPopup } from "../../store/showPopupSlice";

export default function AddYourStylePopup() {
  const localStorage = window.localStorage.getItem("addyourStylePopup");
  const { showAddyourStylePopup } = useSelector((state) => state.showPopup);
  const [showPopup, setShowPopup] = useState(true);
  const [email, setEmail] = useState("");
  const [doNotShow, setDoNotShow] = useState(showAddyourStylePopup);
  const [getAuthEmailsList, { data: emailsList }] = useLazyQuery(getAuthEmails);
  const [updateShowPopup] = useMutation(updateUserProfile);
  const dispatch = useDispatch();

  const classes = useStyles();

  useEffect(() => {
    if (!emailsList) {
      getAuthEmailsList();
    }
    if (!email && emailsList?.getAuthEmails) {
      const email = emailsList?.getAuthEmails?.filter((item) =>
        item?.email?.includes("mikloset.com"),
      )[0]?.email;
      setEmail(email);
    }
  }, [emailsList]);

  function modalResponse() {
    dispatch(setNotShowAYSPopup(true));
    setShowPopup(!showPopup);
    if (doNotShow) {
      window.localStorage.setItem("addyourStylePopup", true);
      updateShowPopup({
        variables: { userDetails: { addYourStylePopup: !showAddyourStylePopup } },
      });
    }
  }

  return localStorage ? (
    ""
  ) : showPopup && !showAddyourStylePopup ? (
    <Overflow onClick={modalResponse}>
      <ModalContainer
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={classes.modalBox}
      >
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div className={classes.desktopViewImage}>
            <p style={{ padding: "5px 0px", fontSize: "16px" }}>Example Receipt:</p>
            <img style={{ width: "90%" }} src={addYourStylePopup} alt="addYourStylePopup" />
          </div>
          <div style={{ marginLeft: "5px" }}>
            <div>
              <SubText className={classes.mainText}>Scan Email Functionality</SubText>
            </div>
            <div>
              <SubText className={classes.subText}>
                To get started, forward your retail receipts to {email} email address.
              </SubText>
              <SubText className={classes.subText}>
                *Note: For this feature to be effective, we recommend using receipts with images
                and/or description.
              </SubText>
            </div>
          </div>
        </div>
        <div className={classes.mobileViewImage}>
          <p>Example Receipt:</p>
          <img style={{ width: "100%" }} src={addYourStylePopup} alt="addYourStylePopup" />
        </div>
        <div>
          {" "}
          <div
            className={classes.checkboxWrapper}
            onClick={() => setDoNotShow((preVal) => !preVal)}
          >
            <IconComponent
              className={classes.checkboxIcon}
              iconTitle={doNotShow ? "BoldCheckBox" : "LightCheckBox"}
            />
            Do Not Show Again
          </div>
        </div>
      </ModalContainer>
    </Overflow>
  ) : (
    <div>{""}</div>
  );
}
