import { makeStyles } from "@material-ui/styles";
import {
  largeTabletMediaStyle,
  mobileMediaStyle,
  tabMediaStyle,
  smallTabMediaStyle,
  smallMobileMediaStyle,
  largerThanTabletMediaStyle,
} from "../../components/helpers/ResponsiveStyleHelper";
import ContactUsImage from "../../assets/images/contactUs.jpg";

export const useStyles = makeStyles({
  miklosetTitle: {
    padding: "26px 60px",
    fontFamily: "var(--primaryHeaderFont)",
    fontSize: "36px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "45px",
    color: "rgb(0, 0, 0)",
    letterSpacing: "9px",
  },
  contactUsDiv: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: "space-around",
    justifyContent: "center",
    columnGap: "100px",
    margin: "5vh 0vw",
    // height: "75vh",
  },
  rightSection: {
    display: "flex",
    flexDirection: "column",
    width: "35vw",
  },
  title: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "28px",
    lineHeight: "40px",
    color: "#000000",
    textAlign: "center",
    letterSpacing: "6.5px",
    display: "flex",
    flexDirection: "row",
    fontFamily: "var(--primaryHeaderFont)",
    justifyContent: "center",
  },
  name: {
    display: "flex",
    flexDirection: "row",
    columnGap: "25px",
  },
  contactText: {
    fontFamily: "Arial, 'Helvetica Neue', Helvetica, sans-serif",
    fontSize: "16px",
    margin: "30px 0px",
    justifySelf: "center",
    textAlign: "justify",
  },
  formCard: {
    boxShadow: "0 4px 40px 0 rgba(0,0,0,.15);",
    padding: "30px",
    borderRadius: "5px",
  },
  imageDiv: {
    backgroundImage: `url(${ContactUsImage})`,
    backgroundSize: "cover",
    // height: "100%",
    width: "35vw",
    maxWidth: "100%",
    maxHeight: "100%",
    backgroundPosition: "center",
  },
  // image: {
  //     height: "486px"
  // },
  // ...largeTabletMediaStyle({

  // contactUsDiv: {
  //     display: "grid",
  //     flexDirection: "column",
  //     justifyContent: "center"
  // },
  // subContactUsDiv: {

  // },
  // contactText: {
  //     // width: "70vw",
  // },
  // formCard: {
  //     // margin: "100px"
  // },
  // rightSection: {
  //     width: "unset"
  // },
  // image: {
  //     width: "100%"
  // },
  // title: {
  //     marginTop: "30px"
  // }
  // }),

  ...tabMediaStyle({
    contactUsDiv: {
      display: "grid",
      flexDirection: "column",
      justifyContent: "center",
      margin: "30px 0",
      height: "unset",
    },
    subContactUsDiv: {
      display: "grid",
      flexDirection: "column",
      justifyContent: "center",
    },
    contactText: {
      margin: "15px 0 ",
      // width: "70vw",
    },
    formCard: {
      // margin: "80px"
      marginTop: "25px",
    },
    imageDiv: {
      // margin: "0 40px",
      width: "unset",
      height: "50vh",
    },
    rightSection: {
      width: "unset",
    },
    image: {
      width: "100%",
    },
    title: {
      marginTop: "30px",
    },
  }),
  ...smallTabMediaStyle({
    contactText: {
      fontSize: "14px",
      width: "unset",
      margin: "10px 20px",
    },
    title: {
      fontSize: "20px",
    },
    formCard: {
      margin: "20px",
    },
    imageDiv: {
      margin: "0 20px",
    },
  }),
  ...mobileMediaStyle({
    contactUsDiv: {
      margin: "0px",
    },
    formCard: {
      margin: "20px",
    },
    contactText: {
      fontSize: "14px",
      width: "unset",
      margin: "10px 20px",
    },
    imageDiv: {
      margin: "16px 20px",
    },
    title: {
      marginTop: "10px",
    },
  }),
  ...smallMobileMediaStyle({
    title: {
      fontSize: "15px",
    },
    contactText: {
      fontSize: "14px",
      width: "unset",
    },
    formCard: {
      margin: "20px 0px",
    },
  }),
});
