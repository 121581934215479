import { useDispatch } from "react-redux";
import { setActiveCurationLook } from "../../store/curationSlice";
import { useNavigate } from "react-router-dom";
import { useStyles } from "./style";

export default function CurationCard({ curationList, style, index, totalCount, curationName }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className={classes.CurationCardContainer} style={style}>
      {index === 2 && totalCount !== index + 1 && (
        <span
          className={classes.countClass}
          onClick={() => {
            navigate("/curateme");
            dispatch(setActiveCurationLook(curationName));
          }}
        >
          +{totalCount - 2}
        </span>
      )}
      <div
        className={classes.CurationCardImage}
        style={{
          gridTemplateColumns: totalCount === 1 ? "repeat(auto-fit, minmax(70px, auto))" : "",
        }}
      >
        {curationList.map((item) => (
          <img key={item.id} className={classes.outfitImage} src={item.src} alt={item.itemName} />
        ))}
      </div>
    </div>
  );
}
