import io from "socket.io-client";
import socketEvents from "./socketEvents";
import { setNotifications, setStyleMe } from "../../store/socketSlice";
import { addGroupData } from "./styleMeHelper";
let socketInstance = null;

function createSocketConnection(user, dispatch) {
  if (!socketInstance && process.env.REACT_APP_SOCKET_URL) {
    socketInstance = io(process.env.REACT_APP_SOCKET_URL, { transports: ["websocket"] });
    socketInstance?.emit(socketEvents.addUser, user);

    // Listen Server Events
    listenEvents(dispatch);
  }
  return socketInstance;
}

function getSocketInstance() {
  if (!socketInstance) {
    return null;
  }
  return socketInstance;
}

function removeSocketConnection() {
  if (socketInstance) {
    socketInstance.disconnect();
    socketInstance = null;
  }
}

function listenEvents(dispatch) {
  socketInstance.on(socketEvents.notification, (notificationInfo) =>
    dispatch(setNotifications(notificationInfo)),
  );

  socketInstance.on(socketEvents.styleMe, (styleMeInfo) => {
    const tempCuration = styleMeInfo?.newData?.CurationList?.map((item) => ({
      ...item,
      curations: item?.curations
        ?.map((cuItem) =>
          addGroupData(styleMeInfo?.newData?.SelectedList)?.filter(
            (siItem) => `${siItem.id}` === `${cuItem}`,
          ),
        )
        .flat(),
    }));

    let { CurationList, SelectedList } = { ...styleMeInfo?.newData };
    SelectedList = addGroupData(
      SelectedList.map((item) => ({
        ...item,
        isSelected: true,
      })),
    );

    SelectedList = SelectedList.filter(
      (obj, index) => SelectedList.findIndex((item) => item.id === obj.id) === index,
    );

    dispatch(
      setStyleMe({
        CurationList: tempCuration,
        SelectedList: SelectedList,
      }),
    );
  });
}

export { createSocketConnection, getSocketInstance, removeSocketConnection };
