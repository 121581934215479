import { useEffect, useRef } from "react";
import { Option } from "./styles";

export default function CardOption(props) {
  const {
    outfits,
    showCuration,
    index,
    showEditMenu,
    setShowConfirmationModal,
    removeCurationDetails,
  } = props;
  const ref = useRef(null);
  function handleHideCategory() {
    showCuration(false);
    setShowConfirmationModal(true);
    removeCurationDetails({ index, outfits });
  }
  function handleEdit() {
    showEditMenu(true);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        showCuration(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [outfits]);
  return (
    <Option className="categoryEditCard" ref={ref}>
      <p onClick={handleEdit}>Edit</p>
      <p onClick={handleHideCategory}>Remove</p>
    </Option>
  );
}
