import {
  Section,
  SubSection,
  Container,
  ImgContainer,
  SubContainer,
  BackContainer,
} from "../../containers/signin";
import {
  H2,
  InputField,
  InputFlex,
  SubmitButton,
  RadioGroup,
  Title,
} from "../../components/signin";
import { validateEmail } from "../../components/helpers/validate";

import WaitlistImage from "../../assets/images/joinWaitlist.png";
import { useState } from "react";
import { useStyles } from "./styles";

const JoinWaitList = ({ handleFormSubmit }) => {
  const classes = useStyles();
  // const [isDisable, setIsDisable] = useState(false);
  const [formDetails, setFormDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    age: "TEENAGE",
    gender: "FEMALE",
    igHandle: "",
  });
  const [inputErrors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    age: "",
    gender: "",
  });

  const handleInputChange = (event) => {
    let {
      target: { name, value },
    } = event;
    inputErrors[name] = "";
    setErrors(inputErrors);
    setFormDetails((prevState) => (value === null ? prevState : { ...prevState, [name]: value }));
  };

  const handleFormData = () => {
    let isError = false;
    for (const property in formDetails) {
      if (!String(formDetails[property]).trim()) {
        if (property !== "igHandle") {
          isError = true;
          inputErrors[property] = true;
        }
      }

      if (property === "email") {
        const validEmail = !!validateEmail(formDetails[property]);
        if (!validEmail) {
          isError = true;
          inputErrors[property] = true;
        }
      }
    }
    setErrors(structuredClone(inputErrors));
    if (!isError) {
      handleFormSubmit(formDetails);
    }
  };

  return (
    <>
      <Section>
        <SubSection>
          <ImgContainer img={WaitlistImage} />
          <Container style={{ zoom: "85%" }} img={WaitlistImage}>
            <BackContainer>
              <SubContainer style={{ zoom: "95%" }}>
                <Title className="signUpTitle">MIKLOSET</Title>
                <H2 className="signUpH2" style={{ margin: "0px 0px 30px" }}>
                  JOIN OUR WAITLIST
                </H2>

                <InputFlex>
                  <InputField
                    type="text"
                    labelName={"First Name"}
                    name="firstName"
                    value={formDetails.firstName || ""}
                    onChange={handleInputChange}
                    errorText={inputErrors.firstName ? "Please enter First Name" : ""}
                  />
                  <InputField
                    type="text"
                    labelName={"Last Name"}
                    name="lastName"
                    value={formDetails.lastName || ""}
                    onChange={handleInputChange}
                    errorText={inputErrors.lastName ? "Please enter Last Name" : ""}
                  />
                </InputFlex>

                <InputFlex>
                  <InputField
                    type="email"
                    labelName={"Email"}
                    name="email"
                    value={formDetails.email || ""}
                    onChange={handleInputChange}
                    errorText={inputErrors.email ? "Please enter Email" : ""}
                  />
                </InputFlex>
                <InputField
                  labelName={"Instagram Handle: (Optional)"}
                  name="igHandle"
                  value={formDetails.igHandle || ""}
                  onChange={handleInputChange}
                />
                <div style={{ width: "100%", marginBottom: "10px" }}>
                  <p>Select Your Age Range:</p>
                  <select
                    name="age"
                    id="age"
                    className={classes.ageDropDown}
                    onChange={handleInputChange}
                  >
                    <option value="TEENAGE">Less than 18</option>
                    <option value="YOUNG_ADULT">18-24</option>
                    <option value="MIDDLE_AGE">25-34</option>
                    <option value="MIDDLE_AGE_2">35-44</option>
                    <option value="MIDDLE_AGE_3">45-54</option>
                    <option value="SENIOR">55 and over</option>
                    <option value="none">I prefer not to say</option>
                  </select>
                </div>

                <RadioGroup
                  id={"gender"}
                  labelName="Gender"
                  onChange={handleInputChange}
                  value={formDetails.gender || ""}
                  options={[
                    { name: "Female", value: "FEMALE" },
                    { name: "Male", value: "MALE" },
                    { name: "Gender Neutral", value: "NEUTRAL" },
                  ]}
                />
                <SubmitButton
                  onClick={() => {
                    // setIsDisable(!isDisable);
                    handleFormData();
                  }}
                  // disabled={isDisable}
                  buttontype="primary"
                  style={{
                    backgroundColor: "rgb(251, 91, 1)",
                    border: "0px",
                    // opacity: isDisable ? "50%" : "100%",
                    cursor: "pointer",
                  }}
                >
                  Join Our Waitlist
                </SubmitButton>
              </SubContainer>
            </BackContainer>
          </Container>
        </SubSection>
      </Section>
    </>
  );
};

export default JoinWaitList;
