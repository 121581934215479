import { useRef, useState } from "react";
import { useStyles } from "./styles";
import IconComponent from "../../../../components/fields/icon";
import ModalPortal from "../../../../containers/common/modalPortal";
import ImageUplaodPopup from "./imageUploadPopup.jsx";

const ImageUpload = ({ handleImageSelected, className, selectedItems = [], activeIndex }) => {
  const inputRef = useRef(null);
  const classes = useStyles();
  const [uploadModal, setUploadModal] = useState(false);
  const [newImageUrl, setnewImageUrl] = useState("")

  function handleSelectImage() {
    setUploadModal(!uploadModal);
  }
  function handleCloseModal(value) {
    setUploadModal(value);
  }

  function handleFileUpload(files) {
    // const { files } = e.target;
    if (files?.length) {
      if (selectedItems.length) {
        handleImageSelected(files[0], activeIndex);
      } else {
        handleImageSelected({
          fileImage: files[0],
        });
      }
    }
  }

  function handleSrcUpload(src) {
    if(src) {
      if (selectedItems.length) {
        handleImageSelected(src, activeIndex, true);
      } else {
        handleImageSelected({
          fileImage: src,
        });
      }
    }
  }

  // console.log("inside imageUpload.jsx", {selectedItems, activeIndex})
  let props = {handleCloseModal, selectedItems, activeIndex, handleFileUpload, handleSrcUpload}
  return (
    <div className={`${className} ${classes.ImageUploadIcon}`}>
      <div>
        <IconComponent
          iconTitle="LargeAddSquare"
          className={classes.UploadImageIcon}
          onClick={handleSelectImage}
        />
      </div>
      {uploadModal && (
        <ModalPortal>
          <ImageUplaodPopup {...props} />
        </ModalPortal>
      )}
    </div>
  );
};
export default ImageUpload;
