import { Avatar } from "@mui/material";
import img from "../../assets/images/dashboard/women/loungewear_women.png";
import { useStyles } from "./style";
import { Button } from "../../components/dashboard";
import CurationGroup from "./CurationGroup";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserProfileData } from "../../apollo/operations/user";
import { getCurationsList } from "../../apollo/operations/curation";
import { getItems } from "../../apollo/operations/items";
import { useLazyQuery } from "@apollo/client";
import { openToast } from "../../components/toast";
import { mobileMaxWidth, mediaQueries } from "../../components/helpers/ResponsiveStyleHelper";

export default function Profile() {
  const images = Array(5).fill({ img, name: "Mona" });
  const [getProfile, { data: userProfile }] = useLazyQuery(getUserProfileData);
  const [getCurations, { data: listOfCuration }] = useLazyQuery(getCurationsList);
  const [getSaleItems, { data: saleItemList }] = useLazyQuery(getItems);
  const navigate = useNavigate();
  const classes = useStyles();
  const [saleItems, setSaleItems] = useState([]);
  const [user, setUser] = useState({});
  const [curationList, setCurationList] = useState({});

  useEffect(() => {
    getProfile();
    getCurations();
    getSaleItems({ variables: { category: [] } });
  }, []);

  useEffect(() => {
    if (saleItemList?.getItemsFromCloset?.length) {
      setSaleItems(saleItemList.getItemsFromCloset);
    }
  }, [saleItemList]);

  useEffect(() => {
    if (userProfile && userProfile.getUserDetails) {
      if (
        userProfile.getUserDetails.statusCode !== "200" ||
        userProfile.getUserDetails.message !== "Fetched Successfully"
      ) {
        openToast("error", "Failed", "Try again later");
        return;
      }
      setUser(JSON.parse(JSON.stringify(userProfile.getUserDetails.data)));
    }
  }, [userProfile]);

  useEffect(() => {
    if (listOfCuration?.getCurationsList?.data && listOfCuration?.getCurationsList?.data?.length) {
      let curationData = {};
      let list = listOfCuration.getCurationsList.data.filter((item) => item.isAdded);
      if (list.length) {
        for (let item of list) {
          if (curationData[item?.curationLook]) {
            curationData[item?.curationLook]?.push(item);
          } else {
            curationData[item?.curationLook] = [item];
          }
        }
      }
      setCurationList(curationData);
    }
  }, [listOfCuration]);

  const ImageContainer = styled.div`
    height: 157px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;

    @media (${mediaQueries.mobile}) {
      height: 125px;
    }
  `;

  const SaleItem = styled.div`
    width: 135px;

    & > p {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.36px;
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    @media (${mediaQueries.mobile}) {
      width: 111px;

      & > p {
        font-size: 10px;
        letter-spacing: 0.3px;
      }
    }
  `;

  return (
    <>
      <div className={classes.root}>
        <div className={classes.profile}>
          <Avatar
            alt={user?.firstName}
            src={user?.profilePicUrl}
            sx={{
              width: window.innerWidth <= mobileMaxWidth ? 92 : 164,
              height: window.innerWidth <= mobileMaxWidth ? 92 : 164,
            }}
          />
          <div className={classes.profileDetails}>
            <h1>
              {user?.firstName} {user?.lastName}
            </h1>
            <p className={classes.email}>{user?.email}</p>
            <Button
              buttontype="primary"
              className={classes.editBtn}
              onClick={() => navigate("/settings/account")}
            >
              Edit Profile
            </Button>
          </div>
        </div>
        <div className={classes.followersDetails}>
          <div className={classes.subFollow}>
            <h1>05</h1>Following
          </div>
          <div className={classes.subFollow}>
            <h1>37</h1>Followers
          </div>
        </div>
        <div className={classes.about}>{user?.bio}</div>
        <p className={classes.following}>Following</p>
        <div className={classes.followingImages}>
          {images.map((item, i) => (
            <div key={i}>
              <Avatar
                alt="Remy Sharp"
                src={item?.img}
                sx={{
                  width: window.innerWidth <= mobileMaxWidth ? 36 : 72,
                  height: window.innerWidth <= mobileMaxWidth ? 36 : 72,
                }}
              />
              <p className={classes.followingName}>{item?.name}</p>
            </div>
          ))}
        </div>
        {!!Object.keys(curationList).length && (
          <>
            <p className={classes.following}>Curated by Me</p>
            <div className={classes.curationGroupContainer}>
              {Object.keys(curationList).map((key, i) => (
                <div key={i}>
                  <CurationGroup curationGroupData={curationList[key]} curationName={key} />
                  <p className={classes.curationGroupName}>{key}</p>
                </div>
              ))}
            </div>
          </>
        )}
        {!!saleItems.length && (
          <>
            <p className={classes.following}>Items for Sale</p>
            <div className={classes.itemsConatiner}>
              {saleItems?.map((saleItem) => (
                <SaleItem key={saleItem.id}>
                  <ImageContainer>
                    <img className={classes.outfitImage} src={saleItem.src} />
                  </ImageContainer>
                  <p>{saleItem.itemName}</p>
                  {/* <p>$4.99</p> */}
                </SaleItem>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
}
