import { gql } from "@apollo/client";

export const reachOut = gql`
mutation ReachOut($reachOutReq: ReachoutRequest!) {
    reachOut(reachOutReq: $reachOutReq) {
      statusCode
      message
      error
      data
    }
  }
`;