import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications: [],
  unreadCount: 0,
  styleMe: {},
};

export const socketSlice = createSlice({
  name: "socket",
  initialState,
  reducers: {
    setStyleMe: (state, action) => {
      state.styleMe = action.payload;
    },
    clearStyleMe: (state, action) => {
      state.styleMe = {};
    },
    setNotifications: (state, action) => {
      state.notifications.unshift(action.payload);
    },
    clearNotification: (state) => {
      state.notifications = [];
    },
    setUnreadCount: (state, action) => {
      state.unreadCount = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setStyleMe, clearStyleMe, setNotifications, clearNotification, setUnreadCount } =
  socketSlice.actions;

export default socketSlice.reducer;
