import { useStyles } from "../styles";
import IconComponent from "../../../components/fields/icon";
import { AddIcon, LikeIcon, List, ListItems } from "../../../containers/StyleMe";
import AccessoriesTag from "./Accessories/Accessories";
import ClothesTag from "./Clothes";
import FootwearTag from "./Footwear";

const Clothes = {
  Activewear: true,
  Beachwear: true,
  Denim: true,
  Dresses: true,
  Loungewear: true,
  Outerwear: true,
  Miscellaneous: true,
  Home: true,
  Jumpsuit: true,

  Shirts: true,
  Tops: true,
  Pants: true,
  Shorts: true,
  Skirts: true,
};

const Accessories = { Accessories: true, Beauty: true };
const Footwear = { Shoes: true };

export default function Curations(props) {
  const {
    linearCategories,
    showAddCurationsPopup,
    outfits,
    addOutfits,
    removeCurationItem,
    index,
    id,
    isAdded,
    isLiked,
    addToIsAdded,
    addCurationToFavorite,
    isSelected,
    handleSelected,
    removeFromFavorite,
    removeAddCurationItem,
    key,
  } = props;
  const classes = useStyles();

  let outFits2 = outfits;
  for (let element of outFits2) {
    if (!element?.mainCategory) {
      element.mainCategory = linearCategories[element.category];
    }
  }
  const clothesList = outFits2.filter((item) => Clothes[item.mainCategory]);
  const accessoriesList = outFits2.filter((item) => Accessories[item.mainCategory]);
  const footwearList = outFits2.filter((item) => Footwear[item.mainCategory]);

  function getIds(outfits) {
    return outfits?.reduce((update, cur) => {
      update.push(cur.id);
      return update;
    }, []);
  }
  function handlePopup(value) {
    if (isLiked && !isAdded) {
      addToIsAdded(id, index);
    } else if (!isAdded) {
      showAddCurationsPopup(true, index);
      addOutfits({ outfits, isLiked: value, isAdded, index });
    } else {
      const itemIds = getIds(outfits);
      addCurationToFavorite({ curation: itemIds, isLiked: true, index });
    }
  }

  function onDropItem(e) {
    props.onDropCurationContainer(e);
  }

  function onDragOverCompleted(e) {
    e.preventDefault();
  }
  function removeAddItemFromCuration() {
    const itemIds = getIds(outfits);
    removeAddCurationItem(itemIds, index);
  }
  function removeCurationFromFavorite() {
    const itemIds = getIds(outfits);
    removeFromFavorite(itemIds, index);
  }
  return (
    <div
      className={`${classes.CurationsContainer} ${isSelected && "selected-item"}`}
      id={props.index}
      onDragOver={(event) => {
        event.preventDefault();
        onDragOverCompleted(event);
      }}
      onDrop={(event) => {
        event.preventDefault();
        onDropItem(index);
      }}
      onClick={(event) => {
        event.stopPropagation();
        handleSelected(index);
      }}
      key={key}
    >
      <AddIcon
        onClick={(event) => {
          event.stopPropagation();
          isAdded ? removeAddItemFromCuration() : handlePopup(false);
        }}
      >
        <IconComponent
          className={classes.vector}
          iconTitle={isAdded ? "AddVector" : "UnSelectedVector"}
        />
      </AddIcon>
      <LikeIcon
        onClick={(event) => {
          event.stopPropagation();
          isLiked ? removeCurationFromFavorite() : handlePopup(true);
        }}
      >
        <IconComponent
          className={classes.vector}
          iconTitle={isLiked ? "LikedVector" : "UnLikedVector"}
        />
      </LikeIcon>

      <ListItems>
        <ClothesTag
          clothesList={clothesList}
          removeCurationItem={removeCurationItem}
          parentIndex={index}
          classes={classes}
        />
        <AccessoriesTag
          linearCategories={linearCategories}
          accessoriesList={accessoriesList}
          removeCurationItem={removeCurationItem}
          parentIndex={index}
          classes={classes}
        />
        <FootwearTag
          footwearList={footwearList}
          removeCurationItem={removeCurationItem}
          parentIndex={index}
          classes={classes}
        />
      </ListItems>
      {/* <ListItems style={{ gridTemplateColumns: `repeat(${outfits.lenght }, auto)` }}>
        {outfits.map((item, itemIndex) => (
          <List key={`index-${itemIndex}`}>
            <IconComponent
              iconTitle="CrossIcon"
              className={classes.trash}
              onClick={(event) => {
                event.stopPropagation();
                removeCurationItem(itemIndex, index);
              }}
            />
            <img className={classes.outfitImage} src={item.src} key={`src-${itemIndex}`} />
          </List>
        ))}
      </ListItems> */}
    </div>
  );
}
