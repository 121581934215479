const sortList = [
  { id: 1, name: "A to Z" },
  { id: 2, name: "Z to A" },
  { id: 3, name: "Recently Added" },
  { id: 4, name: "Previously Added" },
];

function actionDropDownSort(item, filterKey) {
  if (item.id === 1) {
    return (preArr) =>
      preArr.slice().sort((a, b) => sortingSection(a[filterKey], b[filterKey], true));
  } else if (item.id === 2) {
    return (preArr) =>
      preArr.slice().sort((a, b) => sortingSection(a[filterKey], b[filterKey], false));
  } else if (item.id === 3) {
    return (preArr) =>
      preArr
        .slice()
        .sort((a, b) =>
          sortingSection(new Date(a.createdAt).getTime(), new Date(b.createdAt).getTime(), false),
        );
  } else if (item.id === 4) {
    return (preArr) =>
      preArr
        .slice()
        .sort((a, b) =>
          sortingSection(new Date(a.createdAt).getTime(), new Date(b.createdAt).getTime(), true),
        );
  }
}

function sortingSection(preVal, curVal, isAsc) {
  if (preVal > curVal) return isAsc ? 1 : -1;
  else if (preVal < curVal) return isAsc ? -1 : 1;
  else return 0;
}

export { sortList, actionDropDownSort, sortingSection };
