import { useState } from "react";
import CategoriesDropDown from "../../../components/categoriesDropDown";
import { useStyles } from "./styles";
import IconComponent from "../../../components/fields/icon";
import { Tooltip } from "react-tooltip";
import { validatePrice, validateTags } from "../../../components/helpers/validate";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
export default function DetailSection(props) {
  const classes = useStyles();
  const { itemDetails, setItemDetails, categoriesList } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  function handleMenuClick(e) {
    setItemDetails({ ...itemDetails, category: e });
    handleClose();
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleClick(event) {
    return setAnchorEl(event.currentTarget);
  }

  function handleValueChange(value, type) {
    if (type === "size") {
      if (value.length > 7) {
        return;
      }
      value = value.toUpperCase();
    } else if (type === "tags") {
      if (
        (value.length === 1 && value === " ") ||
        (value.charAt(value.length - 2) === " " && value.charAt(value.length - 1) === " ")
      ) {
        return;
      } else if (value.length === 1 && value !== "#") {
        value = "#" + value;
      } else if (value.charAt(value.length - 2) === " " && value.charAt(value.length - 1) !== "#") {
        value = value.substring(0, value.length - 1) + "#" + value.charAt(value.length - 1);
      }

      // to handle when user paste the tags
      if (value.match(/^[^#]|\s\s+|\s+[^#]/gm)) {
        value = validateTags(value.trim());
      }
    } else if (type === "price") {
      if (value.length === 1 && value === ".") {
        value = "$0" + value;
      } else if (value.length === 1 && value.match(/\d/g)) {
        value = "$" + value;
      } else if (value !== "" && !validatePrice(value)) {
        return;
      }
    }
    setItemDetails({ ...itemDetails, [type]: value });
  }

  return (
    <div className={classes.detailsParent}>
      <div className={classes.additionalInfoText}>
        {"Add Additional Information for a better curated experience"}
      </div>
      <div className={classes.detailsBody}>
        <div className={classes.inputContainer}>
          <div className={classes.inputLabel}>
            {"Category: "}
            <span>{"*"}</span>
          </div>
          <div className={classes.selectInputWrapper}>
            <CategoriesDropDown
              selectedCategory={itemDetails.category}
              handleClose={handleClose}
              handleOpen={handleClick}
              categoriesList={categoriesList}
              open={open}
              handleMenuClick={handleMenuClick}
            />
          </div>
        </div>
      </div>
      <div className={classes.detailsBody}>
        <div className={classes.inputContainer}>
          <div className={classes.inputLabel}>{"Brand Name:"}</div>
          <div className={classes.inputWrapper}>
            <input
              className={classes.textInput}
              type="text"
              value={itemDetails.brand}
              onChange={(e) => handleValueChange(e.target.value, "brand")}
            />
          </div>
        </div>
        <div className={classes.inputContainer}>
          <div className={classes.inputLabel}>{"Store Name:"}</div>
          <div className={classes.inputWrapper}>
            <input
              className={classes.textInput}
              type="text"
              value={itemDetails.storeName}
              onChange={(e) => handleValueChange(e.target.value, "storeName")}
            />
          </div>
        </div>
        <div className={classes.inputContainer}>
          <div className={classes.inputLabel}>{"Item Name:"}</div>
          <div className={classes.inputWrapper}>
            <input
              className={classes.textInput}
              type="text"
              value={itemDetails.itemName}
              onChange={(e) => handleValueChange(e.target.value, "itemName")}
            />
          </div>
        </div>
        <div className={classes.inputContainer}>
          <div className={`${classes.inputLabel} ${classes.infoContainer}`}>
            <div>{"Size:"}</div>
            <a
              data-tooltip-id="info-tooltip"
              data-tooltip-content='If size is not available please enter "NA"'
            >
              <IconComponent iconTitle={"InfoIcon"} />
            </a>
            <Tooltip
              id="info-tooltip"
              style={{
                backgroundColor: "#D1D1D1",
                color: "#000000",
                opacity: "1",
                fontFamily: "var(--primaryRegularFont)",
              }}
            />
          </div>
          <div className={classes.inputWrapper}>
            <input
              className={classes.textInput}
              type="text"
              value={itemDetails.size}
              onChange={(e) => handleValueChange(e.target.value, "size")}
            />
          </div>
        </div>
        <div className={classes.inputContainer}>
          <div className={classes.inputLabel}>{"Color:"}</div>
          <div className={classes.inputWrapper}>
            <input
              className={classes.textInput}
              type="text"
              value={itemDetails.color}
              onChange={(e) => handleValueChange(e.target.value, "color")}
            />
          </div>
        </div>
        <div className={classes.inputContainer}>
          <div className={classes.inputLabel}>{"Price:"}</div>
          <div className={classes.inputWrapper}>
            <input
              className={classes.textInput}
              type="text"
              placeholder="$ 99.99"
              value={itemDetails.price}
              onChange={(e) => handleValueChange(e.target.value, "price")}
            />
          </div>
        </div>
        <div className={classes.inputContainer}>
          <div className={classes.inputLabel}>{"Date Purchased:"}</div>
          <div className={classes.inputWrapper}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                className={classes.datePicker}
                inputFormat="MM/DD/YYYY"
                disableFuture
                defaultValue={null}
                value={itemDetails.datePurchased ?? null}
                onChange={(value) => handleValueChange(value, "datePurchased")}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className={classes.inputContainer}>
          <div className={classes.inputLabel}>{"Tags:"}</div>
          <div className={classes.inputWrapper}>
            <input
              className={classes.textInput}
              type="text"
              placeholder="#tag_name1 #tag_name2"
              value={itemDetails.tags}
              onChange={(e) => handleValueChange(e.target.value, "tags")}
            />
          </div>
        </div>
        <div className={classes.inputContainer}>
          <div className={classes.inputLabel}>{"Link to Item:"}</div>
          <div className={classes.inputWrapper}>
            <input
              className={classes.textInput}
              type="text"
              value={itemDetails.productLink || ""}
              onChange={(e) => handleValueChange(e.target.value, "productLink")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
