import ContactUsFragment from "./contactUsFragment";
import { useStyles } from "./styles.js";

export default function ContactUs({ path }) {
  console.log("in main contact us", path);
  const classes = useStyles();
  if (path === "/dashboard/contactus") {
    return (
      <>
        <ContactUsFragment />
      </>
    );
  } else if (path === "/contactus") {
    return (
      <>
        <div className={classes.miklosetTitle}>MIKLOSET</div>
        <ContactUsFragment />
      </>
    );
  }
}
