import { gql } from "@apollo/client";

export const getNotificationTypes = gql`
  query GetNotificationTypes {
    getNotificationTypes {
      statusCode
      message
      error
      data
    }
  }
`;
export const addOrUpdateNotificationType = gql`
  mutation AddOrUpdateNotificationType($notificationTypeData: NotificationTypeData) {
    addOrUpdateNotificationType(notificationTypeData: $notificationTypeData) {
      data
      error
      message
      statusCode
    }
  }
`;
