import { useEffect, useState } from "react";
import styled from "styled-components";

import { Heading, Text } from "../../components/forgotpassword";
import { Hyper, InputField, Para, SubmitButton, Title } from "../../components/signin";
import {
  BackContainer,
  Container,
  ImgContainer,
  Section,
  SubContainer,
  SubSection,
} from "../../containers/signin";
import background from "../../assets/images/forgotPassword.png";
import { validatePassword } from "../../components/helpers/validate";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { resetPassword } from "../../apollo/operations/user";
import Loader from "../../components/loaderScreen";
import { openToast } from "../../components/toast";
import ModalPortal from "../../containers/common/modalPortal";
import Modal from "../../containers/common/modalPortal/modal";

const P = styled(Text)`
  width: 66%;
  min-width: 280px;
`;

const ResetPassword = () => {
  const [passwordDetails, setPasswordDetails] = useState({
    password: "",
    checkPassword: "",
    show: false,
  });
  const [success, setSuccess] = useState(false);
  const [inputError, setInputError] = useState({
    password: false,
    checkPassword: false,
  });
  const [errorPopup, setErrorPopup] = useState(false);
  const [callResetPassword, { data: resetResponse, loading }] = useMutation(resetPassword);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (resetResponse?.resetPassword) {
      if (+resetResponse.resetPassword.statusCode === 200) {
        openToast("success", "Password Changed Successfully");
        setSuccess(true);
      } else if (+resetResponse.resetPassword.statusCode === 419) {
        setErrorPopup(true);
      } else openToast("error", resetResponse.resetPassword.message);
    }
  }, [resetResponse]);

  useEffect(() => {
    if (inputError.password || inputError.checkPassword) {
      setInputError({
        password: !passwordDetails.password || !validatePassword(passwordDetails.password),
        checkPassword: passwordDetails.checkPassword !== passwordDetails.password,
      });
    }
  }, [passwordDetails]);

  const handleInputChange = (event) => {
    const {
      target: { name, value },
    } = event;
    if (name === "show") {
      setPasswordDetails((prevState) => ({ ...prevState, show: value }));
      return;
    }
    setPasswordDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (success) {
      navigate("/betatesters/signin");
      // navigate("/signin");
      return;
    }
    let errors = {
      password: !!(!passwordDetails.password || !validatePassword(passwordDetails.password)),
      checkPassword: passwordDetails.password !== passwordDetails.checkPassword,
    };

    if (Object.values(errors).filter((value) => value).length) {
      setInputError({ ...errors });
    } else {
      const pathArray = pathname.split("/");
      const token = pathArray[pathArray.length - 1];

      callResetPassword({
        variables: {
          resetDetails: {
            newPassword: passwordDetails.password,
            token,
          },
        },
      });
    }
  };

  function handleButtonClick(value) {
    setErrorPopup(false);
    if (value) navigate("/forgotPassword");
  }

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      handleSubmit();
    }
  };

  return (
    <>
      <Section>
        <SubSection>
          <ImgContainer img={background} />
          <Container img={background}>
            <BackContainer>
              <SubContainer>
                <Title>MIKLOSET</Title>
                <Heading>{success ? "PASSWORD RESET" : "SET NEW PASSWORD"}</Heading>
                <P>
                  {success
                    ? "Your password has been successfully reset. Click below to Log In magically."
                    : "Your new password must be different to previously used passwords"}
                </P>
                {!success && (
                  <>
                    <InputField
                      labelName={"Password"}
                      name="password"
                      placeholder=""
                      value={passwordDetails.password || ""}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyPress}
                      type="password"
                      errorText={
                        inputError.password
                          ? "Password must be minimum 8 characters, including at least 1 uppercase, 1 lowercase, 1 numeric and 1 special character"
                          : ""
                      }
                    />
                    <InputField
                      labelName={"Confirm Password"}
                      name="checkPassword"
                      placeholder=""
                      value={passwordDetails.checkPassword || ""}
                      onChange={handleInputChange}
                      type={passwordDetails.show ? "text" : "password"}
                      icon={passwordDetails.show ? "EyeBoldCrossIcon" : "EyeBoldIcon"}
                      onKeyDown={handleKeyPress}
                      errorText={
                        inputError.checkPassword
                          ? "Password and Confirm Password must be same!"
                          : ""
                      }
                      onIconClick={() =>
                        handleInputChange({
                          target: { name: "show", value: !passwordDetails.show },
                        })
                      }
                    />
                  </>
                )}
                <SubmitButton buttontype="primary" onClick={handleSubmit}>
                  {success ? "Continue" : "Reset Password"}
                </SubmitButton>
                <Para>
                  {/* Back to <Hyper to={"/signin"}>Log In</Hyper> */}
                  Back to <Hyper to={"/betatesters/signin"}>Log In</Hyper>
                </Para>
              </SubContainer>
            </BackContainer>
          </Container>
        </SubSection>
      </Section>
      {loading && <Loader />}
      {errorPopup && (
        <ModalPortal>
          <Modal type={"tokenExpired"} handleButtonClick={handleButtonClick} svgType="circle" />
        </ModalPortal>
      )}
    </>
  );
};

export default ResetPassword;
