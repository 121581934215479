import { useState } from "react";
import { useStyles } from "./styles";
import IconComponent from "../fields/icon";
import { Header, DropDownContainer } from "./DropDownComponents";

export default function DropDown({
  lookName,
  setLookName,
  defaultName,
  dropDownList,
  setDropDownList,
  handleSelection,
}) {
  const classes = useStyles();
  const [toggle, setToggle] = useState(false);

  function arrayIterator(data, subItemToggle) {
    return dropDownList.map((item) => {
      if (!subItemToggle) item.isActive = false;

      if (item.id === data.id) {
        item.isActive = subItemToggle ? !item.isActive : true;
      }

      if (!subItemToggle && "subNavOption" in item && item.subNavOption.length) {
        item.subNavOption = item.subNavOption.map((subItem) => {
          subItem.isActive = false;
          if (subItem.id === data.id) {
            subItem.isActive = item.isActive = true;
          }
          return subItem;
        });
      }

      return item;
    });
  }

  function handleClick(item, subItemToggle) {
    setDropDownList(arrayIterator(item, subItemToggle));
    if (!subItemToggle) {
      setLookName(item.name);
      handleSelection(item);
      setToggle(false);
    }
  }

  function SubItem({ data }) {
    return (
      <div
        className={`${classes.dropDownItem} ${data.isActive && classes.dropDownItemActive}`}
        onClick={() => handleClick(data, "subNavOption" in data)}
      >
        {data.name}
        {"subNavOption" in data && (
          <IconComponent iconTitle={data.isActive ? "UpArrow" : "DownArrow"} />
        )}
      </div>
    );
  }

  return (
    <div className={classes.dropDownContainer}>
      <Header
        onClick={() => {
          setToggle(!toggle);
        }}
      >
        {lookName && lookName.toLowerCase() !== "view all" ? lookName : defaultName}
        <IconComponent width="14px" height="14px" iconTitle={toggle ? "UpArrow" : "DownArrow"} />
      </Header>
      <DropDownContainer className={toggle && classes.curationDropDown}>
        {dropDownList?.map((item, index) => (
          <div key={index}>
            <SubItem key={item.id} data={item} />
            {"subNavOption" in item &&
              item.isActive &&
              item.subNavOption?.map((subItem) => <SubItem key={subItem.id} data={subItem} />)}
          </div>
        ))}
      </DropDownContainer>
    </div>
  );
}
