import { mobileMaxWidth } from "../../components/helpers/ResponsiveStyleHelper";
import { useStyles } from "./styles";

export default function ItemImage({ imageUrl, totalCount, index, indexToShow }) {
  const classes = useStyles();

  return (
    <div className={classes.productImageContainer}>
      {index === indexToShow && totalCount !== index + 1 && (
        <span className={classes.countClass}>
          +{totalCount - (window.innerWidth <= mobileMaxWidth ? 0 : 2)}
        </span>
      )}
      <img className={classes.productImage} src={imageUrl} alt="profile" />
    </div>
  );
}
