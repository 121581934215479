import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
    container: {
        position: "fixed",
        top: "0",
        left: "0",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: "2",
    },
     
    loader: {
        position: "fixed",
        top: "calc( 50% - 100px )",
    }
})