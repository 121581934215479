import { makeStyles } from "@material-ui/styles";

import {
  tabMediaStyle,
  mobileMediaStyle,
} from "../../../../components/helpers/ResponsiveStyleHelper";

export const useStyles = makeStyles({
  iconAndUrlDiv: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignContent: "center",
    gap: "25px",
    marginBottom:"35px"
  },
  urlInput: {
    border: "none",
    outline: "none",
    width: "100%",
    height: "100%",
    fontSize: "20px",
    lineHeight: "20px",
    padding: "0 5px 0 18px",
    borderRadius: "10px",
    textOverflow: "ellipsis",
  },
  ORptag: {
    fontFamily: "var(--primaryHeaderFont)",
    fontSize: "24px"
  },
  imageNameDiv: {
    width: "70%"
  },
  alignSelf: {
    alignSelf: "center"
  },
  uploadedImageStyle: {
    height: "150px",
  },
  imageDiv: {
    alignItems: "Center",
    display: "flex"
  },
  inputDiv: {
    height: "52px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
  },
  inputDivWrapper: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #000000",
    borderRadius: "10px",
  },


  UploadImageIcon: {
    position: "absolute",
    top: "10px",
    left: "10px",
    height: "35px",
    width: "28px",
    borderRadius: "0px 0 0 0",
    "&:hover": {
      backgroundColor: "#8a8a8a40",
    },

    zIndex: 1,
  },
  ImageUploadIcon: {
    position: "absolute",
    top: "0",
    left: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  mainContainer: {
    padding: "30px 60px",
  },



  header: {
    height: "56px",
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "var(--primaryRegularFont)",
  },

  title: {
    fontSize: "32px",
    lineHeight: "40px",
    fontWeight: "500",
    fontFamily: "var(--primaryHeaderFont)",
  },

  button: {
    width: "140px",
    height: "100%",
    fontSize: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    color: "#ffffff",
  },

  activeButton: {
    backgroundColor: "#000000",
  },

  disableButton: {
    backgroundColor: "#CACACA",
  },

  itemContainer: {
    display: "flex",
  },

  dItemContainer: {
    paddingTop: "20px",
    boxSizing: "border-box",
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    columnGap: "2%",
  },

  itemBody: {
    width: "100%",
    display: "flex",
    marginBottom: "40px",
    padding: "10px",
    borderRadius: "5px",
    boxSizing: "border-box",
    boxShadow: "0 0 4px #4b4b4b",
  },

  checkboxContainer: {
    width: "36px",
    height: "36px",
    display: "flex",
    justifyContent: "start",
    marginTop: "20px",
  },

  details: {
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  imgWrapper: {
    position: "relative",
    width: "100%",
    height: "280px",
    backgroundColor: "#ffffff",
    marginBottom: "20px",
    "&:hover": {
      "& > i": {
        display: "block",
      },
    },
  },

  imgBody: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },

  inputLabel: {
    fontFamily: "var(--primaryRegularFont)",
    fontSize: "14px",
    marginBottom: "6px",
    "& > span": {
      color: "#ff0000",
      fontWeight: "bold",
    },
  },

  inputWrapper: {
    height: "54px",
    width: "100%",
    marginBottom: "16px",
    boxSizing: "border-box",
    borderRadius: "10px",
  },

  validInputWrapper: {
    // border: "1px solid #000000",
    boxShadow: "0 0 3px #4b4b4b",
  },

  errorInputWrapper: {
    // border: "1px solid #ff0000",
    boxShadow: "0 0 3px #ff0000",
  },

  selectInputWrapper: {
    height: "54px",
    width: "100%",
    marginBottom: "16px",
    boxSizing: "border-box",
    borderRadius: "10px",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },

    "& .MuiInputBase-formControl": {
      fontSize: "20px",
    },

    "& .MuiOutlinedInput-input": {
      padding: "13.5px 10px 13.5px 20px !important",
    },
  },

  textInput: {
    border: "none",
    outline: "none",
    width: "100%",
    height: "100%",
    fontSize: "18px",
    fontFamily: "var(--primaryRegularFont)",
    boxSizing: "border-box",
    padding: "0 10px 0 18px",
    borderRadius: "inherit",
    textOverflow: "ellipsis",
  },

  subHeader: {
    fontFamily: "var(--primaryRegularFont)",
    fontSize: "30px",
    fontWeight: "500",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "300px",
  },

  infoWrapper: {
    display: "flex",
    justifyContent: "space-between",

    "& > a > i > svg": {
      height: "20px",
      width: "20px",
    },
  },

  loadingSubHeader: {
    fontFamily: "var(--primaryRegularFont)",
    fontSize: "30px",
    fontWeight: "500",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "300px",
  },

  parentContainer: {
    width: "100%",
  },

  imageGallery: {
    width: "100%",
    boxSizing: "border-box",
    display: "grid",
    gap: "20px",
    paddingTop: "20px",
    gridTemplateColumns: "repeat(auto-fill, minMax(220px, 1fr))",
  },

  parent: {
    height: "250px",
    boxShadow: "0 0 3px #b5b5b5",
  },

  galleryImgWrapper: {
    width: "100%",
    height: "100%",
    backgroundColor: "#ffffff",
    marginBottom: "20px",
    position: "relative",
    "&:hover": {
      "& > i": {
        display: "block",
      },
    },
  },

  selectedProduct: {
    outline: "1px Solid #FB5B01",
  },

  selectedVector: {
    position: "absolute",
    top: "0",
    right: "0",
    transform: "translate(50%, -50%)",
    backgroundColor: "white",
    outline: "2px solid white",
    borderRadius: "50%",
  },

  eclipseVector: {
    "& > svg > circle": {
      fillOpacity: "0.4",
    },
  },

  addCheckBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

    "& > svg": {
      height: "16px",
      width: "16px",
      "& > path": {
        fill: "white",
      },
    },
  },

  mDetails: {
    width: "100%",
    boxSizing: "border-box",
    display: "none",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "10px",
  },

  mImgContainer: {
    height: "350px",
    position: "relative",
    boxShadow: "0 0 4px #cacaca",
    borderRadius: "20px",
  },

  imageContainer1: {
    width: "calc(100% - 70px)",
  },

  imageContainer2: {
    width: "100%",
  },

  mImage: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    borderRadius: "20px",
  },

  checkBoxArea: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    marginBottom: "15px",
    color: "#CACACA",
    fontFamily: "var(--primaryRegularFont)",
    fontSize: "16px",
    lineHeight: "16px",
    cursor: "pointer",

    "& > i > svg": {
      height: "20px",
      width: "20px",
    },
  },

  checkBoxMsg: {
    paddingLeft: "8px",
  },

  buttonControl: {
    display: "flex",
    fontFamily: "var(--primaryRegularFont)",
    fontSize: "12px",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },

  navigationButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "30px",
    width: "30px",
    borderRadius: "15px",
    margin: "1px",
    cursor: "pointer",
    "& >svg": {
      height: "18px",
      width: "18px",
      "& > path": {
        stroke: "#fff",
      },
    },
  },

  removeButton: {
    display: "flex",
    justifyContent: "end",
  },

  removeIcon: {
    cursor: "pointer",
    "& > svg": {
      height: "40px",
      width: "40px",
    },
  },

  crossIcon: {
    position: "absolute",
    top: "0px",
    right: "0px",
    height: "40px",
    width: "40px",
    borderRadius: "20px",
    backgroundColor: "#ffffff60",
    display: "none",
    zIndex: 1,
    "& > svg": {
      height: "40px",
      width: "40px",
    },
  },

  nextButton: {
    transform: "rotate(180deg)",
  },

  active: {
    backgroundColor: "#000",
  },

  deactive: {
    backgroundColor: "#cacaca",
  },

  datePicker: {
    width: "100%",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    "& .MuiInputBase-input": {
      padding: "14.5px 15px !important",
    },
  },

  ...tabMediaStyle({
    mainContainer: {
      padding: "24px 36px 24px",
    },
    parent: {
      height: "200px",
    },
    imageGallery: {
      gridTemplateColumns: "repeat(auto-fill, minMax(150px, 1fr))",
    },
    textInput: {
      fontSize: "16px",
    },
    selectInputWrapper: {
      height: "48px",
      marginBottom: "12px",

      "& .MuiInputBase-formControl": {
        fontSize: "16px !important",
        fontWeight: "400 !important",
      },

      "& .MuiOutlinedInput-input": {
        padding: "13.5px 12px !important",
      },
    },
    inputWrapper: {
      height: "48px",
      marginBottom: "12px",
    },
    dItemContainer: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    button: {
      width: "120px",
      fontSize: "16px",
    },
    header: {
      height: "48px",
    },
    title: {
      fontSize: "28px",
      lineHeight: "30px",
    },
  }),

  ...mobileMediaStyle({
    datePicker: {
      "& .MuiInputBase-input": {
        fontSize: "14px",
        padding: "12.8px 10px 12.8px 12px !important",
      },
    },
    dItemContainer: {
      display: "none",
    },
    mDetails: {
      display: "flex",
    },
    mainContainer: {
      padding: "12px 18px 12px",
    },
    checkBoxArea: {
      fontSize: "14px",
      "& > i > svg": {
        height: "16px",
        width: "16px",
      },
    },
    mImgContainer: {
      height: "290px",
    },
    parent: {
      height: "160px",
    },
    imageGallery: {
      gridTemplateColumns: "repeat(auto-fill, minMax(120px, 1fr))",
    },
    infoWrapper: {
      "& > a > i > svg": {
        height: "16px",
        width: "16px",
      },
    },
    textInput: {
      fontSize: "14px",
      fontWeight: "400",
      padding: "0 10px 0 12px",
    },
    inputLabel: {
      fontSize: "14px",
      marginBottom: "6px",
    },
    button: {
      width: "90px",
      fontSize: "12px",
    },
    header: {
      height: "36px",
    },
    title: {
      fontSize: "20px",
      lineHeight: "20px",
      display: "flex",
      "& > svg": {
        height: "20px",
        paddingRight: "5px",
      },
    },
    selectInputWrapper: {
      "& .MuiInputBase-formControl": {
        fontSize: "14px !important",
      },
    },
  }),
});
