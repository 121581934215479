import { useState } from "react";
import { useStyles } from "./styles";
import IconComponent from "../../components/fields/icon";

export default function FavoriteListType(props) {
  const { list, setType } = props;
  const classes = useStyles();
  const [isShow, setIsShow] = useState(false);

  function SubCategories(props) {
    const { menuList, isCuration, applyClass } = props;
    return (
      <>
        {menuList?.map((cat, index) => (
          <div
            style={{ paddingBottom: applyClass && index + 1 === menuList.length ? 0 : "" }}
            className={`${classes.subCategory} ${cat.isActive && classes.active} ${
              applyClass && classes.dropDownSection
            }`}
            key={index}
            onClick={() => {
              setType(cat, index, isCuration);
              setIsShow(isCuration);
            }}
          >
            {cat.name}
          </div>
        ))}
      </>
    );
  }

  return (
    <div className={classes.leftContainer}>
      <div className={classes.leftHeader}>CATEGORY</div>
      <hr />
      <div className={classes.categoryLeftMenu}>My Favorites</div>
      <div className={classes.subCategoryMenu}>
        <SubCategories menuList={list.itemType} isCuration={false} />
        <div
          className={`${classes.subCategory} ${
            list?.curationType?.some((item) => item.isActive) && classes.active
          }`}
          onClick={() => setIsShow(!isShow)}
        >
          My Curations
          <IconComponent iconTitle={isShow ? "UpArrow" : "DownArrow"} />
        </div>
        {isShow && (
          <SubCategories menuList={list.curationType} isCuration={true} applyClass={isShow} />
        )}
      </div>
    </div>
  );
}
