export function addGroupData(curationsList) {
  const categoryData = {};
  const uniqueCategory = [];
  let completeWear = [];
   const newCategoryData = {
    bottomWear: [],
  };

  function addCategoryGroup(key, arr) {
    return arr.map((item) => ({
      ...item,
      categoryGroup: key,
    }));
  }

  for (const item of structuredClone(curationsList)) {
    if (["beauty", "accessories"].includes(item?.mainCategory?.toLowerCase())) {
      if (!uniqueCategory.length) {
        uniqueCategory.push(item?.category?.toLowerCase());
      } else if (!uniqueCategory.includes(item?.category?.toLowerCase())) {
        uniqueCategory.push(item?.category?.toLowerCase());
      }
    } else {
      if (!uniqueCategory.length) {
        uniqueCategory.push(item?.mainCategory?.toLowerCase());
      } else if (!uniqueCategory.includes(item?.mainCategory?.toLowerCase())) {
        uniqueCategory.push(item?.mainCategory?.toLowerCase());
      }
    }
  }

  for (const item of structuredClone(curationsList)) {
    if (uniqueCategory.includes(item?.category?.toLowerCase())) {
      if (Object.prototype.hasOwnProperty.call(categoryData, item?.category?.toLowerCase())) {
        categoryData[item?.category?.toLowerCase()].push(item);
      } else {
        categoryData[item?.category?.toLowerCase()] = [item];
      }
    } else if (uniqueCategory.includes(item?.mainCategory?.toLowerCase())) {
      if (Object.prototype.hasOwnProperty.call(categoryData, item?.mainCategory?.toLowerCase())) {
        categoryData[item?.mainCategory?.toLowerCase()].push(item);
      } else {
        categoryData[item?.mainCategory?.toLowerCase()] = [item];
      }
    }
  }

  for (const key in categoryData) {
    if (Object.hasOwnProperty.call(categoryData, key)) {
      let element = categoryData[key];

      if (["skirts", "shorts", "pants", "denim"].includes(key?.toLowerCase())) {
        if (Object.hasOwnProperty.call(newCategoryData, key)) {
          let elementData = addCategoryGroup("bottomWear", element);
          newCategoryData.bottomWear.push(...elementData);
        } else {
          // newCategoryData.bottomWear = [];
          let elementData = addCategoryGroup("bottomWear", element);
          newCategoryData.bottomWear.push(...elementData);
        }
      } else if (["shirts", "tops"].includes(key?.toLowerCase())) {
        if (Object.hasOwnProperty.call(newCategoryData, key)) {
          let elementData = addCategoryGroup("topWear", element);
          newCategoryData.topWear.push(...elementData);
        } else {
          newCategoryData.topWear = [];
          let elementData = addCategoryGroup("topWear", element);
          newCategoryData.topWear.push(...elementData);
        }
      } else if (
        [
          "dresses",
          "loungewear",
          "beachwear",
          "activewear",
          "miscellaneous",
          "jumpsuit",
          "suits",
        ].includes(key?.toLowerCase())
      ) {
        if (Object.hasOwnProperty.call(newCategoryData, key)) {
          let elementData = addCategoryGroup("completeWear", element);
          newCategoryData.completeWear.push(...elementData);
        } else {
          newCategoryData.completeWear = [];
          let elementData = addCategoryGroup("completeWear", element);
          newCategoryData.completeWear.push(...elementData);
          completeWear.push(...elementData);
        }
      } else {
        if (Object.hasOwnProperty.call(newCategoryData, key)) {
          let elementData = addCategoryGroup(key, element);
          newCategoryData[key].push(...elementData);
        } else {
          newCategoryData[key] = [];
          let elementData = addCategoryGroup(key, element);
          newCategoryData[key].push(...elementData);
        }
      }
    }
  }

  newCategoryData.completeWear = completeWear;
  let mergeArray = [];
  for (const key in newCategoryData) {
    mergeArray = mergeArray.concat(newCategoryData[key]);
  }
  return mergeArray;
}
