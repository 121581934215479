import { useStyles } from "./styles";
import { ReactComponent as UploadImageIcon} from "../../../assets/svg/uploadImageIcon2.svg";
import { useRef } from "react";

export default function UploadYourImage(props) {
    const { image, handleSelectImage, onError } = props;
    const classes = useStyles();
    const inputRef = useRef(null);


    function onFileUploadClick() {
      inputRef.current.click();
    }

    return(
        <div className={classes.uploadSection} onClick={onFileUploadClick}>
          <input 
            type="file"
            ref={inputRef}
            style={{display: "none"}}
            onChange={handleSelectImage}
            accept="image/*"
          />
          { image === ""
            ? (
            <div className={classes.uploadImageContainer}>
              <div className={classes.icon}>
                <UploadImageIcon/>
              </div>
              <div className={classes.label}>
                Upload Your Image
              </div>
            </div>
            ) : (
              <div className={classes.previewImageWrapper}>
                <img className={classes.previewImage} src={image}   alt="Preview Image" onError={onError}/>
              </div>
            )
          }  
        </div>
    )
}