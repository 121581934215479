import { useState } from "react";
import {
  ButtonContainter,
  CircleSvg,
  MainText,
  ModalContainer,
  Overflow,
  SvgContainer,
} from "../../components/modal";
import Button from "../../components/fields/button";
import IconComponent from "../../components/fields/icon";
import { InputField } from "../../components/signin";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useStyles } from "./styles";
import { validateTags } from "../../components/helpers/validate";
import { MenuContainer } from "../../containers/StyleMe";

export default function AddCurations(props) {
  const { addCurationsDetails, outfits, showAddCurationsPopup, allCurationData, favoriteDataList } =
    props;
  const classes = useStyles();
  const [curationName, setCurationName] = useState("");
  const [curationLook, setCurationLook] = useState("");
  const [curationTags, setCurationTags] = useState("");
  const [inputCurationLookError, setInputCurationLookError] = useState(false);

  const checkDuplicateNames = () => {
    const isExist = outfits?.isLiked
      ? favoriteDataList?.getFavoritesByItemIdOrCurationId?.data?.find(
          (item) => item?.curationName === curationName,
        )
        ? favoriteDataList?.getFavoritesByItemIdOrCurationId?.data
        : ""
      : allCurationData?.find((item) => item?.curationName === curationName && item?.isDeleted === 0)
      ? allCurationData?.filter((item) => item?.isDeleted === 0)
      : "";
    return isExist;
  };

  function handleChoiceResult(value) {
    if (value) {
      if (curationLook === "") {
        setInputCurationLookError(true);
      } else {
        const itemIds = outfits?.outfits?.reduce((update, cur) => {
          update.push(cur.id);
          return update;
        }, []);
        const curationObj = {
          curationName,
          curationLook,
          curation: itemIds,
          isAdded: true,
          isLiked: false,
        };
        // for already existed name adding postfix

        let count = 0;
        if (checkDuplicateNames()) {
          for (let cur of checkDuplicateNames()) {
            if (cur.curationName.includes(curationObj.curationName.split("_")[0])) {
              let curationNumber = cur.curationName.split("_")[1];
              if (count <= Number(curationNumber)) count = Number(curationNumber) + 1;
              else if (cur.curationName === curationObj.curationName && count === 0) count = 1;
            }
          }

          curationObj.curationName =
            count > 0
              ? curationObj.curationName.split("_")[0] + "_" + count
              : curationObj.curationName.split("_")[0];
        }

        // if selected outfit is liked. User wanted to add it in favorate then we will set isDelete = true.
        // outfit will visible in Favorate but not in CurateMe
        if (outfits.isLiked) {
          curationObj.isAdded = false;
          curationObj.isLiked = true;
          curationObj.isDeleted = true;
        }
        if (curationTags !== "") {
          curationObj.curationTags = curationTags;
        }
        addCurationsDetails(curationObj);
      }
    } else showAddCurationsPopup(value);
  }

  function handleCurationNameChange(e) {
    setCurationName(e.target.value);
  }
  function handleCurationLookChange(e) {
    if (inputCurationLookError) {
      setInputCurationLookError(false);
    }
    setCurationLook(e.target.value);
  }

  function handleCurationTagsChange(e) {
    let value = e.target.value;

    if (
      (value.length === 1 && value === " ") ||
      (value.charAt(value.length - 2) === " " && value.charAt(value.length - 1) === " ")
    ) {
      return;
    } else if (value.length === 1 && value !== "#") {
      value = "#" + value;
    } else if (value.charAt(value.length - 2) === " " && value.charAt(value.length - 1) !== "#") {
      value = value.substring(0, value.length - 1) + "#" + value.charAt(value.length - 1);
    }

    // to handle when user paste the tags
    if (value.match(/^[^#]|\s\s+|\s+[^#]/gm)) {
      value = validateTags(value.trim());
    }

    setCurationTags(value);
  }

  const lookType = [
    "Date Night",
    "Wine Country",
    "Night Out",
    "Custom Look",
    "Night Out - Casual",
    "Get Away",
    "Travel Casual",
    "Business Casual",
    "Business Formal",
    "Cocktail Attire",
    "Black Tie",
    "Beach Day",
    "Brunch Time",
    "Outdoor Adventure",
    "Summer - Dressy",
    "Summer - Casual",
    "Winter Wonderland",
    "Special Event",
    "Everyday Look",
    "Boho Chic",
    "Autumn Cozy",
    "Autumn Chic",
    "Fit Life",
    "Sportswear",
  ];

  return (
    <Overflow>
      <ModalContainer>
        <SvgContainer className={classes.svgContainer}>
          <CircleSvg>
            <IconComponent iconTitle={"InfoCirclePlan"} />
          </CircleSvg>
        </SvgContainer>

        <MainText>{"Information"}</MainText>
        <MenuContainer>
          <p>
            {"Select Look: "}
            <span>{"*"}</span>
          </p>
          <FormControl fullWidth>
            <Select value={curationLook} onChange={handleCurationLookChange} displayEmpty>
              {lookType.map((look, index) => (
                <MenuItem key={`look-${index}`} value={look}>
                  {look}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {inputCurationLookError && (
            <div className="errorText">
              <IconComponent iconTitle={"ErrorBoldIcon"} />
              <p>Please select looks</p>
            </div>
          )}
        </MenuContainer>
        <InputField
          labelName={"Name"}
          name="name"
          value={curationName}
          onChange={handleCurationNameChange}
          type="text"
          placeholder="enter curation name"
          errorText={checkDuplicateNames() ? "Curation Name Already Exists!" : ""}
        />
        <InputField
          labelName={"Tags (optional)"}
          name="tags"
          value={curationTags}
          onChange={handleCurationTagsChange}
          type="text"
          placeholder=""
        />

        <ButtonContainter>
          <Button
            buttontype="primary"
            size="default"
            disabled={!curationLook}
            onClick={() => handleChoiceResult(true)}
          >
            {"Save"}
          </Button>
          <Button buttontype="secondary" size="default" onClick={() => handleChoiceResult(false)}>
            {"Cancel"}
          </Button>
        </ButtonContainter>
      </ModalContainer>
    </Overflow>
  );
}
