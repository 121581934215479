import styled from "styled-components";

const InputContainer = styled.div`
  width: 100%;
  > p {
    font-family: var(--secondaryHeaderFont);
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.03em;
    user-select: none;
    > span {
      color: red;
      font-weight: bold;
    }
  }

  .errorText {
    display: flex;
    align-items: flex-end;
    user-select: none;

    > p {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.03em;
      color: #ff0000;
      margin-top: 12px;
      margin-left: 12px;
    }
`;

const Field = styled.textarea`
  width: 100%;
  height: 206px;
  border: 1px solid #000000;
  box-sizing: border-box;
  padding: 5px 18px;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 10px;

  font-family: var(--secondaryRegularFont);
  outline: none;
  background: inherit;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.03em;
  color: #000000;
  text-overflow: ellipsis;

  resize: vertical;
  min-height: 52px;
  max-height: 200px;
  padding-top: 13px;
`;

export { InputContainer, Field }