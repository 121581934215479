import theme from "./global";

const { colors } = theme;

export default {
  sizes: {
    default: {
      default: {
        width: "186px",
        height: "56px",
      },
      small: {
        width: "137px",
        height: "56px",
      },
      primary: {
        width: "375px",
        height: "86px",
      },
    },
    tablet: {
      default:{
        width: "148px",
        height: "48px",
      },
      small: {
        width: "110px",
        height: "48px",
      },
      primary: {
        width : "255px",
        height : "66px",
      },
    },
    mobile: {
      default:{
        width: "115px",
        height: "42px",
      },
      small: {
        width: "84px",
        height: "42px",
      },
      primary: {
        width : "140px",
        height : "48px",
      },
    }
  },
  bg: {
    default: {
      default: {
        background: "transparent",
        borderColor: colors.white,
        color: colors.white,
      },
      primary: {
        background: colors.black.regular,
        borderColor: colors.black.regular,
        color: colors.white,
      },
      secondary: {
        background: "transparent",
        borderColor: colors.black.regular,
        color: colors.black.regular,
      },
    },
    hover: {},
    disabled: {
      default: {
        background: "transparent",
        borderColor: colors.grey.regular,
        color: colors.white,
      },
      primary: {
        background: colors.grey.regular,
        borderColor: colors.grey.regular,
        color: colors.white,
      },
      secondary: {
        background: "transparent",
        borderColor: colors.grey.regular,
        color: colors.grey.regular,
      },
    },
    mobile: {
      default: {
        background: "transparent",
        borderColor: colors.white,
        color: colors.white,
      },
      primary: {
        background: colors.black.regular,
        borderColor: colors.black.regular,
        color: colors.white,
      },
      secondary: {
        background: colors.white,
        borderColor: colors.black.regular,
        color: colors.black.regular,
      },
    }
  },
  borderSize: {},
};
