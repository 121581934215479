import IconComponent from "../../../components/fields/icon";
import { useStyles } from "../styles";

export default function SelectedItems(props) {
  const classes = useStyles();
  function dragStartItem() {
    props.onDragStartItem(props.index);
  }

  function removeItem() {
    props.removeSelectedItem(props.index);
  }

  return (
    <>
      <div className={classes.singleItem} draggable onDragStart={dragStartItem}>
        <IconComponent
          iconTitle="CrossIcon"
          className={classes.trash}
          onClick={(event) => {
            event.stopPropagation();
            removeItem();
          }}
        />
        <img
          className={classes.image}
          src={props.item}
          height={100}
          width={100}
          data-maincat={props.mainCat}
          data-itemcat={props.itemCat}
        />
      </div>
    </>
  );
}
