import { useEffect, useState } from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  XAxis,
  Label,
  LabelList,
} from "recharts";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  gender,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
      {`${gender} ${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
const ChartPie = ({ genderData }) => {
  const [validData, setValidData] = useState([]);

  useEffect(() => {
    if (genderData?.length) {
      setValidData(genderData);
    }
  }, [genderData]);

  return (
    <div style={{ height: "300px", width: "50%" }}>
      <h3
        style={{
          justifyContent: "center",
          display: "flex",
          fontFamily: "var(--secondaryHeaderFont)",
        }}
      >
        Gender PieChart
      </h3>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={500} height={500}>
          <Pie
            data={validData}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={100}
            fill="#FF8042"
            dataKey="count"
          >
            {/* <LabelList dataKey="gender" position="outside" fill="#FF8042" /> */}
            {validData?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChartPie;
