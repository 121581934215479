import { gql } from "@apollo/client";

export const checkEmailAccess = gql`
  query Query($email: EmailAddress!) {
    checkEmailAccess(email: $email)
  }
`;

export const getAuthEmails = gql`
  query GetAuthEmails {
    getAuthEmails {
      email
      hasAccess
      profileUrl
      previousScan
    }
  }
`;

export const getAuthUrl = gql`
query GetAuthURL($email: EmailAddress!) {
  getAuthURL(email: $email) {
    statusCode
    message
    error
    data
  }
}`

