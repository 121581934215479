import { makeStyles } from "@material-ui/styles";
import { mobileMediaStyle, tabMediaStyle } from "../../../components/helpers/ResponsiveStyleHelper";

export const useStyles = makeStyles({
  leftNavContainer: {
    width: "18%",
    minWidth: "300px",
    paddingRight: "4vw",
    boxSizing: "border-box",
    fontFamily: "var(--secondaryRegularFont)",
  },

  leftNavHeader: {
    height: "100px",
    fontWeight: "400",
    // fontSize: "24px",
    fontSize: "26px",
    lineHeight: "28px",
    paddingBottom: "15px",
    display: "flex",
    alignItems: "end",
    borderBottom: "1px solid #D9D9D9",
    boxSizing: "border-box",
  },

  subNavMenu: {
    paddingTop: "40px",
  },

  active: {
    fontWeight: "bold !important",
    textDecorationLine: "underline",
  },

  subMenu: {
    // fontSize: "16px",
    fontSize: "18px",
    fontWeight: "300",
    marginBottom: "18px",
    cursor: "pointer",
    display: "flex",
  },

  nestedSubOption: {
    fontSize: "16px",
    fontWeight: "300",
    marginBottom: "18px",
    cursor: "pointer",
    paddingLeft: "40px",
    display: "none",
  },

  subOptionArrow: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "40px",
  },

  nestedActive: {
    display: "block",
  },

  ...tabMediaStyle({
    leftNavContainer: {
      minWidth: "220px",
      paddingRight: "24px",
    },
    leftNavHeader: {
      height: "75px",
      fontSize: "22px",
      lineHeight: "24px",
      paddingBottom: "12px",
    },
    subNavMenu: {
      paddingTop: "30px",
    },
  }),

  ...mobileMediaStyle({
    leftNavContainer: {
      display: "none",
    },
  },"@media (max-width:600px)"),
});
