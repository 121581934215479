import { useState, useRef, useEffect } from "react";
import { useStyles } from "./styles";
import { ButtonContainter, MainText, ModalContainer, Overflow } from "../../components/modal";
import Button from "../../components/fields/button";
import IconComponent from "../../components/fields/icon";
import { useLazyQuery } from "@apollo/client";
import { uploadImage } from "../../apollo/operations/items";
import { openToast } from "../../components/toast";

export default function EditCategoryPopup({ modalResponse, category, userID }) {
  const classes = useStyles();
  const inputRef = useRef(null);
  const [image, setImage] = useState(category?.imageLink ? category?.imageLink : "");
  const [itemDetails, setItemDetails] = useState(category);
  const [GetUploadUrl, { data: uploadResponse }] = useLazyQuery(uploadImage);

  useEffect(() => {
    if (uploadResponse && uploadResponse.getPresignedUploadURL) {
      imageUpload();
    }
  }, [uploadResponse]);
  async function imageUpload() {
    const fileName = itemDetails.image.name;
    var parts = fileName.split(".");
    const fileType = parts[parts.length - 1];

    const res = await fetch(uploadResponse.getPresignedUploadURL.url, {
      method: "PUT",
      headers: {
        "Content-Type": fileType,
      },
      body: itemDetails.image,
    });
    if (res.status === 200) {
      modalResponse(true, uploadResponse.getPresignedUploadURL.key);
    } else {
      openToast("error", "Error", "There is an Error, try again later");
    }
  }

  function handleChoiceResult(value) {
    if (value) {
      if (itemDetails.image === "" && itemDetails.imageLink === "") {
        modalResponse(true, { defaultImage: true });
      }
      if (image) {
        var parts = itemDetails.image.name.split(".");
        const fileName = `${userID}_${category.name}`;
        const fileType = parts[parts.length - 1];
        GetUploadUrl({
          variables: { file: { fileName: fileName, fileType: fileType, flag: "CUSTOMCATIMG" } },
        });
      }
    } else modalResponse(value);
  }

  function handleSelectImage() {
    inputRef.current.click();
  }

  function handleFileUpload(e) {
    const { files } = e.target;
    if (files && files.length) {
      setImage(URL.createObjectURL(files[0]));
      itemDetails.image = files[0];
      itemDetails.imageLink = "";
      setItemDetails({ ...itemDetails });
    }
  }
  function handleResetImage() {
    inputRef.current.value = "";
    setImage("");
    itemDetails.image = "";
    itemDetails.imageLink = "";
    setItemDetails({ ...itemDetails });
    
  }

  const CrossIconDiv = () => (
    <div>
      {image && (
        <IconComponent
          iconTitle="UndoIcon"
          className={classes.crossIcon}
          onClick={handleResetImage}
        />
      )}
    </div>
  );

  return (
    <Overflow onClick={() => handleChoiceResult(false)}>
      <ModalContainer style={{ minHeight: "unset" }} onClick={(e) => e.stopPropagation()}>
        <MainText className={classes.editBoxTitle}>{`Edit ${category.name}`}</MainText>

        <div className={classes.imageUploadWrapper}>
          <div className={classes.imageDiv}>
            <CrossIconDiv />

            <input
              type="file"
              ref={inputRef}
              style={{ display: "none" }}
              onChange={(e) => handleFileUpload(e)}
              accept="image/*"
            />

            <div
              className={classes.displayImage}
              style={{
                backgroundImage: image
                  ? `url(${image})`
                  : `url(${itemDetails.imageLink ? `${itemDetails.imageLink}` : itemDetails.img})`,
                backgroundSize: "cover",
              }}
            >
              <div>
                <IconComponent
                  iconTitle="CameraLinearIcon"
                  onClick={handleSelectImage}
                  className={classes.UploadImageIcon}
                />
              </div>
            </div>
          </div>
        </div>

        <ButtonContainter style={{ paddingTop: "14px" }}>
          <Button buttontype="primary" size="default" onClick={() => handleChoiceResult(true)}>
            {"Save"}
          </Button>
          <Button buttontype="secondary" size="default" onClick={() => handleChoiceResult(false)}>
            {"Cancel"}
          </Button>
        </ButtonContainter>
      </ModalContainer>
    </Overflow>
  );
}
