import { ButtonContainter, ModalContainer, Overflow } from "../../../components/modal";
import { InputField } from "../../../components/signin";
import Button from "../../../components/fields/button";
import { useEffect, useState } from "react";
import { updateUserProfile } from "../../../apollo/operations/user";
import { useMutation } from "@apollo/client";
import { validatePassword } from "../../../components/helpers/validate";
import { openToast } from "../../../components/toast";
import Loader from "../../../components/loaderScreen";
import { useNavigate } from "react-router-dom";

export default function PasswordUpdatePopup(props) {
  const { updatePassword } = props;
  const [inputErrors, setErrors] = useState({});
  const [apiErrors, setApiErrors] = useState({});
  const [updateProfile, { data: userUpdated, loading }] = useMutation(updateUserProfile);
  const [tempUser, setTempUser] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    showConfirmPassword: false,
  });
  const navigate = useNavigate();

  userUpdated;
  useEffect(() => {
    if (userUpdated) {
      if (userUpdated.updateUserDetails.statusCode === "401") {
        setApiErrors(userUpdated.updateUserDetails);
      } else if (userUpdated.updateUserDetails.statusCode === "200") {
        openToast("success", "Password changed", "Please login to continue");
        navigate("/betatesters/signin");
        // navigate("/signin");
      } else {
        openToast("error", "Password change failed", "Try again later");
        updatePassword();
      }
    }
  }, [userUpdated]);

  function handleIconClick() {
    handleChange({ target: { name: "showConfirmPassword", value: !tempUser.showConfirmPassword } });
  }

  function handleChange(event) {
    let {
      target: { name, value },
    } = event;

    if (name === "oldPassword" || name === "newPassword" || name === "confirmPassword") {
      value = value.trim();
    }

    if (name === "oldPassword" && apiErrors?.message) {
      setApiErrors({});
    }

    let isValid = true;
    if (
      name === "confirmPassword" &&
      tempUser.newPassword !== "" &&
      value !== tempUser.newPassword
    ) {
      isValid = false;
    } else if (value === "") {
      isValid = false;
    }

    setTempUser((prevState) => (value === null ? prevState : { ...prevState, [name]: value }));

    setErrors((prevState) => {
      let val = "";
      if (name === "newPassword") {
        val = value !== tempUser.confirmPassword;
      }
      return name === "newPassword"
        ? {
            ...prevState,
            [name]: !isValid,
            confirmPassword: val,
          }
        : value === null || value === ""
        ? prevState
        : {
            ...prevState,
            [name]: !isValid,
          };
    });
  }

  function handleUpdatePassword(value) {
    if (!value) {
      updatePassword();
    } else {
      let errors = {
        oldPassword: !tempUser.oldPassword,
        newPassword: !tempUser.newPassword || !validatePassword(tempUser.newPassword),
        confirmPassword:
          !tempUser.confirmPassword || tempUser.newPassword !== tempUser.confirmPassword,
      };
      if (Object.values(errors).filter((ele) => ele).length) {
        setErrors((prevState) => ({ ...prevState, ...errors }));
      } else {
        // api call for updating password
        updateProfile({
          variables: {
            userDetails: {
              oldPassword: tempUser.oldPassword,
              newPassword: tempUser.newPassword,
            },
          },
        });
      }
    }
  }

  return (
    <Overflow>
      {loading && <Loader />}
      <ModalContainer style={{ minHeight: "unset", fontFamily: "karla" }}>
        <InputField
          labelName={"Current Password"}
          name="oldPassword"
          value={tempUser.oldPassword || ""}
          onChange={handleChange}
          type="password"
          errorText={
            inputErrors.oldPassword
              ? "Please enter current password"
              : apiErrors?.message === "Invalid Credentials"
              ? "Password is not Valid"
              : ""
          }
        />
        <InputField
          labelName={"New Password"}
          name="newPassword"
          className="signUp"
          value={tempUser.newPassword || ""}
          onChange={handleChange}
          type="password"
          errorText={
            inputErrors.newPassword
              ? "Password must be minimum 8 characters, including at least 1 uppercase, 1 lowercase, 1 numeric and 1 special character"
              : ""
          }
        />
        <InputField
          labelName={"Confirm Password"}
          icon={tempUser.showConfirmPassword ? "EyeBoldCrossIcon" : "EyeBoldIcon"}
          name="confirmPassword"
          value={tempUser.confirmPassword || ""}
          onChange={handleChange}
          type={tempUser.showConfirmPassword ? "text" : "password"}
          onIconClick={handleIconClick}
          errorText={inputErrors.confirmPassword ? "Both new & confirm password need to match" : ""}
        />
        <ButtonContainter>
          <Button buttontype="primary" size="default" onClick={() => handleUpdatePassword(true)}>
            {"Update"}
          </Button>
          <Button buttontype="secondary" size="default" onClick={() => handleUpdatePassword(false)}>
            {"Cancel"}
          </Button>
        </ButtonContainter>
      </ModalContainer>
    </Overflow>
  );
}
