import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";
import PropTypes from "prop-types";

import IconComponent from "../fields/icon";

const StyledToastContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    width: 440px;
    height: 80px;
    border-radius: 5px;
    bottom: 4em;

    @media screen and (max-width: 480px){
      height: 64px;
      width: 100%;
      display: flex;
      justify-content: end;
    }
  }

  .Toastify__toast {
    padding: 0px;
    margin-bottom: 0px;

    @media screen and (max-width: 480px){
      right: 1em;
      border-radius: 4px;
    }
  }

  .Toastify__toast-body {
    padding: 0px;

    & > div {
      display: flex;
      align-items: center;
      column-gap: 12px;
      bottom: 120px;
      width: 438px;
      height: 78px;
      box-sizing: border-box;
      padding: 10px;
      background: #ffffff;

      @media screen and (max-width: 480px){
        column-gap: 8px;
        width: 300px;
        height: 62px;
        padding: 8px;
      }
    }
  }
`;

const Line = styled.div`
  width: 5px;
  height: 58px;

  background: ${({ type }) => (type === "success" ? "#08b662" : "#F43131")};
  border-radius: 10px;
`;

const Msg = styled.div`
  font-family: var(--primaryRegularFont);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #000000;
  margin-bottom: 5px;

  @media screen and (max-width: 480px){
    font-Size: 16px;
    line-height: 16px;
  }
`;

const Info = styled.div`
  font-family: var(--primaryRegularFont);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #3f3d56;

  @media screen and (max-width: 480px){
    font-size: 14px;
    line-height: 16px;
  }
`;

const Cross = styled.div`
  position: absolute;
  right: 10px;
  cursor: pointer;
`;

const toastTypes = ["success", "error"];

export const openToast = (type = "success", msg = "", info = "", duration = 4000) => {
  toast(<Toast />, {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: duration,
    hideProgressBar: true,
    draggable: false,
    closeButton: false,
    closeOnClick: false,
    data: { type, msg, info },
  });
};

openToast.propTypes = {
  type: PropTypes.oneOf(toastTypes).isRequired,
  msg: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  duration: PropTypes.number,
};

export const Toast = ({ closeToast, data }) => (
  <>
    <Line type={data.type} />
    <IconComponent iconTitle={data.type === "success" ? "TickCircleIcon" : "InfoCircleIcon"} />
    <div>
      <Msg>{data?.msg || ""}</Msg>
      <Info>{data?.info || ""}</Info>
    </div>
    <Cross onClick={closeToast}>
      <IconComponent iconTitle={"CrossIcon"} />
    </Cross>
  </>
);

export default StyledToastContainer;
