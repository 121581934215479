import { makeStyles } from "@material-ui/styles";
import { mobileMediaStyle } from "../../components/helpers/ResponsiveStyleHelper";

export const useStyles = makeStyles({
  modulesWrapper: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: " 18px 24px",
    padding: "25px 4px",
    overflow: "auto",

    "&::-webkit-scrollbar": {
      width: "3px",
      height: "3px",
    },

    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },

    "&::-webkit-scrollbar-thumb": {
      background: "#c1c1c1",
      borderRadius: "10px",
    },
  },

  modules: {
    padding: "15px",
    borderRadius: "5px",
    cursor: "pointer",
    border: "1px solid #f7f7f7",
    backgroundColor: "#f7f7f7",

    "&:hover": {
      backgroundColor: "#ffe4d4",
      border: "1px solid #FB5B01",
    },
  },

  moduleTitle: {
    fontFamily: "var(--primaryRegularFont)",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "bold",
    paddingBottom: "8px",
  },

  moduleSummary: {
    fontFamily: "var(--primaryRegularFont)",
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: "300",
  },

  subText: {
    fontFamily: "var(--primaryRegularFont)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    fontSize: "18px",
    paddingBottom: "24px",
    letterSpacing: "0.54px",

    "@media (max-width: 450px)": {
      fontSize: "16px",
      paddingBottom: "20px",
      letterSpacing: "0.48px",
    },
  },

  subTitle: {
    fontFamily: "var(--primaryRegularFont)",
    display: "flex",
    textAlign: "center",
    fontSize: "18px",
    paddingBottom: "8px",
    letterSpacing: "0.54px",

    "@media (max-width: 450px)": {
      fontSize: "16px",
    },
  },

  stepHead: {
    fontFamily: "var(--primaryHeaderFont)",
    fontSize: "16px",
    fontWeight: "700",
    letterSpacing: "0.48px",
    lineHeight: "20px",
    paddingBottom: "5px",

    "@media (max-width: 450px)": {
      fontSize: "14px",
    },
  },

  stepBody: {
    fontFamily: "var(--primaryHeaderFont)",
    fontSize: "16px",
    lineHeight: "19px",
    paddingBottom: "16px",
    fontWeight: "300",
    letterSpacing: "0.48px",
    maxHeight: "450px",
    overflow: "auto",
    boxSizing: "border-box",

    "&::-webkit-scrollbar": {
      width: "3px",
      height: "3px",
    },

    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },

    "&::-webkit-scrollbar-thumb": {
      background: "#c1c1c1",
      borderRadius: "10px",
    },

    "@media (max-width: 450px)": {
      fontSize: "14px",
      lineHeight: "16px",
      paddingBottom: "14px",
    },
  },

  para: {
    fontWeight: "300",
    letterSpacing: "0.48px",
  },

  highlight: {
    color: "#FB5B01",
    fontStyle: "italic",
    fontWeight: "bold",
    letterSpacing: "0.48px",
  },

  italic: {
    fontStyle: "italic",
    letterSpacing: "0.48px",
  },

  bold: {
    fontWeight: "bold",
    letterSpacing: "0.48px",
  },

  imageContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",

    "& .MuiAvatar-root": {
      height: "100px",
      width: "100px",
      boxShadow: "0 0 3px #7b7b7b",
    },

    "& .MuiAvatar-img": {
      height: "100px",
      width: "100px",
    },

    "& > i > svg": {
      height: "100px",
      width: "100px",
    },

    "@media (max-width: 900px)": {
      "& .MuiAvatar-root": {
        height: "90px",
        width: "90px",
      },

      "& .MuiAvatar-img": {
        height: "90px",
        width: "90px",
      },

      "& > i > svg": {
        height: "90px",
        width: "90px",
      },
    },

    "@media (max-width: 450px)": {
      "& .MuiAvatar-root": {
        height: "75px",
        width: "75px",
      },

      "& .MuiAvatar-img": {
        height: "75px",
        width: "75px",
      },

      "& > i > svg": {
        height: "75px",
        width: "75px",
      },
    },
  },
  videoTour: {
    textDecoration: "underline",
    justifyContent: "center",
    display: "flex",
    padding: "0 0 18px 8px",
    fontFamily: "var(--primaryRegularFont)",
    fontSize: "20px",
    fontWeight: "400",
    letterSpacing: "0.6",
    alignItems: "center",
    cursor: "pointer",
    gap: 15,
  },
  checkboxWrapper: {
    display: "flex",
    padding: "0 0 0 8px",
    fontFamily: "var(--primaryRegularFont)",
    fontSize: "20px",
    fontWeight: "400",
    letterSpacing: "0.6",
    alignItems: "center",
    cursor: "pointer",
    gap: 15,

    "@media (max-width: 900px)": {
      fontSize: "16px",
      padding: "0 0 0 17px",
    },

    "@media (max-width: 450px)": {
      fontSize: "12px",
      padding: "0 0 0 12px",
    },
  },

  checkboxIcon: {
    width: "23px",
    height: "23px",

    "@media (max-width: 900px)": {
      width: "20px",
      height: "20px",
    },

    "@media (max-width: 450px)": {
      width: "18px",
      height: "18px",
    },
  },
  ...mobileMediaStyle({
    modalContainer: {
      padding: "24px 41px",
      gap: 12,
      minHeight: "unset",
    },
    profileImage: {
      width: 80,
      height: 80,
    },
    welcomeHeader: {
      fontSize: 18,
      fontWeight: 600,
      letterSpacing: 0.54,
    },
    welcomeText: {
      fontSize: 12,
      letterSpacing: 0.36,
      lineHeight: "18px",
    },
    modalButtonContainer: {
      paddingTop: 22,
    },
    checkboxIcon: {
      width: 15,
      height: 15,
    },
    checkboxWrapper: {
      fontSize: 12,
      letterSpacing: 0.36,
      gap: 10,
    },
  }),
});
