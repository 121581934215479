import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, ButtonContainer, H1, H4 } from "../../components/dashboard";
import Modal from "../../containers/common/modal";
import {
  DashboardContainer,
  DashboardParent,
  HeaderCont,
  HowItWorkBackGround,
  HowitWorks,
  ImgCont,
} from "../../containers/dashboard";
import Categories from "../../containers/dashboard/categories";
import { useAuth } from "../../hooks/auth";
import HowItWork from "./HowItWorkGuide";
import { useDispatch, useSelector } from "react-redux";
import WelcomePopup from "./WelcomePopup";
import { setNotShowAYSPopup, setShowAddyourStylePopup } from "../../store/showPopupSlice";

export default function Dashboard() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const modal = useRef(null);
  const dispatch = useDispatch();
  const gender = user?.gender?.toLowerCase();
  const [showHowItWork, setShowHowIsWork] = useState(false);
  const [showWelcomePopup, setShowWelcomePopup] = useState(false);
  const { showPopup } = useSelector((state) => state.showPopup);

  useEffect(() => {
    setTimeout(() => {
      setShowWelcomePopup(showPopup && (user?.newPurchasePopup || user?.showPopup));
    }, 500);
  }, [showPopup, user]);

  const handleClickCreate = () => {
    // dispatch(setShowAddyourStylePopup(false));
    modal.current?.open();
  };

  const handleHowWork = () => {
    setShowHowIsWork(true);
  };

  const handleHowWorkResponse = (value) => {
    if (!value) {
      setShowHowIsWork(false);
    }
  };

  const handleChoice = (navigateURL) => {
    dispatch(setNotShowAYSPopup(false));
    navigate(navigateURL);
  };

  return (
    <DashboardParent>
      <DashboardContainer>
        <HeaderCont>
          <ImgCont gender={gender} />
          <HowitWorks gender={gender}>
            <HowItWorkBackGround>
              <H4>LET‘S</H4>
              <H1>ORGANIZE</H1>
              <H1>YOUR CLOSET</H1>
              <ButtonContainer>
                <Button buttontype="primary" onClick={handleHowWork}>
                  How it Works
                </Button>
                <Button buttontype="secondary" onClick={handleClickCreate}>
                  Create Closet
                </Button>
              </ButtonContainer>
            </HowItWorkBackGround>
          </HowitWorks>
        </HeaderCont>
        <Categories gender={gender} userID={user?.id} />
        <Modal
          ref={modal}
          type="createCloset"
          handleChoice={() => handleChoice("/addyourstyle/scanemail")}
        />
        {showHowItWork && <HowItWork modalResponse={handleHowWorkResponse} />}
        {showWelcomePopup && (
          <WelcomePopup userData={user ?? {}} setShowWelcomePopup={setShowWelcomePopup} />
        )}
      </DashboardContainer>
    </DashboardParent>
  );
}
