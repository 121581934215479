import { useState, useEffect } from "react";
import { InputField, SubmitButton } from "../../components/signin/index.jsx";
import { useStyles } from "./styles.js";
import { reachOut } from "../../apollo/operations/contactUs.js";
import { useMutation } from "@apollo/client";
import { openToast } from "../../components/toast";
import { validateEmail } from "../../components/helpers/validate";
import Icon from "../../components/fields/icon.jsx";
import Loader from "../../components/loaderScreen/index.jsx";
import { InputContainer, Field } from "./styledDivs.js";
import ContactUsImage from "../../assets/images/contactUs.jpg";

function TextAreaInput({
  labelName,
  required,
  setMessage,
  value,
  errorText,
  isButtonTapped,
  isPositive,
}) {
  return (
    <InputContainer>
      {labelName && (
        <p>
          {labelName}: {required && <span>*</span>}
        </p>
      )}
      <Field
        onChange={(e) => {
          setMessage(e.target.value);
          isButtonTapped && isPositive();
        }}
        value={value}
      />
      {!!errorText && (
        <div className="errorText">
          <Icon iconTitle={"ErrorBoldIcon"} />
          <p>{errorText}</p>
        </div>
      )}
    </InputContainer>
  );
}

export default function ContactUsFragment() {
  const [ReachoutRequest, { loading, data: responseReachOut }] = useMutation(reachOut);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const classes = useStyles();
  const [isButtonTapped, setIsButtonTapped] = useState(false);
  const [inputErrors, setInputErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    message: false,
    subject: false
  });

  const isPositive = () => {
    let status = true;

    let tempInputErrors = { ...inputErrors };
    if (firstName.length < 3) {
      status = false;
      tempInputErrors.firstName = true;
    } else {
      tempInputErrors.firstName = false;
    }
    if (!lastName) {
      status = false;
      tempInputErrors.lastName = true;
    } else {
      tempInputErrors.lastName = false;
    }
    if (!validateEmail(email)) {
      // console.log({ email, valid: validateEmail(email) });
      // console.log(validateEmail(email));
      // console.log(email?.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,6}$/));
      status = false;
      tempInputErrors.email = true;
    } else {
      tempInputErrors.email = false;
    }
    if(!subject) {
      status = false
      tempInputErrors.subject = true
    } else {
      tempInputErrors.subject = false
    }
    if (!message) {
      status = false;
      tempInputErrors.message = true;
    } else {
      tempInputErrors.message = false;
    }
    setInputErrors(tempInputErrors);

    return status;
  };

  const handleRequestReachOut = async () => {
    setIsButtonTapped(true);
    if (isPositive()) {
      ReachoutRequest({
        variables: {
          reachOutReq: {
            firstName,
            lastName,
            email,
            message,
            subject
          },
        },
      });
    }
  };

  useEffect(() => {
    if (responseReachOut) {
      if (responseReachOut?.reachOut?.statusCode === "200") {
        openToast("success", "Success", "Message sent");
      } else {
        openToast("error", "Failed", "Failed to send message");
      }
    }
  }, [loading]);

  useEffect(() => {
    if (email.length > 1) {
      setInputErrors({ ...inputErrors, email: !validateEmail(email) });
    }
    if (isButtonTapped) {
      isPositive();
    }
  }, [firstName, lastName, email, message, subject]);

  console.log(document.getElementsByClassName("rightSection").clientHeight);
  return (
    <>
      <div className={classes.contactUsDiv}>
        <div className={classes.imageDiv}>
          {/* <div style={{paddingTop:"100%",paddingBottom:"100%"}}>{}</div> */}
          {/* <img src={ContactUsImage} className={classes.image}/> */}
        </div>
        <div className={classes.rightSection}>
          <div className={classes.subContactUsDiv}>
            <h2 className={classes.title}>CONTACT US</h2>
            <p className={classes.contactText}>
              Need to reach Mikloset support? Fill out the contact form or click{" "}
              <a href="mailto:miklosethelp@gmail.com">here</a> to send us an email.
            </p>
          </div>
          <div className={classes.formCard}>
            <div className={classes.name}>
              <InputField
                className="signUp"
                labelName={"First Name"}
                value={firstName}
                name="firstName"
                onChange={(e) => {
                  setFirstName(e.target.value);
                  // isButtonTapped && isPositive();
                }}
                required={true}
                errorText={inputErrors.firstName && "min 3 characters"}
              />
              <InputField
                className="signUp"
                labelName={"Last Name"}
                name="lastName"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                  // isButtonTapped && isPositive();
                }}
                errorText={inputErrors.lastName && "Please enter last name"}
                required={true}
              />
            </div>
            <div className={classes.emailAndMessage}>
              <InputField
                className="signUp"
                labelName={"Email"}
                name="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  // isButtonTapped && isPositive();
                }}
                errorText={inputErrors.email && "Please enter valid email"}
                required={true}
              />
              <InputField
                className="signUp"
                labelName={"Subject"}
                name="subject"
                value={subject}
                onChange={(e) => {
                  setSubject(e.target.value);
                  // isButtonTapped && isPositive();
                }}
                errorText={inputErrors.subject && "Please type your subject"}
                required={true}
              />
              <TextAreaInput
                labelName={"Message"}
                setMessage={setMessage}
                value={message}
                errorText={inputErrors.message && "Please type your message"}
                required={true}
                //   isButtonTapped={isButtonTapped}
                //   isPositive={isPositive}
              />
              <SubmitButton
                style={{ margin: "10px 0" }}
                className="signUp"
                buttontype="primary"
                onClick={handleRequestReachOut}
              >
                Submit
              </SubmitButton>
            </div>
          </div>
        </div>
      </div>

      {loading && <Loader />}
    </>
  );
}
