import { useStyles } from "./styles";
import getUser from "../../hooks/useQueryMe";
import { AboutUsImageContainer } from "../../containers/AboutUs";

export default function AboutUs() {
  const { currentUser } = getUser();
  const classes = useStyles();
  return (
    <>
      {!currentUser?.email && <div className={classes.miklosetTitle}>MIKLOSET</div>}
      <div className={classes.parentContainer}>
        <AboutUsImageContainer />
        <div className={classes.bodyContainer}>
          <div className={classes.title}>{"ABOUT US"}</div>
          <p className={classes.content}>
            {
              "Mikloset (pronounced My-Closet) represents a revolutionary approach to closet management and engagement. It offers an easy way to search and scroll, digitalized rolodex of an individual’s closet using next-generation technology to help manage, inspire, and curate personalized looks. At Mikloset, you can effortlessly share, sell, curate, and style your existing clothing collection."
            }
          </p>
        </div>
      </div>
    </>
  );
}
