import { useEffect, useState } from "react";

import {
  BackContainer,
  Container,
  ImgContainer,
  Section,
  SubContainer,
  SubSection,
} from "../../containers/signin";
import { Hyper, InputField, Para, SubmitButton, Title } from "../../components/signin";
import { Heading, OpenMailButton, Span, Text } from "../../components/forgotpassword";
// import background from "../../assets/images/forgotPassword.png";
import backgroundNew from "../../assets/images/forgotPassword_new.png";
import OTPInput from "../../components/fields/otpInput";
import { validateEmail } from "../../components/helpers/validate";
import { useMutation } from "@apollo/client";
import { requestResetPassword } from "../../apollo/operations/user";
import Loader from "../../components/loaderScreen";
import { openToast } from "../../components/toast";

const ForgotPassword = () => {
  const [details, setDetails] = useState("");
  const [byEmail /* , setByEmail */] = useState(true);
  const [isReset, setIsReset] = useState(false);
  const [inputErrors, setErrors] = useState(false);
  const [sendRequest, { data: resetResponse, loading }] = useMutation(requestResetPassword);

  useEffect(() => {
    if (resetResponse?.requestResetPassword) {
      if (+resetResponse.requestResetPassword.statusCode === 200) {
        openToast("success", "Email Sent Successfully");
        setIsReset(true);
      } else openToast("error", resetResponse.requestResetPassword.message);
    }
  }, [resetResponse]);

  function handleInputChange({ target: { value } }) {
    setDetails(value.trim());
    if (inputErrors) setErrors(!!value && !validateEmail(value));
  }

  function handleSubmitButton() {
    if (details && validateEmail(details)) {
      sendRequest({
        variables: {
          userInfo: details,
        },
      });
    } else setErrors(true);
  }

  const handleEnterOtp = (value) => {
    console.log("otp", value);
  };

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      handleSubmitButton();
    }
  };

  return (
    <>
      <Section>
        <SubSection>
          <ImgContainer img={backgroundNew} />
          <Container img={backgroundNew}>
            <BackContainer>
              <SubContainer>
                <Title>MIKLOSET</Title>
                <Heading>
                  {isReset ? (byEmail ? "CHECK YOUR EMAIL" : "SMS CODE") : "FORGOT PASSWORD?"}
                </Heading>
                {isReset ? (
                  byEmail ? (
                    <>
                      <Para>We sent a password reset link to</Para>
                      <Text>{details}</Text>
                    </>
                  ) : (
                    <Text>We sent you SMS Code on your Phone Number</Text>
                  )
                ) : (
                  <Text>Don’t worry, We’ll send you the reset instructions</Text>
                )}
                {!isReset && (
                  <InputField
                    labelName={byEmail ? "Email" : "Phone number"}
                    name={byEmail ? "email" : "mobile"}
                    value={details ?? ""}
                    onChange={handleInputChange}
                    placeholder="Enter your Email"
                    errorText={inputErrors && "Enter Valid Details"}
                    onKeyDown={handleKeyPress}
                  />
                )}
                {isReset && !byEmail ? (
                  <OTPInput otpLength={4} handleChange={handleEnterOtp} />
                ) : isReset ? (
                  <OpenMailButton buttontype="primary">Email Sent Successfully.</OpenMailButton>
                ) : (
                  <SubmitButton
                    buttontype="primary"
                    onClick={handleSubmitButton}
                    disabled={inputErrors}
                  >
                    Reset Password
                  </SubmitButton>
                )}
                {isReset && (
                  <Para>
                    {byEmail ? "Don’t receive the Email?" : "Don’t receive the Code?"}
                    <Span style={{ cursor: "pointer" }} onClick={handleSubmitButton}>
                      {" "}
                      Click to Resend
                    </Span>
                  </Para>
                )}
                <Para>
                  {/* Back to <Hyper to={"/signin"}>Log In</Hyper> */}
                  Back to <Hyper to={"/betatesters/signin"}>Log In</Hyper>
                </Para>
              </SubContainer>
            </BackContainer>
          </Container>
        </SubSection>
      </Section>
      {loading && <Loader />}
    </>
  );
};

export default ForgotPassword;
