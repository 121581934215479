import { makeStyles } from "@material-ui/styles";
import { mobileMediaStyle, tabMediaStyle } from "../../components/helpers/ResponsiveStyleHelper";

export const useStyles = makeStyles({
  parentContainer: {
    padding: "40px 60px",
  },
  title: {
    fontFamily: "var(--primaryHeaderFont)",
    fontWeight: "500",
    fontSize: "32px",
    lineHeight: "40px",
  },
  mainContainer: {
    display: "flex",
  },
  rightContainer: {
    width: "85%",
    display: "flex",
    flexDirection: "column",
    marginTop: "100px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    borderBottom: "1px solid #D9D9D9",
    height: "100px",
    paddingBottom: "15px",
    boxSizing: "border-box",
    alignItems: "end",
  },

  Button: {
    height: "56px",
    width: "137px",
    fontFamily: "var(--primaryRegularFont)",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "23px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },

  enabledShareMe: {
    color: "#ffffff",
    backgroundColor: "#000000",
  },

  disabledShareMe: {
    color: "#ffffff",
    backgroundColor: "#CACACA",
  },

  enabledStyleMe: {
    color: "#000000",
    backgroundColor: "#ffffff",
    border: "2px solid #000000",
    boxSizing: "border-box",
    marginLeft: "18px",
  },

  disabledStyleMe: {
    color: "#CACACA",
    backgroundColor: "#ffffff",
    border: "2px solid #CACACA",
    boxSizing: "border-box",
    marginLeft: "18px",
  },

  rightHeader: {
    display: "flex",
    paddingTop: "40px",
    fontFamily: "var(--primaryRegularFont)",
    fontSize: "16px",
  },

  itemsCount: {
    fontWeight: "bold",
  },

  sortOption: {
    marginLeft: "auto",
    display: "flex",
    cursor: "pointer",
    gap: "5px",
    position: "relative",
  },

  itemsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20vh 0",
    fontFamily: "var(--primaryRegularFont)",
  },

  errorMsg: {
    paddingTop: "5vh",
    fontSize: "24px",
    lineHeight: "28px",
    fontWeight: "500",
    color: "#CACACA",
  },

  addItemButton: {
    height: "64px",
    width: "220px",
    marginTop: "5vh",
    backgroundColor: "#000000",
    color: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    boxSizing: "border-box",
    padding: "0 35px",
    cursor: "pointer",
  },

  closetItemsContainer: {
    paddingTop: "24px",
    display: "flex",
    flexWrap: "wrap",
  },

  closetItem: {
    width: "243px",
    height: "320px",
    marginRight: "18px",
    marginBottom: "40px",
    fontFamily: "var(--primaryRegularFont)",
    position: "relative",
  },

  actionField: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "32px",
    width: "32px",
    borderRadius: "16px",
    background: "rgba(251, 91, 1, 0.4)",
    position: "absolute",
  },

  selectField: {
    left: "10px",
    top: "12px",
  },

  likeField: {
    top: "12px",
    right: "10px",
  },

  itemImg: {
    width: "243px",
    height: "283px",
    objectFit: "contain",
    boxShadow: "0 0 2px #cdcdcd",
    boxSizing: "border-box",
  },

  itemDetails: {
    width: "30%",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    marginTop: "15px",
    boxSizing: "border-box",
    padding: "0 20px",
  },

  ItemsCard: {
    position: "absolute",
    left: "90%",
  },

  icon: {
    filter: "invert(1)",
  },

  //css of left categories
  leftContainer: {
    width: "18%",
    minWidth: "300px",
    paddingRight: "4vw",
    boxSizing: "border-box",
    fontFamily: "var(--primaryRegularFont)",
  },

  leftHeader: {
    height: "100px",
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "28px",
    paddingBottom: "15px",
    display: "flex",
    alignItems: "end",
    boxSizing: "border-box",
    fontFamily: "var(--primaryRegularFont)",
  },

  // subCategoryMenu: {
  //   paddingTop: "40px",
  // },

  subCategory: {
    fontSize: "16px",
    fontWeight: "300",
    marginBottom: "18px",
    cursor: "pointer",
    display: "flex",
    gap: "14px",
    width: "fit-content",
  },

  subCategoryArrow: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "40px",
  },

  active: {
    fontWeight: "bold !important",
    textDecorationLine: "underline",
  },

  nestedSubCategory: {
    fontSize: "16px",
    fontWeight: "300",
    marginBottom: "18px",
    cursor: "pointer",
    paddingLeft: "40px",
    display: "none",
  },

  nestedActive: {
    display: "block",
  },

  addCategory: {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: "bold",
    marginTop: "40px",
    cursor: "pointer",
    display: "flex",
  },
  outfitImage: {
    height: "100px",
    width: "100px",
    padding: "10px",
    objectFit: "contain",
  },
  addVector: {
    position: "absolute",
    left: "23.8%",
    right: "93.16%",
    top: "24.3%",
    bottom: "69.19%",
  },
  likeVector: {
    position: "absolute",
    left: "23.8%",
    right: "93.16%",
    top: "29.3%",
    bottom: "69.19%",
  },
  addCategoryText: {
    paddingLeft: "20px",
  },
  curationContainer: {
    paddingTop: "24px",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr))",
    gap: "30px 15px",
  },
  categoryLeftMenu: {
    fontWeight: 500,
    fontSize: "1.3rem",
    textDecoration: "underline",
    margin: "30px 0 30px 0",
    cursor: "pointer",
  },
  dropDownSection: {
    margin: "0 0 0 40px",
    padding: "0 0 18px 12px",
    borderLeft: "0.5px solid #ababab",
  },

  ...tabMediaStyle({
    parentContainer: {
      padding: "30px 40px",
    },
    
    title: {
      fontSize: "30px",
      lineHeight: "32px",
    },

    leftHeader: {
      height: "75px",
      fontSize: "22px",
      lineHeight: "24px",
      paddingBottom: "12px",
    },

    rightContainer: {
      marginTop: "75px",
    },

    categoryLeftMenu: {
      margin: "22px 0 22px 0",
    },

    rightHeader: {
      paddingTop: "26px",
    },

    leftContainer: {
      minWidth: "220px",
      paddingRight: "24px",
    },
  }),

  ...mobileMediaStyle({
    leftContainer: {
      display: "none",
    },
    parentContainer: {
      padding: "24px 0",
    },
    title: {
      display: "none",
    },
    rightContainer: {
      width: "unset",
      margin: "0 16px",
    },
    rightHeader: {
      paddingTop: "24px",
      fontSize: "10px",
    },
    mainContainer: {
      flexDirection: "column",
    },
    sortOption: {
      textDecoration: "underline",
    },
    curationContainer: {
      paddingTop: "15px",
      gridTemplateColumns: "repeat(auto-fill, minmax(111px, 1fr))",
      gap: "12px",
    },
  }, "@media (max-width: 600px)"),
});
