import { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import Button from "../../../components/fields/button";
import IconComponent from "../../../components/fields/icon";
import { modalTypes } from "./constant";
import GlobalStyles from "../../../styles/global";

const {mediaQueries} = GlobalStyles;

const Overflow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 20;
`;

const ModalContainer = styled.div`
  position: absolute;
  width: 550px;
  height: 550px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  padding: 24px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  font-family: var(--secondaryRegularFont);

  @media screen and (${mediaQueries.tablet}) {
    width: 450px;
    height: 450px;
    padding: 18px 24px;
  }

  @media screen and (${mediaQueries.mobile}) {
    width: 336px;
    height: 336px;
  }
`;

const HeaderSvg = styled.div`
  background: #f7f7f7;
  border-radius: 5px;
  height: 229px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;

  @media screen and (${mediaQueries.tablet}) {
    height: 150px;
    margin-bottom: 18px;
  }

  @media screen and (${mediaQueries.mobile}) {
    height: 128px;
    > i {
      > svg {
        height: 95px;
      }
    }
  }
`;

const MainText = styled.div`
  font-family: var(--primaryRegularFont);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  justify-content: center;
  color: #000000;
  margin-bottom: 12px;

  @media screen and (${mediaQueries.tablet}) {
    font-size: 20px;
  }

  @media screen and (${mediaQueries.mobile}) {
    font-size: 18px;
  }
`;

const SubText = styled.div`
  font-family: var(--primaryRegularFont);
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
  color: #000000;

  @media screen and (${mediaQueries.tablet}) {
    font-size: 14px;
    line-height: 20px;
  }

  @media screen and (${mediaQueries.mobile}) {
    font-size: 12px;
    line-height: 18px;
  }
`;

const ButtonContainter = styled.div`
  display: flex;
  column-gap: 24px;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: 12px;
`;

const modalElement = document.getElementById("modal-root");

const Modal = ({ defaultOpen, type, handleChoice }, ref) => {
  const [openModal, setOpen] = useState(defaultOpen || false);

  const close = useCallback(() => setOpen(false), []);

  const modalType = useMemo(() => modalTypes[type], [type]);

  useImperativeHandle(
    ref,
    () => ({
      open: () => setOpen(true),
      close,
    }),
    [close],
  );

  const handleChoiceResult = (choice) => {
    choice ? handleChoice() : close () 
  };

  return createPortal(
    openModal ? (
      <Overflow>
        <ModalContainer>
          {modalType?.headerSvg ? (
            <HeaderSvg>
              <IconComponent iconTitle={modalType?.headerSvg} />
            </HeaderSvg>
          ) : (
            ""
          )}
          {modalType?.mainText ? <MainText>{modalType.mainText}</MainText> : ""}
          {modalType?.subText ? <SubText>{modalType.subText}</SubText> : ""}
          <ButtonContainter>
            <Button buttontype="primary" size="default" onClick={() => handleChoiceResult(true)}>
              {modalType?.acceptButton}
            </Button>
            <Button buttontype="secondary" size="default" onClick={() => handleChoiceResult(false)}>
              {modalType?.rejectButton}
            </Button>
          </ButtonContainter>
        </ModalContainer>
      </Overflow>
    ) : (
      ""
    ),
    modalElement,
  );
};

export default forwardRef(Modal);
