import styled from "styled-components";
import globalStyles from "../../styles/global";
const { mediaQueries } = globalStyles;

export const MainContainer = styled.div`
  width: 100%;
  position: relative;
`;
export const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-family: var(--primaryHeaderFont);
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  text-align: left;
  padding: 30px 60px;
  color: #000000;

  @media screen and (${mediaQueries.tablet}) {
    font-size: 36px;
    line-height: 40px;
    padding: 30px 40px 40px;
  }

  @media screen and (${mediaQueries.smallTablet}) {
    font-size: 34px;
    line-height: 36px;
    padding: 24px 28px 30px 28px;
  }

  @media screen and (${mediaQueries.mobile}) {
    font-size: 20px;
    line-height: 25px;
    padding: 18px 16px 24px 16px;
  }
`;

export const AddClosetNav = styled.div`
  width: 100%;
  padding: 0 60px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: space-between;

  @media screen and (${mediaQueries.tablet}) {
    padding: 0 40px;
  }

  @media screen and (${mediaQueries.smallTablet}) {
    padding: 0 28px;
  }

  @media screen and (${mediaQueries.mobile}) {
    padding: 0 16px;
  }
`;

export const AddClosetMenu = styled.div`
  width: 32%;
  height: 54px;
  box-sizing: border-box;
  // border: 2px solid black;
  border: ${({available}) => available? "2px solid black": " 2px solid #cacaca"};
  border-radius: 8px;

  > a {
    text-decoration: none;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 30px;
    font-family: var(--primaryRegularFont);
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    // color: #000000;
    color: ${({available}) => available? "#000000": "#cacaca"};
    box-sizing: border-box;

    > img {
      padding-right: 24px;
      height: 25px;
      width: 25px;
      filter: invert(1);
      filter: ${({available}) => available? "invert(0)": "invert(0.7)"};
    }
  }

  .active {
    background-color: #000000;
    color: #fff;

    > img {
      filter: invert(1);
    }
  }

  @media screen and (${mediaQueries.tablet}) {
    width: 32%;
    height: 40px;

    > a {
      padding-left: 10px;
      font-size: 16px;
      line-height: 20px;

      > img {
        padding-right: 12px;
        height: 22px;
        width: 22px;
      }
    }
  }

  @media screen and (${mediaQueries.mobile}) {
    width: 32.5%;
    height: 28px;
    // border: 1px solid black;
    border: ${({available}) => available? "1px solid black": " 1px solid #cacaca"};

    > a {
      padding-left: 4px;
      font-size: 12px;
      line-height: 16px;

      > img {
        padding-right: 4px;
        height: 15px;
        width: 15px;
      }
    }
  }
`;
