import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
  errorContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20vh 0",
    fontFamily: "var(--primaryRegularFont)",
  },

  errorMsg: {
    paddingTop: "5vh",
    fontSize: "24px",
    lineHeight: "28px",
    fontWeight: "500",
    color: "#CACACA",
  },
});
