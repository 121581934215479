import styled from "styled-components";
import IconComponent from "../../../components/fields/icon";
import { modalTypes } from "../modal/constant";
import Button from "../../../components/fields/button";
import GlobalStyles from "../../../styles/global";

const { mediaQueries } = GlobalStyles;

const Overflow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 20;
`;

const ModalContainer = styled.div`
  position: absolute;
  width: 550px;
  min-height: 420px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  padding: 24px;
  display: flex;
  flex-direction: column;
  background: #ffffff;

  @media screen and (${mediaQueries.tablet}) {
    width: 450px;
    padding: 18px 24px;
  }

  @media screen and (${mediaQueries.mobile}) {
    width: 336px;
    min-height: 336px;

  }
`;

const HeaderSvg = styled.div`
  background: #f7f7f7;
  border-radius: 5px;
  height: 229px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;

  @media screen and (${mediaQueries.tablet}) {
    height: 150px;
    margin-bottom: 18px;
    > i {
      > svg {
        height: 120px;
      }
    }
  }

  @media screen and (${mediaQueries.mobile}) {
    height: 128px;
    > i {
      > svg {
        height: 95px;
      }
    }
  }
`;

const SvgContainer = styled.div`
  width: 100%;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CircleSvg = styled.div`
  background: #f7f7f7;
  height: 82px;
  width: 82px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  > i {
    > svg {
      height: 42px;
      width: 42px;
    }
  }
`;

const MainText = styled.div`
  font-family: var(--primaryRegularFont);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  justify-content: center;
  color: #000000;
  margin-bottom: 12px;

  @media screen and (${mediaQueries.tablet}) {
    font-size: 20px;
  }

  @media screen and (${mediaQueries.mobile}) {
    font-size: 18px;
  }
`;

const SubText = styled.div`
  font-family: var(--primaryRegularFont);
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.03em;
  color: #000000;
  padding-bottom: 40px;

  @media screen and (${mediaQueries.tablet}) {
    font-size: 14px;
    line-height: 20px;
  }

  @media screen and (${mediaQueries.mobile}) {
    font-size: 12px;
    line-height: 18px;
  }
`;

const ButtonContainter = styled.div`
  display: flex;
  column-gap: 24px;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: 12px;
`;

export default function Modal(props) {
    const { type, svgType="", handleButtonClick } = props;
    const modalType = modalTypes[type];

    function handleOutSideClick() {
      handleButtonClick(false)
    }

    return (
        <Overflow onClick={handleOutSideClick}>
          <ModalContainer onClick={(e) => e.stopPropagation()}>
            {modalType?.headerSvg && (
                svgType !== "circle"?
                <HeaderSvg>
                  <IconComponent iconTitle={modalType?.headerSvg} />
                </HeaderSvg>
                :
                <SvgContainer>
                  <CircleSvg>
                    <IconComponent iconTitle={modalType?.headerSvg} />
                  </CircleSvg>
                </SvgContainer>
            )}
            {modalType?.mainText ? <MainText>{modalType.mainText}</MainText> : ""}
            {modalType?.subText ? <SubText>{modalType.subText}</SubText> : ""}
            <ButtonContainter>
                <Button buttontype="primary" size="default" onClick={() => handleButtonClick(true)}>
                  {modalType?.acceptButton}
                </Button>
                <Button buttontype="secondary" size="default" onClick={() => handleButtonClick(false)}>
                  {modalType?.rejectButton}
                </Button>
            </ButtonContainter>
          </ModalContainer>
        </Overflow>
    )
}