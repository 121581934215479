import { gql } from "@apollo/client";

export const addFavorites = gql`
  mutation AddFavorites($favoriteData: Favorite!) {
    addFavorites(favoriteData: $favoriteData) {
      statusCode
      message
      error
      data
    }
  }
`;

export const getFavoritesByItemIdOrCurationId = gql`
  query GetFavoritesByItemIdOrCurationId {
    getFavoritesByItemIdOrCurationId {
      data
      error
      message
      statusCode
    }
  }
`;
export const removeFavorites = gql`
  mutation RemoveFavorites($favoriteData: FavoriteData!) {
    removeFavorites(favoriteData: $favoriteData) {
      statusCode
      message
      error
      data
    }
  }
`;
export const getFavoritesByCategoryType = gql`
  query GetFavoritesByCategoryType($favoritesFilterData: FavoritesFilterData!) {
    getFavoritesByCategoryType(favoritesFilterData: $favoritesFilterData) {
      data
      error
      message
      statusCode
    }
  }
`;
