import { useState } from "react";
import {
  ButtonContainter,
  CircleSvg,
  MainText,
  ModalContainer,
  Overflow,
  SvgContainer,
} from "../../../components/modal";
import Button from "../../../components/fields/button";
import IconComponent from "../../../components/fields/icon";
import { InputField } from "../../../components/signin";
import { validateEmail } from "../../../components/helpers/validate";
// import { useStyles } from "./styles";

export default function AddEmailPopup({ setShowPopup, addNewUserEmail }) {
  //   const classes = useStyles();
  const [userEmail, setUserEmail] = useState("");

  return (
    <Overflow>
      <ModalContainer style={{ minHeight: "unset" }}>
        <SvgContainer>
          <CircleSvg>
            <IconComponent iconTitle="Sms" />
          </CircleSvg>
        </SvgContainer>
        <MainText style={{ marginBottom: "16px" }}>{"Add Another Email"}</MainText>
        <InputField
          labelName="Email"
          name="email"
          value={userEmail}
          onChange={({ target: { value } }) => setUserEmail(value)}
          type="email"
        />
        <ButtonContainter>
          <Button
            buttontype="primary"
            size="default"
            disabled={!validateEmail(userEmail)?.length}
            onClick={() => {
              setShowPopup(false);
              addNewUserEmail(userEmail);
            }}
          >
            {"Save"}
          </Button>
          <Button buttontype="secondary" size="default" onClick={() => setShowPopup(false)}>
            {"Cancel"}
          </Button>
        </ButtonContainter>
      </ModalContainer>
    </Overflow>
  );
}
