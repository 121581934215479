import {
  ButtonContainter,
  CircleSvg,
  MainText,
  ModalContainer,
  Overflow,
  SubText,
  SvgContainer,
} from "../modal";
import Button from "../fields/button";
import IconComponent from "../fields/icon";

export default function DndConfirm(props) {
  const { modalResponse, field, newValue } = props;

  return (
    <Overflow>
      <ModalContainer style={{ minHeight: "unset" }}>
        <SvgContainer>
          <CircleSvg>
            <IconComponent iconTitle={"EditLigthIcon"} />
          </CircleSvg>
        </SvgContainer>
        <MainText>{"Edit"}</MainText>
        <SubText>{`Would you like to modify the ${field} to “${newValue}”?`}</SubText>
        <ButtonContainter style={{ paddingTop: "14px" }}>
          <Button buttontype="primary" size="default" onClick={() => modalResponse(true)}>
            {"Save"}
          </Button>
          <Button buttontype="secondary" size="default" onClick={() => modalResponse(false)}>
            {"Cancel"}
          </Button>
        </ButtonContainter>
      </ModalContainer>
    </Overflow>
  );
}
