import styled from "styled-components";
import Icon from "./icon";
import globalStyle from "../../styles/global";

const { mediaQueries } = globalStyle;

const InputContainer = styled.div`
  // font-family: var(--primaryRegularFont);
  width: 100%;
  > p {
    font-family :var(--secondaryHeaderFont);
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.03em;
    margin-bottom: 8px;
    user-select: none;
    > span {
      color: red;
      font-weight: bold;
    }
  }

  .errorText {
    display: flex;
    align-items: flex-end;
    user-select: none;

    > p {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.03em;
      color: #ff0000;
      margin-top: 12px;
      margin-left: 12px;
    }

    @media screen and (${mediaQueries.mobile}) {
      > p {
        font-size: 12px;
        margin-top: 8px;
      }
    }
  }
`;

const Field = styled.div`
  width: 100%;
  height: 54px;
  border: 1px solid ${({ error }) => (error ? "#ff0000" : "#000000")};
  box-sizing: border-box;
  padding: 5px 18px;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 10px;

  > input {
    font-family: var(--secondaryRegularFont);
    // font-family: inherit;
    outline: none;
    background: inherit;
    border: none;
    width: 100%;
    height: 100%;
    padding: 0;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.03em;
    color: #000000;
    text-overflow: ellipsis;
  }

  > input[type="password"]::-ms-reveal,
  input[type="password"]::-ms-clear {
    display: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px inset #ffffff !important;
    box-shadow: 0 0 0 30px inset #ffffff !important;
  }

  input[type="password"]::-webkit-contacts-auto-fill-button,
  input[type="password"]::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    height: 0;
    width: 0;
    margin: 0;
  }
`;

export default function Input({
  className,
  labelName,
  required,
  name,
  placeholder = "",
  onChange,
  onKeyDown,
  id,
  value = "",
  icon,
  type = "text",
  onIconClick,
  errorText = "",
  maxLength,
  disabled,
  inputRef = null
}) {
  return (
    <InputContainer className={className}>
      {labelName && <p>{labelName}: {required && <span>*</span>}</p>}
      <Field error={!!errorText}>
        <input
          disabled={disabled}
          name={name}
          onChange={onChange}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          id={id}
          value={value}
          type={type}
          maxLength={maxLength}
          ref={inputRef}
        />
        {icon && <Icon onClick={onIconClick} iconTitle={icon} />}
      </Field>
      {!!errorText && (
        <div className="errorText">
          <Icon iconTitle={"ErrorBoldIcon"} />
          <p>{errorText}</p>
        </div>
      )}
    </InputContainer>
  );
}
