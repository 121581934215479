import { useStyles } from "./styles";
import IconComponent from "../../../components/fields/icon";
import { useNavigate } from "react-router-dom";
export default function EmptyCloset() {
  const classes = useStyles();
  const navigate = useNavigate();

  function handleAddItemButton() {
    navigate("/addyourstyle/scanemail");
  }

  return (
    <div className={classes.itemsContainer}>
      <IconComponent iconTitle={"NoItemIcon"} />
      <div className={classes.errorMsg}>Sorry! No Items Found Here</div>
      <div className={classes.addItemButton} onClick={handleAddItemButton}>
        <span>+</span> <span>Add Item</span>
      </div>
    </div>
  );
}
