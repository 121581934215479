import { makeStyles } from "@material-ui/styles";
import { mobileMediaStyle, tabMediaStyle } from "../../../components/helpers/ResponsiveStyleHelper";

export const useStyles = makeStyles({
  notificationCategoryHeader: {
    height: "100px",
    width: "100%",
    borderBottom: "1px solid #D9D9D9",
    boxSizing: "border-box",
  },
  notificationHeading: {
    fontSize: "20px",
    fontWeight: "600",
    marginTop: 0,
    letterSpacing: "0.6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  notificationContainer: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "40px",
    gap: "30px",
  },
  notificationDescription: {
    fontFamily: "var(--secondaryRegularFont)",
    fontSize: "17px",
    fontWeight: "300",
    paddingTop: "15px",
    lineHeight: "20px",
    letterSpacing: "0.48px",
  },

  ...tabMediaStyle({
    notificationCategoryHeader: {
      height: "75px",
    },
    notificationContainer: {
      paddingTop: "30px",
    },
  }),

  ...mobileMediaStyle({
    notificationCategoryHeader: {
      display: "none",
    },
    notificationContainer: {
      padding: "12px 0",
      margin: "0 16px",
      gap: "16px",
    },
    notificationHeading: {
      fontSize: "16px",
      letterSpacing: "0.42px",
    },
    notificationDescription: {
      fontSize: "14px",
      paddingTop: "10px",
      lineHeight: "20px",
      letterSpacing: "0.36px",
    },
  },"@media (max-width:600px)"),
});
