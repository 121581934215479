import FaqPage from "./FaqPage.jsx";
// import { useStyles } from "./styles.js";

export default function Faq() {
  //   const classes = useStyles();

  return (
    <>
      <FaqPage />
    </>
  );
}
