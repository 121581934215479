import { useQuery } from "@apollo/client";
import { getCurationsList } from "../apollo/operations/curation";
import { useMemo } from "react";

export default () => {
  const { data, error, loading, refetch } = useQuery(getCurationsList);

  const ListOfCuration = useMemo(() => data?.getCurationsList?.data, [loading]);

  return {
    ListOfCuration,
    error,
    loading,
    refetch,
  };
};
