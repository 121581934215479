import IconComponent from "../../../components/fields/icon";
import { List } from "../../../containers/StyleMe";

export default function ClothesTag({ clothesList, removeCurationItem, parentIndex, classes }) {
  const normalCloths = clothesList.filter(
    (obj) => !["bottomWear", "outerwear"].includes(obj?.categoryGroup?.toLowerCase()),
  );
  const outerwear = clothesList.filter((item) => item?.mainCategory?.toLowerCase() === "outerwear");
  const pants = clothesList.filter((item) => item?.mainCategory?.toLowerCase() === "bottomWear");

  const GroupList = normalCloths.concat(outerwear, pants);

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "auto auto",
        placeContent: "center",
        placeItems: "center",
        gridRow: "1/span 3",
        gridRowGap: "10px",
        gridAutoFlow: "dense",
      }}
    >
      {GroupList?.map((item, itemIndex) => (
        <List
          key={`index-${itemIndex}`}
          data-category={item?.category}
          data-maincategory={item?.mainCategory}
          style={
            ["skirts", "shorts", "pants", "denim"].includes(item?.mainCategory?.toLowerCase())
              ? { gridRowStart: 2 }
              : {}
          }
        >
          <IconComponent
            style={{ right: "unset" }}
            iconTitle="CrossIcon"
            className={classes.trash}
            onClick={(event) => {
              event.stopPropagation();
              removeCurationItem(item?.id, parentIndex);
            }}
          />
          <img
            alt=""
            style={{
              maxWidth: "150px",
              maxHeight: item?.categoryGroup === "completeWear" ? "350px" : "150px",
              minWidth: item?.categoryGroup === "completeWear" ? "100px" : "120px",
              minHeight: item?.categoryGroup === "completeWear" ? "350px" : "120px",
              objectFit: "contain",
            }}
            src={item?.src}
            key={`src-${itemIndex}`}
          />
        </List>
      ))}
    </div>
  );
}
