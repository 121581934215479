import { makeStyles } from "@material-ui/styles";
import { mobileMediaStyle, tabMediaStyle } from "../helpers/ResponsiveStyleHelper";

export const useStyles = makeStyles({
  list: {
    paddingBottom: "0px !important",
  },
  isHiddenItem: {
    justifyContent: "center",
    display: "flex",
    position: "sticky",
    bottom: "0px",
    height: "25px",
    borderRadius: "10px 10px 0 0",
    background:"white"
  },
  ...tabMediaStyle({}),

  ...mobileMediaStyle(
    {
      isHiddenItem: {},
    },
    "@media (max-width: 600px)",
  ),
});
