import { useState } from "react";
import Avatar from "react-avatar-edit";
import { useStyles } from "./styles";
import Button from "../fields/button";
import { ReactComponent as UploadImageIcon} from "../../assets/svg/uploadImageIcon.svg";

export default function AvatarSelector(props) {
    const { setImage, setRowImage, setOpenAvatarSelector } = props;
    const [imageData, setImageData] = useState("");
    const classes = useStyles();
    const [preview, setPreview] = useState("");

    function handleCrop(pre) {
        setPreview(pre);
    }
    
    function handleEditClose() {
        setPreview("");
        setOpenAvatarSelector(false);
    }

    function handleButtonClick(value) {
        if(value && preview){
            setImage(preview)
            setRowImage(imageData);
        }
        setOpenAvatarSelector(false)
    }

    function onBeforeFileLoadHandler(elem) {
        setImageData(elem?.target?.files[0]);
    }

    return (
        <div className={classes.overflow}>
          <div className={classes.modalContainer}>
            <div className={classes.modalTitle}>Upload Profile Picture</div>
            <div className={classes.avatarContainer}>
                <Avatar
                  imageWidth={280}
                  width={400}
                  height={350}
                  onBeforeFileLoad={onBeforeFileLoadHandler}
                  onCrop={handleCrop}
                  onClose={handleEditClose}
                  labelStyle={{ width: "100%" }}
                  label={
                    <div className={classes.LabelIconContainer}>
                      <UploadImageIcon className={classes.uploadIcon} />
                    </div>
                  }
                />
                {
                preview &&
                    <div className={classes.avatarPreview}>
                        <div className={classes.previewTitle}>Preview</div>
                        <div className={classes.previewWrapper}>
                            <img className={classes.preview} src={preview} alt="preview"/>
                        </div>
                    </div> 
                }
            </div>
            <div className={classes.buttonContainer}>
                <Button buttontype="secondary" size="default" onClick={() => handleButtonClick(false)}>
                    {"Cancel"}
                </Button>
                <Button buttontype="primary" size="default" onClick={() => handleButtonClick(true)}>
                    {"Okay"}
                </Button>
            </div>
          </div>
        </div>
    )
}