import { useStyles } from "./styles"
import hangerGIF from "../../assets/gif/loadingHanger.gif";

export default function Loader() {
  const classes = useStyles()
  return (
      <div className={classes.container}>
        <div className={classes.loader}>
          <img src={hangerGIF} />
        </div>
      </div>
  )
}