import { memo, useEffect } from "react";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import Modal from "../../../containers/common/modalPortal/modal";
import { useStyles } from "./styles";
import IconComponent from "../../../components/fields/icon";
import { ReactComponent as SelectAll } from "../../../assets/svg/selectAll.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/svg/deleteIcon.svg";
import { ReactComponent as SelectEmpty } from "../../../assets/svg/selectEmpty.svg";

function TableMails(props) {
  const { mailsArray, isDeleteModal, isASC, selectedOptions, isSort } = props;
  const { handleSorting, handleReciptsId, handleCheckboxChange, reset, onModalButtonsClick } =
    props;
  const { handleAllCheckBoxChange, handleDeleteClick } = props;
  const classes = useStyles();
  const options = {
    month: "numeric",
    day: "2-digit",
    year: "numeric",
  };

  useEffect;

  const sortArrowIcon = (isActive) => (
    <div className={classes.arrowSort} style={{ opacity: isActive ? 1 : 0 }}>
      <IconComponent
        iconTitle={isASC ? "UpArrow" : "DownArrow"}
        onClick={() => {
          handleSorting(null);
        }}
      />
    </div>
  );

  const formatDate = (date) => {
    date = date.split("T");
    date = date[0].split("-");
    return `${date[1]}/${date[2]}/${date[0]}`;
  };

  return (
    <div className={classes.table}>
      <div className={classes.headerRows}>
        {/* <div >From</div> */}
        <div
          onClick={() => {
            reset("isStoreName");
          }}
          style={{ display: "flex", cursor: "pointer" }}
        >
          <div>Store Name </div>
          {sortArrowIcon(isSort?.isStoreName)}
        </div>
        <div
          onClick={() => {
            reset("isSubject");
          }}
          style={{ display: "flex", cursor: "pointer" }}
        >
          <div>Subject</div> {sortArrowIcon(isSort?.isSubject)}
        </div>
        <div className={classes.dateWrapper}>
          <div
            onClick={() => {
              reset("isDate");
            }}
            className={`${classes.dateHeader}`}
          >
            {" "}
            Date{sortArrowIcon(isSort?.isDate)}
          </div>
          <div className={classes.deleteAll}>
            <div className={`${classes.deleteIcon}`} onClick={handleAllCheckBoxChange}>
              <SelectAll
                className={selectedOptions.length ? classes.selectAllBlack : classes.selectAll}
              />
            </div>
            <div className={`${classes.deleteIcon} `} onClick={handleDeleteClick}>
              <DeleteIcon
                className={!selectedOptions.length ? classes.deleteBlackIcon : undefined}
              />
            </div>
          </div>
        </div>

        {/* <div className={`${classes.deleteIcon} ${classes.hideIcon}`}>
        <IconComponent iconTitle={"Trash"} />
       </div> */}
        {/* <div className={`${classes.deleteIcon} ${classes.hideIcon}`}>
          <RadioButtonUncheckedOutlinedIcon className={classes.radioButton} />
        </div> */}
        {/* <div /> */}
      </div>

      {mailsArray.map((item) => (
        <div key={item.id} className={classes.rows}>
          {/* <div className={classes.fromMail}>{item.storeEmail}</div> */}
          <div className={classes.senderName} onClick={() => handleReciptsId(item.messageId)}>
            {item.storeName}
          </div>
          <div className={classes.subject} onClick={() => handleReciptsId(item.messageId)}>
            {item.subject}
          </div>

          {/* <div className={classes.fromMail}>{item.storeName}</div> */}
          <div className={classes.dateWrapper}>
            <div className={classes.mailDate} onClick={() => handleReciptsId(item.messageId)}>
              {/* {new Date(parseInt(item.createdAt)).toLocaleString("en-IN", options)} */}
              {formatDate(item.createdAt)}
            </div>
            <div className={`${classes.selectIcon}`} onClick={() => handleCheckboxChange(item.id)}>
              {selectedOptions.includes(item.id) ? (
                <SelectAll className={classes.selectAllBlack} />
              ) : (
                <SelectEmpty className={classes.selectAll} />
              )}
              {/* <SelectAll
                className={
                  selectedOptions.includes(item.id) ? classes.selectAllBlack : classes.selectAll
                }
              /> */}
              {/* <SelectEmpty /> */}
            </div>
          </div>

          {/* delete icon here */}
          {/* <div className={classes.deleteIcon}>
          <IconComponent iconTitle={"Trash"} />
        </div> */}
          {/* <div onClick={() => handleCheckboxChange(item.id)} className={classes.deleteIcon}>
            {selectedOptions.includes(item.id) ? (
              <RadioButtonCheckedOutlinedIcon className={classes.radioButton} />
            ) : (
              <RadioButtonUncheckedOutlinedIcon className={classes.radioButton} />
            )}
          </div> */}
        </div>
      ))}
      {isDeleteModal && (
        <Modal type={"deleteReceipts"} handleButtonClick={onModalButtonsClick} svgType="circle" />
      )}
    </div>
  );
}

export default memo(TableMails);
