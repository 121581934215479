import AddCurations from "./AddCurations";
import { useStyles } from "./styles";
import SelectedItems from "./SelectedItems/selectedItems";
import CurationsContainer from "./Curations/Curations";
import Inspired from "./Inspired/index";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "./pagination";
import Modal from "../../containers/common/modalPortal/modal";
import ModalPortal from "../../containers/common/modalPortal";
import { createRef, useEffect, useMemo, useState } from "react";

import {
  addCurations,
  getCurationsList,
  updateCuration,
  removeCuration,
  getRandomInspired,
} from "../../apollo/operations/curation";
import { useMutation, useLazyQuery } from "@apollo/client";
import { openToast } from "../../components/toast";
import Slider from "react-slick";
import AddItemToCuration from "./AddItemToCuration";
import CategoriesDropDown from "../../components/categoriesDropDown";
import { getItemsFromCloset } from "../../apollo/operations/items";
import { addStyleMe } from "../../apollo/operations/styleme";

import {
  addFavorites,
  getFavoritesByItemIdOrCurationId,
  removeFavorites,
} from "../../apollo/operations/favorite";
import {
  setCuration,
  clearCuration,
  deleteCuration,
  setFlags,
  setStyleMeCuration,
  setBulkCuration,
} from "../../store/curationSlice";
import { getChildCategories, getMainCategory } from "../../components/helpers/categoryHelper";
import IconComponent from "../../components/fields/icon";
import {
  Clear,
  ContainerHeader,
  ContainerList,
  Curations,
  Header,
  InspiredContainer,
  ItemContainer,
  Label,
  MainContainer,
} from "../../containers/StyleMe";
import { clearStyleMe } from "../../store/socketSlice";
import Loader from "../../components/loaderScreen";
import useGetStyleMe from "../../hooks/useGetStyleMe";

export default function StyleMe() {
  const { SelectedList, loading: styleMeloading } = useGetStyleMe();

  const sliderRef = createRef();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const open = Boolean(anchorEl);
  const [curationsData, setCurationsData] = useState([]);

  const [inspiredData, setInspiredData] = useState([]);
  const [inspiredList, setInspiredList] = useState([]);
  const styleMeData = useSelector((state) => state.socket.styleMe);
  const styleMeCuration = useSelector((state) => state.curation.styleMeCuration);

  const [curationIndex, setCurationIndex] = useState(null);

  const curationsList = useSelector((state) => state.curation.curation);
  const curationsFlags = useSelector((state) => state.curation.flags);

  const [addCurationsPopup, setAddCurationsPopup] = useState(false);
  const [outFitsDetails, setOutFitsDetails] = useState({});
  const [showCategoryPopup, setShowCategoryPopup] = useState(false);
  const [curation, { data: addCurationData, loading: curationLoading }] = useMutation(addCurations);
  const [getCuration, { data: getCurationListData }] = useLazyQuery(getCurationsList);
  const [getRandomInspiredRequest, { data: getRandomInspiredData, loading }] =
    useLazyQuery(getRandomInspired);
  const [addFavorite, { data: addFavoriteData, loading: addFavoriteLoading }] =
    useMutation(addFavorites);
  const [getFavorite, { data: favoriteDataList, loading: getFavoriteLoading }] = useLazyQuery(
    getFavoritesByItemIdOrCurationId,
  );
  const [UpdateCuration, { data: updateCurationData, loading: UpdateCurationLoading }] =
    useMutation(updateCuration);
  const [deleteCurationList] = useMutation(removeCuration);
  const [deleteFavorite] = useMutation(removeFavorites);
  const newCategoryData = {
    topWear: [],
    bottomWear: [],
    completeWear: [],
  };
  const dispatch = useDispatch();
  const [getItems, { data: ItemsList }] = useLazyQuery(getItemsFromCloset);
  const [categoryName, setCategoryName] = useState("");
  const [mainCategoryName, setMainCategoryName] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const categoriesList = useSelector((state) => state.category.category);
  const [productsList, setProductsList] = useState([]);
  const [allCurationData, setAllCurationData] = useState([]);
  const [savedGetInpired, setSavedGetInspired] = useState([]);
  const [updateStyleMeDB, { data: updateStyleMeDBData, loading: updateStyleMeDBLoading }] =
    useMutation(addStyleMe);
  const [updateStyleMeFlag, setUpdateStyleMeFlag] = useState(false);
  const [slide, setSlide] = useState(0);

  let draggedItem = {};

  const linearCategories = useMemo(() => {
    let linearSubCategories = {};
    let mainCategory = "";
    categoriesList?.forEach((subCat) => {
      mainCategory = subCat.name;
      recursion(subCat);
    });

    function recursion(subCat) {
      linearSubCategories[subCat.name] = mainCategory;
      if (subCat.subCategories) {
        subCat.subCategories.forEach((subCategory) => {
          recursion(subCategory);
        });
      }
    }

    return linearSubCategories;
  }, [categoriesList]);

  useEffect(() => {
    linearCategories;
  }, [categoriesList]);

  useEffect(() => {
    if (curationsData.length) {
      styleMeCuration.forEach((item) => {
        curationsData.splice(Object.keys(item), 1, item[Object.keys(item)]);
      });
    }
  }, [curationsData]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(clearCuration());
    dispatch(setBulkCuration(styleMeData?.SelectedList));
  }, [styleMeData]);

  useEffect(() => {
    // if (SelectedList.length) {
    dispatch(clearCuration());
    SelectedList.forEach((item) => {
      if (item.src) {
        dispatch(
          setCuration({
            ...item,
            mainCategory: getMainCategory(
              categoriesList?.length ? categoriesList : [],
              item?.category,
            ),
          }),
        );
      }
    });
    // setCurationsData(CurationDataList);
    // }
  }, [styleMeloading]);

  function updateToStyleDB(items, curation = []) {
    //send to db when customize curation
    updateStyleMeDB({
      variables: {
        styleMeData: {
          selectList: structuredClone(items.map((item) => `${item.id}`)),
          curationList: [],
        },
      },
    });
  }

  function addCategoryGroup(key, arr) {
    return arr.map((item) => ({
      ...item,
      categoryGroup: key,
    }));
  }

  const [settings, setSettings] = useState({
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToScroll: 4,
    adaptiveHeight: true,
    arrows: true,
    variableWidth: true,
    className: classes.sliderContainer,
    // dotsClass: `slick-dots ${classes.dots}`,
  });

  function getCurationData() {
    const categoryData = {};
    const uniqueCategory = [];
    let completeWear = [];

    let validCurationList = structuredClone(curationsList?.length ? curationsList : SelectedList);
    validCurationList?.forEach((item) => {
      item.mainCategory = linearCategories[item.category];
      if (item.categoryGroup === "undefined") {
        if (["skirts", "shorts", "pants", "denim"].includes(item?.mainCategory?.toLowerCase())) {
          item.categoryGroup = "bottomWear";
        } else if (["tops", "shirts"].includes(item?.mainCategory?.toLowerCase())) {
          item.categoryGroup = "topWear";
        } else if (
          [
            "dresses",
            "loungewear",
            "beachwear",
            "activewear",
            "miscellaneous",
            "jumpsuit",
            "suits",
          ].includes(item?.mainCategory?.toLowerCase())
        ) {
          item.categoryGroup = "completeWear";
        } else {
          if (["beauty", "accessories"].includes(item?.mainCategory?.toLowerCase())) {
            item.categoryGroup = item.category;
          } else {
            item.categoryGroup = item.mainCategory;
          }
        }
      }
    });

    for (const item of validCurationList) {
      if (["beauty", "accessories"].includes(item?.mainCategory?.toLowerCase())) {
        if (!uniqueCategory.length) {
          uniqueCategory.push(item?.category?.toLowerCase());
        } else if (!uniqueCategory.includes(item?.category?.toLowerCase())) {
          uniqueCategory.push(item?.category?.toLowerCase());
        }
      } else {
        if (!uniqueCategory.length) {
          uniqueCategory.push(item?.mainCategory?.toLowerCase());
        } else if (!uniqueCategory.includes(item?.mainCategory?.toLowerCase())) {
          uniqueCategory.push(item?.mainCategory?.toLowerCase());
        }
      }
    }

    for (const item of validCurationList) {
      if (uniqueCategory.includes(item?.category?.toLowerCase())) {
        if (Object.prototype.hasOwnProperty.call(categoryData, item?.category?.toLowerCase())) {
          categoryData[item?.category?.toLowerCase()].push(item);
        } else {
          categoryData[item?.category?.toLowerCase()] = [item];
        }
      } else if (uniqueCategory.includes(item?.mainCategory?.toLowerCase())) {
        if (Object.prototype.hasOwnProperty.call(categoryData, item?.mainCategory?.toLowerCase())) {
          categoryData[item?.mainCategory?.toLowerCase()].push(item);
        } else {
          categoryData[item?.mainCategory?.toLowerCase()] = [item];
        }
      }
    }

    for (const key in categoryData) {
      if (Object.hasOwnProperty.call(categoryData, key)) {
        let element = categoryData[key];

        if (["skirts", "shorts", "pants", "denim"].includes(key?.toLowerCase())) {
          if (Object.hasOwnProperty.call(newCategoryData, key)) {
            let elementData = addCategoryGroup("bottomWear", element);
            newCategoryData.bottomWear.push(...elementData);
          } else {
            // newCategoryData.bottomWear = [];
            let elementData = addCategoryGroup("bottomWear", element);
            newCategoryData.bottomWear.push(...elementData);
          }
        } else if (["shirts", "tops"].includes(key?.toLowerCase())) {
          if (Object.hasOwnProperty.call(newCategoryData, key)) {
            let elementData = addCategoryGroup("topWear", element);
            newCategoryData.topWear.push(...elementData);
          } else {
            // newCategoryData.topWear = [];
            let elementData = addCategoryGroup("topWear", element);
            newCategoryData.topWear.push(...elementData);
          }
        } else if (
          [
            "dresses",
            "loungewear",
            "beachwear",
            "activewear",
            "miscellaneous",
            "jumpsuit",
            "suits",
          ].includes(key?.toLowerCase())
        ) {
          if (Object.hasOwnProperty.call(newCategoryData, key)) {
            let elementData = addCategoryGroup("completeWear", element);
            newCategoryData.completeWear.push(...elementData);
          } else {
            // newCategoryData.completeWear = [];
            let elementData = addCategoryGroup("completeWear", element);
            newCategoryData.completeWear.push(...elementData);
            completeWear.push(...elementData);
          }
        } else {
          if (Object.hasOwnProperty.call(newCategoryData, key)) {
            let elementData = addCategoryGroup(key, element);
            newCategoryData[key].push(...elementData);
          } else {
            newCategoryData[key] = [];
            let elementData = addCategoryGroup(key, element);
            newCategoryData[key].push(...elementData);
          }
        }
      }
    }

    newCategoryData.completeWear = completeWear;

    return newCategoryData;
  }

  function getInspiredData(curationsList = []) {
    const categoryData = {};
    const uniqueCategory = [];
    let newCategoryData = {};
    for (const item of curationsList) {
      if (["beauty", "accessories"].includes(item?.mainCategory?.toLowerCase())) {
        if (!uniqueCategory.length) {
          uniqueCategory.push(item?.category?.toLowerCase());
        } else if (!uniqueCategory.includes(item?.category?.toLowerCase())) {
          uniqueCategory.push(item?.category?.toLowerCase());
        }
      } else {
        if (!uniqueCategory.length) {
          uniqueCategory.push(item?.mainCategory?.toLowerCase());
        } else if (!uniqueCategory.includes(item?.mainCategory?.toLowerCase())) {
          uniqueCategory.push(item?.mainCategory?.toLowerCase());
        }
      }
    }

    for (const item of curationsList) {
      if (uniqueCategory.includes(item?.category?.toLowerCase())) {
        if (Object.prototype.hasOwnProperty.call(categoryData, item?.category?.toLowerCase())) {
          categoryData[item?.category?.toLowerCase()].push(item);
        } else {
          categoryData[item?.category?.toLowerCase()] = [item];
        }
      } else if (uniqueCategory.includes(item?.mainCategory?.toLowerCase())) {
        if (Object.prototype.hasOwnProperty.call(categoryData, item?.mainCategory?.toLowerCase())) {
          categoryData[item?.mainCategory?.toLowerCase()].push(item);
        } else {
          categoryData[item?.mainCategory?.toLowerCase()] = [item];
        }
      }
    }

    for (const key in categoryData) {
      if (Object.hasOwnProperty.call(categoryData, key)) {
        let element = categoryData[key];

        if (["skirts", "shorts", "pants", "denim"].includes(key?.toLowerCase())) {
          if (Object.hasOwnProperty.call(newCategoryData, key)) {
            let elementData = addCategoryGroup("bottomWear", element);
            newCategoryData.bottomWear.push(...elementData);
          } else {
            // newCategoryData.bottomWear = [];
            let elementData = addCategoryGroup("bottomWear", element);
            newCategoryData.bottomWear.push(...elementData);
          }
        } else if (["shirts", "tops"].includes(key?.toLowerCase())) {
          if (Object.hasOwnProperty.call(newCategoryData, key)) {
            let elementData = addCategoryGroup("topWear", element);
            newCategoryData.topWear.push(...elementData);
          } else {
            // newCategoryData.topWear = [];
            let elementData = addCategoryGroup("topWear", element);
            newCategoryData.topWear.push(...elementData);
          }
        } else if (
          [
            "dresses",
            "loungewear",
            "beachwear",
            "activewear",
            "miscellaneous",
            "jumpsuit",
            "suits",
          ].includes(key?.toLowerCase())
        ) {
          if (Object.hasOwnProperty.call(newCategoryData, key)) {
            let elementData = addCategoryGroup("completeWear", element);
            newCategoryData.completeWear.push(...elementData);
          } else {
            // newCategoryData.completeWear = [];
            let elementData = addCategoryGroup("completeWear", element);
            newCategoryData.completeWear.push(...elementData);
          }
        } else {
          if (Object.hasOwnProperty.call(newCategoryData, key)) {
            let elementData = addCategoryGroup(key, element);
            newCategoryData[key].push(...elementData);
          } else {
            newCategoryData[key] = [];
            let elementData = addCategoryGroup(key, element);
            newCategoryData[key].push(...elementData);
          }
        }
      }
    }
    return newCategoryData;
  }

  function makeCurationCategory(newCategoryData) {
    let obj1 = {};
    let obj2 = {};
    for (const key in newCategoryData) {
      if (Object.hasOwnProperty.call(newCategoryData, key)) {
        const element = newCategoryData[key];
        if (!["completewear"].includes(key.toLowerCase()) && element.length > 0) {
          obj1[key] = [...element];
        }
      }
    }

    for (const key in newCategoryData) {
      if (Object.hasOwnProperty.call(newCategoryData, key)) {
        const element = newCategoryData[key];
        if (
          !["bottomwear", "topwear", "sweaters"].includes(key.toLowerCase()) &&
          element.length > 0
        ) {
          obj2[key] = [...element];
        }
      }
    }
    // below if code was to remove identical curations
    if (
      Object.keys(obj1).length >= Object.keys(obj2).length &&
      Object.keys(obj2).every((value) => Object.keys(obj1).includes(value))
    ) {
      obj2 = {};
    } else if (
      Object.keys(obj2).length >= Object.keys(obj1).length &&
      Object.keys(obj1).every((value) => Object.keys(obj2).includes(value))
    ) {
      obj1 = {};
    }

    return [obj1, obj2];
  }

  function combineArrays(arrays) {
    const result = [];

    function combine(current, index) {
      if (index === arrays.length) {
        result.push(current);
        return;
      }

      for (let i = 0; i < arrays[index].length; i++) {
        const obj = arrays[index][i];
        combine([...current, obj], index + 1);
      }
    }

    combine([], 0);
    return result;
  }

  function showAddCurationsPopup(value, index) {
    if (value) {
      setCurationIndex(index);
      setAddCurationsPopup(true);
    } else {
      setAddCurationsPopup(false);
    }
  }
  useEffect(() => {
    if (addCurationData && addCurationData.addCurations) {
      if (addCurationData.addCurations?.statusCode === "200") {
        if (outFitsDetails.isLiked) {
          addFavorite({
            variables: {
              favoriteData: {
                curationId: addCurationData.addCurations.data.id.toString(),
                isLiked: true,
              },
            },
          });
          setCurationId(addCurationData.addCurations.data.id.toString());
        } else {
          openToast(
            "success",
            "Successful!",
            `Curation added ${
              addCurationData.addCurations.data?.curationName
                ? `as ${addCurationData.addCurations.data?.curationName}`
                : ""
            }`,
          );
        }
        setAddCurationsPopup(false);
      } else {
        openToast("error", "Failed!", "Try Again");
        handleCurationType("");
      }
    }
  }, [addCurationData]);

  useEffect(() => {
    if (addFavoriteData && addFavoriteData.addFavorites?.statusCode === "200") {
      openToast(
        "success",
        "Successful!",
        `Curation added to favorite  ${
          addCurationData.addCurations.data?.curationName
            ? `as ${addCurationData.addCurations.data?.curationName}`
            : ""
        }`,
      );
    }
  }, [addFavoriteData]);

  function addCurationsDetails(value) {
    if (value) {
      curation({
        variables: {
          curationData: {
            ...value,
          },
        },
      });
      if (outFitsDetails.isLiked) {
        curationsData[outFitsDetails.index].isAdded = false;
        curationsData[outFitsDetails.index].isLiked = true;
      } else {
        curationsData[outFitsDetails.index].isAdded = true;
        curationsData[outFitsDetails.index].isLiked = false;
      }
      setCurationsData([...curationsData]);
    }
  }

  useEffect(() => {
    getCuration({ variables: {} });
  }, [addCurationData, addFavoriteData, updateCurationData]);

  useEffect(() => {
    getRandomInspiredRequest({ variables: {} });
  }, []);

  useEffect(() => {
    getFavorite({ variables: {} });
  }, [addFavoriteData]);

  function addCurationToFavorite(favoriteDetails) {
    curationsData[favoriteDetails.index].isLiked = favoriteDetails.isLiked;
    setCurationsData([...curationsData]);
    if (
      getCurationListData?.getCurationsList?.data &&
      getCurationListData?.getCurationsList?.data?.length > 0
    ) {
      let favoriteData = getCurationListData?.getCurationsList?.data;
      let curationId;
      favoriteData.forEach((item) => {
        let data = 0;
        if (item.curation.length === favoriteDetails.curation.length) {
          item.curation.forEach((i) => {
            if (favoriteDetails.curation.includes(i)) data++;
          });
        }
        if (data === favoriteDetails.curation.length) {
          curationId = item.id;
        }
      });
      if (curationId) {
        addFavorite({
          variables: {
            favoriteData: {
              curationId: curationId.toString(),
              isLiked: true,
            },
          },
        });
        UpdateCuration({
          variables: {
            curationData: {
              curationId: curationId.toString(),
              isLiked: true,
            },
          },
        });
      } else {
        setAddCurationsPopup(true);
      }
    }
  }

  function addOutfits(outfitData) {
    setOutFitsDetails(outfitData);
  }

  const onDragStartItem = (e) => {
    if (e >= 0) {
      if (
        ["skirts", "shorts", "pants", "denim"].includes(
          curationsList[e]?.mainCategory?.toLowerCase(),
        )
      ) {
        draggedItem = { categoryGroup: "bottomWear", ...curationsList[e] };
      } else if (["tops", "shirts"].includes(curationsList[e]?.mainCategory?.toLowerCase())) {
        draggedItem = { categoryGroup: "topWear", ...curationsList[e] };
      } else if (
        [
          "dresses",
          "loungewear",
          "beachwear",
          "activewear",
          "miscellaneous",
          "jumpsuit",
          "suits",
        ].includes(curationsList[e]?.mainCategory?.toLowerCase())
      ) {
        draggedItem = { categoryGroup: "completeWear", ...curationsList[e] };
      } else {
        if (["beauty", "accessories"].includes(curationsList[e]?.mainCategory?.toLowerCase())) {
          draggedItem = { categoryGroup: curationsList[e].category, ...curationsList[e] };
        } else {
          draggedItem = { categoryGroup: curationsList[e]?.mainCategory, ...curationsList[e] };
        }
      }
    }
  };

  const customCurationData = (data, index) => {
    let customData = {};
    let styleMeFilterData = styleMeCuration.filter((item) => Object.keys(item) !== index);
    customData[index] = data[index];
    const setCustomData = styleMeFilterData.concat(customData);
    return setCustomData;
  };

  const onDropCurationContainer = (e) => {
    const customDataSave = curationsData;

    draggedItem.mainCategory = linearCategories[draggedItem.category];

    if (draggedItem.categoryGroup === "undefined") {
      if (
        ["skirts", "shorts", "pants", "denim"].includes(draggedItem?.mainCategory?.toLowerCase())
      ) {
        draggedItem.categoryGroup = "bottomWear";
      } else if (["tops", "shirts"].includes(draggedItem?.mainCategory?.toLowerCase())) {
        draggedItem.categoryGroup = "topWear";
      } else if (
        [
          "dresses",
          "loungewear",
          "beachwear",
          "activewear",
          "miscellaneous",
          "jumpsuit",
          "suits",
        ].includes(draggedItem?.mainCategory?.toLowerCase())
      ) {
        draggedItem.categoryGroup = "completeWear";
      } else {
        if (["beauty", "accessories"].includes(draggedItem?.mainCategory?.toLowerCase())) {
          draggedItem.categoryGroup = draggedItem.category;
        } else {
          draggedItem.categoryGroup = draggedItem.mainCategory;
        }
      }
    }
    draggedItem.categoryGroup = draggedItem.mainCategory.includes("Top")
      ? "topWear"
      : draggedItem.categoryGroup;

    const checkExistItem = customDataSave[e]?.curations?.filter(
      (data) => data?.categoryGroup?.toLowerCase() === draggedItem?.categoryGroup?.toLowerCase(),
    );

    const existingCategory = customDataSave[e]?.curations?.reduce((category, cur) => {
      category.push(cur.categoryGroup);
      return category;
    }, []);

    if (checkExistItem?.length > 0) {
      let temp = structuredClone(customDataSave);
      let swapData = temp[e].curations.map((data) => {
        if (data?.categoryGroup?.toLowerCase() === draggedItem?.categoryGroup?.toLowerCase()) {
          data = draggedItem;
        }
        return data;
      });
      temp[e].curations = swapData.filter(
        (obj, index) => swapData.findIndex((item) => item.id === obj.id) === index,
      );
      setCurationsData(temp);
      dispatch(setStyleMeCuration(customCurationData(temp, e)));
      // openToast("error", "Failed!", "Restricted Item");
    } else if (
      ((existingCategory.includes("topWear") ||
        existingCategory.includes("bottomWear") ||
        existingCategory.includes("Sweaters")) &&
        draggedItem.categoryGroup === "completeWear") ||
      (existingCategory.includes("completeWear") &&
        (draggedItem.categoryGroup === "topWear" ||
          draggedItem.categoryGroup === "bottomWear" ||
          draggedItem.categoryGroup === "Sweaters"))
    ) {
      // this code needs optimization **
      let temp = structuredClone(curationsData);
      let swapData = temp[e].curations.map((data) => {
        if (
          ((data?.categoryGroup?.includes("topWear") ||
            data?.categoryGroup?.includes("bottomWear") ||
            data?.categoryGroup?.includes("Sweaters")) &&
            draggedItem.categoryGroup === "completeWear") ||
          (data?.categoryGroup?.includes("completeWear") &&
            (draggedItem.categoryGroup === "topWear" ||
              draggedItem.categoryGroup === "bottomWear" ||
              draggedItem.categoryGroup === "Sweaters"))
        ) {
          data = draggedItem;
        }
        return data;
      });
      temp[e].curations = swapData.filter(
        (obj, index) => swapData.findIndex((item) => item.id === obj.id) === index,
      );
      setCurationsData(temp);
      dispatch(setStyleMeCuration(customCurationData(temp, e)));
      // openToast("error", "Failed!", "Restricted Item");
    } else {
      let temp = structuredClone(curationsData);
      temp[e]?.curations?.push(draggedItem);
      // curationsData[e]?.curations?.push(draggedItem);
      if (e === selectedIndex) setSelectedIndex(0);
      // setCurationsData([...curationsData.splice(e, 1), ...curationsData]);
      setCurationsData([...temp]);
      dispatch(setStyleMeCuration(customCurationData(temp, e)));
    }
  };

  const genrateCuration = useMemo(() => {
    const newCategoryData = getCurationData();
    const categoryKeys = Object.keys(newCategoryData);
    let tempCurationsData;
    if (
      (categoryKeys.includes("completeWear") &&
        (categoryKeys.includes("bottomWear") ||
          categoryKeys.includes("topWear") ||
          categoryKeys.includes("sweaters"))) ||
      (categoryKeys.includes("completeWear") &&
        categoryKeys.includes("bottomWear") &&
        categoryKeys.includes("topWear") &&
        categoryKeys.includes("sweaters"))
    ) {
      const [obj1, obj2] = makeCurationCategory(newCategoryData);
      const all = Object.values(obj1);
      const all1 = Object.values(obj2);
      const combinations1 = all.length > 0 ? combineArrays(all) : [];
      const combination2 = all1.length > 0 ? combineArrays(all1) : [];
      tempCurationsData =
        combinations1.length > 0 && combination2.length > 0
          ? [...combinations1, ...combination2]
          : combinations1.length > 0 && combination2.length === 0
          ? combinations1
          : combinations1.length === 0 && combination2.length > 0
          ? combination2
          : [];
    } else {
      const all = Object.values(newCategoryData);
      tempCurationsData = combineArrays(all);
    }

    if (tempCurationsData.length > 0) {
      let newCurationsData = tempCurationsData?.map((item) => ({
        curations: [...item],
        isLiked: false,
        isAdded: false,
        selected: false,
      }));
      setCurationsData(newCurationsData);
    }
  }, [curationsList, styleMeloading]);

  useEffect(() => {
    if (updateStyleMeFlag || curationsFlags.updateStyleMeDb) {
      updateToStyleDB(curationsList);
      setUpdateStyleMeFlag(false);
      dispatch(setFlags({ updateStyleMeDb: false }));
    }
    genrateCuration;

    setInspiredData(getRandomInspiredData?.getRandomInspired?.data);
  }, [curationsList, loading]);

  function showMenuPopup(category, value) {
    let mainCategory = getMainCategory(categoriesList, category);
    let categoryName = getChildCategories(categoriesList, category, mainCategory);
    getItems({ variables: { category: categoryName } });
    setCategoryName(category);
    setMainCategoryName(mainCategory);
    setShowCategoryPopup(value);
  }
  useEffect(() => {
    if (ItemsList && ItemsList.getItemsFromCloset && ItemsList.getItemsFromCloset.length > 0) {
      let itemsList = ItemsList.getItemsFromCloset.map((data) => ({
        ...data,
        isSelected: false,
        isLiked: false,
      }));
      const uniqueCur = itemsList?.map((item) =>
        curationsList?.findIndex((cur) => cur.id === item.id),
      );
      uniqueCur?.forEach((item, index) => {
        if (item >= 0) {
          itemsList[index] = { ...curationsList[item], isSelected: true, isLiked: false };
        }
        setProductsList(itemsList);
      });
    } else {
      setProductsList([]);
    }
  }, [ItemsList, curationsList]);

  function addItemModalButton(value) {
    setShowConfirmationModal(false);
    if (value) {
      setCurationsData([]);
      dispatch(clearCuration());
      dispatch(clearStyleMe());
      updateToStyleDB([], []);
      dispatch(setStyleMeCuration([]));
      setCurationsData([]);
    }
  }

  function clearCurationData() {
    setShowConfirmationModal(true);
  }
  function removeSelectedItem(itemIndex) {
    dispatch(deleteCuration(itemIndex));
    setUpdateStyleMeFlag(true);
  }
  function removeAddCurationItem(itemIds, index) {
    curationsData[index].isAdded = false;
    setCurationsData([...curationsData]);
    if (allCurationData.length > 0) {
      let curationData = [...allCurationData];
      let curationId;
      curationData.forEach((item) => {
        let data = 0;
        if (item.curation.length === itemIds.length) {
          item.curation.forEach((i) => {
            if (itemIds.includes(i)) data++;
          });
        }
        if (data === itemIds.length) {
          curationId = item.id;
        }
      });

      if (curationId) {
        deleteCurationList({
          variables: {
            curationId: curationId.toString(),
          },
        });
      }
    }
  }

  function removeCurationItem(itemID, index) {
    const customDataSave = curationsData;
    let temp = structuredClone(customDataSave);
    if (temp[index]?.curations) {
      temp[index].curations = temp[index].curations.filter((citem) => citem.id !== itemID);
      // temp[index]?.curations?.splice(itemIndex, 1);
    } else {
      temp[index].curations = temp[index].curations.filter((citem) => citem.id !== itemID);
    }
    // temp[index]?.curations?.splice(itemIndex, 1);
    if (index === selectedIndex) setSelectedIndex(0);
    // setCurationsData([...curationsData.splice(index, 1), ...curationsData]);
    setCurationsData([...temp]);
    dispatch(setStyleMeCuration(customCurationData(temp, index)));
  }

  useEffect(() => {
    if (
      getCurationListData?.getCurationsList?.data &&
      getCurationListData?.getCurationsList?.data?.length > 0
    ) {
      setAllCurationData(getCurationListData?.getCurationsList?.data);
    }
  }, [getCurationListData, updateCurationData, addCurationData]);

  function removeFromFavorite(itemIds, index) {
    curationsData[index].isLiked = false;
    setCurationsData([...curationsData]);
    if (allCurationData.length > 0) {
      let curationData = [...allCurationData];
      let curationId;
      curationData.forEach((item) => {
        let data = 0;
        if (item.curation.length === itemIds.length) {
          item.curation.forEach((i) => {
            if (itemIds.includes(i)) data++;
          });
        }
        if (data === itemIds.length) {
          curationId = item.id;
        }
      });

      if (curationId) {
        deleteFavorite({
          variables: {
            favoriteData: {
              curationId: curationId.toString(),
            },
          },
        });

        if (curationsData[index].isAdded) {
          UpdateCuration({
            variables: {
              curationData: {
                curationId: curationId.toString(),
                isLiked: false,
              },
            },
          });
        } else {
          deleteCurationList({
            variables: {
              curationId: curationId.toString(),
            },
          });
        }
      }
    }
  }

  function removeFromFavGetInspired(curationId, index) {
    if (curationId) {
      deleteFavorite({
        variables: {
          favoriteData: {
            curationId: curationId.toString(),
          },
        },
      });

      if (inspiredData[index].isLiked) {
        UpdateCuration({
          variables: {
            curationData: {
              curationId: curationId.toString(),
              isLiked: false,
            },
          },
        });
      } else {
        deleteCurationList({
          variables: {
            curationId: curationId.toString(),
          },
        });
      }
    }
    let temp = JSON.parse(JSON.stringify(inspiredData));
    temp[0].isLiked = false;
    setInspiredData(temp);
  }

  function handleMenuClick(e) {
    showMenuPopup(e, true);
    handleClose();
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleClick(event) {
    return setAnchorEl(event.currentTarget);
  }

  function deleteCurationItem() {
    setCurationsData(curationsData.filter((item, i) => i !== selectedIndex));
    setSelectedIndex(null);
  }

  const handleSelected = (index) => {
    setSelectedIndex((pre) => (pre === index ? null : index));
  };

  function addNewCurationsItem() {
    if (sliderRef && sliderRef.current) {
      sliderRef.current.slickGoTo(0);
    }
    setCurationsData([
      {
        curations: [],
        isLiked: false,
        isAdded: false,
        selected: false,
      },
      ...curationsData,
    ]);
    openToast("success", "Successful!", "Curation added");
  }

  function handleResize() {
    if (window.innerWidth > 450) {
      setSettings({ ...settings, slidesToShow: Math.floor(window.innerWidth / 430) });
    } else if (window.innerWidth <= 450) {
      setSettings({ ...settings, slidesToShow: Math.floor(window.innerWidth / 330) });
    }
  }

  function setCurationId(id) {
    setCurationsData(
      curationsData.map((item, index) => {
        if (index === curationIndex) item.id = id;
        return item;
      }),
    );
  }

  function addToIsAdded(id, index) {
    UpdateCuration({
      variables: {
        curationData: {
          curationId: id,
          isAdded: true,
        },
      },
    });

    setCurationsData(
      curationsData.map((item, i) => {
        if (i === index) {
          item.id = id;
          item.isAdded = true;
        }
        return item;
      }),
    );
  }

  const [curationType, setCurationType] = useState("");

  function handleCurationType(value) {
    setCurationType(value);
  }

  const handleSlideChange = (slide) => {
    setSlide(slide);
  };

  return (
    <MainContainer>
      <Header>
        <p>PICK YOUR STYLE</p>
      </Header>

      <ItemContainer>
        <ContainerHeader>
          <Label>SELECTED ITEMS</Label>
          {curationsList?.length ? (
            <Clear>
              <p onClick={clearCurationData}>Clear All</p>
            </Clear>
          ) : (
            ""
          )}
        </ContainerHeader>
        {showConfirmationModal && (
          <ModalPortal>
            <Modal type="clearSelected" handleButtonClick={addItemModalButton} svgType="circle" />
          </ModalPortal>
        )}
        {!curationsList?.length && (
          <div className={classes.dataNotFound}>Click on add button to select items</div>
        )}
        <ContainerList>
          {curationsList?.length
            ? curationsList?.map((item, index) => (
                <SelectedItems
                  mainCat={item.mainCategory}
                  itemCat={item.category}
                  item={item?.src}
                  index={index}
                  key={index}
                  onDragStartItem={onDragStartItem}
                  removeSelectedItem={removeSelectedItem}
                />
              ))
            : undefined}

          <div className={classes.addItemButton}>
            <div className={classes.addbutton} onClick={handleClick}>
              <IconComponent iconTitle={"AddSquare"} />
            </div>
            <CategoriesDropDown
              selectedCategory={"shirts"}
              handleClose={handleClose}
              handleOpen={handleClick}
              categoriesList={categoriesList}
              open={open}
              handleMenuClick={handleMenuClick}
            />
          </div>
        </ContainerList>
      </ItemContainer>

      <Curations>
        <ContainerHeader style={{ justifyContent: "space-between" }}>
          <Label>MY CURATIONS</Label>
          <Pagination
            curationsData={curationsList?.length ? curationsData?.length : 0}
            sliderRef={sliderRef}
            slide={slide}
          />
        </ContainerHeader>
        {curationsList?.length > 0 ? (
          <Slider ref={sliderRef} {...settings} afterChange={handleSlideChange}>
            {curationsData?.map((outfits, index) => (
              <CurationsContainer
                key={`curation_${index}`}
                outfits={outfits?.curations}
                linearCategories={linearCategories}
                id={outfits?.id}
                index={index}
                showAddCurationsPopup={showAddCurationsPopup}
                addOutfits={addOutfits}
                isAdded={outfits?.isAdded}
                isLiked={outfits?.isLiked}
                isSelected={index === selectedIndex}
                handleSelected={handleSelected}
                onDropCurationContainer={onDropCurationContainer}
                removeCurationItem={removeCurationItem}
                addCurationToFavorite={addCurationToFavorite}
                addToIsAdded={addToIsAdded}
                removeFromFavorite={removeFromFavorite}
                removeAddCurationItem={removeAddCurationItem}
              />
            ))}
          </Slider>
        ) : (
          <div className={classes.dataNotFound}>No Items Found Here</div>
        )}
        {curationsList?.length > 0 && (
          <div className={classes.curationActions}>
            <IconComponent
              iconTitle="Trash"
              width={"100%"}
              height={"100%"}
              className={classes.actionMyCuration}
              style={{
                opacity: selectedIndex !== null ? 1 : 0.5,
                pointerEvents: selectedIndex !== null ? "all" : "none",
              }}
              onClick={deleteCurationItem}
            />
            <IconComponent
              iconTitle="AddCircle"
              width={"100%"}
              height={"100%"}
              className={classes.actionMyCuration}
              onClick={addNewCurationsItem}
            />
          </div>
        )}
        {addCurationsPopup && (
          <AddCurations
            favoriteDataList={favoriteDataList}
            allCurationData={allCurationData}
            outfits={outFitsDetails}
            showAddCurationsPopup={showAddCurationsPopup}
            addCurationsDetails={addCurationsDetails}
          />
        )}
        {showCategoryPopup && (
          <AddItemToCuration
            categoryProductsList={productsList}
            categoryName={categoryName}
            mainCategoryName={mainCategoryName}
            handleResize={handleResize}
            setShowCategoryPopup={setShowCategoryPopup}
            setUpdateStyleMeFlag={setUpdateStyleMeFlag}
          />
        )}
      </Curations>

      <Curations>
        <ContainerHeader>
          <Label>GET INSPIRED</Label>
        </ContainerHeader>
      </Curations>
      <InspiredContainer>
        {inspiredData?.length ? (
          inspiredData
            ?.slice(0, 2)
            .map(
              (outfits, index) =>
                outfits?.curations?.length > 0 && (
                  <Inspired
                    outfits={outfits?.curations}
                    key={index}
                    index={index}
                    handleCurationType={handleCurationType}
                    showAddCurationsPopup={showAddCurationsPopup}
                    addCurationToFavorite={addCurationToFavorite}
                    addOutfits={addOutfits}
                    isLiked={outfits?.isLiked}
                    removeFromFavGetInspired={removeFromFavGetInspired}
                    id={outfits?.id}
                  />
                ),
            )
        ) : (
          <div className={classes.dataNotFound}>No Items Found Here</div>
        )}
      </InspiredContainer>
      {/* curationLoading || addFavoriteLoading || getFavoriteLoading || UpdateCurationLoading */}
      {(curationLoading || addFavoriteLoading || UpdateCurationLoading) && <Loader />}
    </MainContainer>
  );
}
