import styled from "styled-components";
import globalStyles from "../../styles/global";

const { mediaQueries } = globalStyles;

const ToggleBody = styled.div`
  width: 48px;
  cursor: pointer;

  @media (${mediaQueries.mobile}) {
    width: 36px;
  }
`;

const ToggleArea = styled.div`
  position: relative;
  width: 48px;
  height: 24px;
  border-radius: 12px;
  background-color: ${(props) => (props.active ? "#000000" : "#CACACA")};
  transition: all 0.2s;

  @media (${mediaQueries.mobile}) {
    width: 36px;
    height: 18px;
  }
`;

const ToggleElement = styled.div`
  position: absolute;
  top: 4px;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background-color: #ffffff;
  left: ${(props) => (props.active ? "28px" : "4px")};
  transition: all 0.2s;

  @media (${mediaQueries.mobile}) {
    height: 12px;
    width: 12px;
    top: 3px;
    left: ${(props) => (props.active ? "20px" : "4px")};
  }
`;

export default function Toggle({ onClick, active }) {
  return (
    <ToggleBody onClick={onClick}>
      <ToggleArea active={active}>
        <ToggleElement active={active} />
      </ToggleArea>
    </ToggleBody>
  );
}
