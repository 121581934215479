import { useEffect, useState } from "react";
import {
  ButtonContainter,
  CircleSvg,
  MainText,
  ModalContainer,
  Overflow,
  SvgContainer,
} from "../../components/modal";
import Button from "../../components/fields/button";
import IconComponent from "../../components/fields/icon";
import { InputField } from "../../components/signin";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { validateTags } from "../../components/helpers/validate";
import { MenuContainer } from "../../containers/StyleMe";

export default function EditCuration(props) {
  const { updateCurationsDetails, outfits, showAddCurationsPopup, showCuration, sortedCuration } =
    props;
  const [curationName, setCurationName] = useState("");
  const [curationLook, setCurationLook] = useState("");
  const [curationTags, setCurationTags] = useState("");
  const [inputCurationLookError, setInputCurationLookError] = useState(false);

  useEffect(() => {
    setCurationName(outfits.curationName);
    setCurationLook(outfits.curationLook);
    setCurationTags(outfits.curationTags);
  }, [outfits]);

  function handleChoiceResult(value) {
    if (
      value &&
      (curationName !== outfits.curationName ||
        curationLook !== outfits.curationLook ||
        curationTags !== outfits.curationTags)
    ) {
      if (curationLook === "") {
        setInputCurationLookError(true);
      } else {
        const curationObj = { curationId: outfits.id.toString(), curationName, curationLook };
        if (!curationObj.curationName) {
          let count = 0;
          if (sortedCuration) {
            for (let cur of sortedCuration) {
              if (cur.curationName.includes(curationObj.curationLook)) {
                let curationNumber = cur.curationName.split("_")[1];
                if (count <= Number(curationNumber)) count = Number(curationNumber) + 1;
                else if (cur.curationName === curationObj.curationLook && count === 0) count = 1;
              }
            }
          }
          curationObj.curationName =
            count > 0 ? curationObj.curationLook + "_" + count : curationObj.curationLook;
        }
        if (curationTags) {
          curationObj.curationTags = curationTags;
        }
        updateCurationsDetails(curationObj);
        showAddCurationsPopup(false);
        showCuration(false);
      }
    } else {
      showAddCurationsPopup(false);
      showCuration(false);
    }
  }

  function handleCurationNameChange(e) {
    setCurationName(e.target.value);
  }
  function handleCurationLookChange(e) {
    if (inputCurationLookError) {
      setInputCurationLookError(false);
    }
    setCurationLook(e.target.value);
  }

  function handleCurationTagsChange(e) {
    let value = e.target.value;

    if (
      (value.length === 1 && value === " ") ||
      (value.charAt(value.length - 2) === " " && value.charAt(value.length - 1) === " ")
    ) {
      return;
    } else if (value.length === 1 && value !== "#") {
      value = "#" + value;
    } else if (value.charAt(value.length - 2) === " " && value.charAt(value.length - 1) !== "#") {
      value = value.substring(0, value.length - 1) + "#" + value.charAt(value.length - 1);
    }

    // to handle when user paste the tags
    if (value.match(/^[^#]|\s\s+|\s+[^#]/gm)) {
      value = validateTags(value.trim());
    }

    setCurationTags(value);
  }

  const lookType = [
    "Date Night",
    "Wine Country",
    "Night Out",
    "Custom Look",
    "Night Out - Casual",
    "Get Away",
    "Travel Casual",
    "Business Casual",
    "Business Formal",
    "Cocktail Attire",
    "Black Tie",
    "Beach Day",
    "Brunch Time",
    "Outdoor Adventure",
    "Summer - Dressy",
    "Summer - Casual",
    "Winter Wonderland",
    "Special Event",
    "Everyday Look",
    "Boho Chic",
    "Autumn Cozy",
    "Autumn Chic",
    "Fit Life",
    "Sportswear",
  ];

  return (
    <Overflow>
      <ModalContainer>
        <SvgContainer>
          <CircleSvg>
            <IconComponent iconTitle={"InfoCirclePlan"} />
          </CircleSvg>
        </SvgContainer>

        <MainText>{"Information"}</MainText>
        <MenuContainer>
          <p>
            {"Select Look: "}
            <span>{"*"}</span>
          </p>
          <FormControl fullWidth>
            <Select value={curationLook} onChange={handleCurationLookChange} displayEmpty>
              {lookType.map((look, index) => (
                <MenuItem key={`look-${index}`} value={look}>
                  {look}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {inputCurationLookError && (
            <div className="errorText">
              <IconComponent iconTitle={"ErrorBoldIcon"} />
              <p>Please select looks</p>
            </div>
          )}
        </MenuContainer>
        <InputField
          labelName={"Name"}
          name="name"
          value={curationName || ""}
          onChange={handleCurationNameChange}
          type="text"
          errorText={
            sortedCuration.find(
              (item) => item.curationName === curationName && item.id !== outfits.id,
            )
              ? "Curation Name Already Exists!"
              : ""
          }
        />
        <InputField
          labelName={"Tags"}
          name="tags"
          value={curationTags || ""}
          onChange={handleCurationTagsChange}
          type="text"
          placeholder=""
        />

        <ButtonContainter>
          <Button
            buttontype="primary"
            size="default"
            onClick={() => handleChoiceResult(true)}
            disabled={
              !!sortedCuration.find(
                (item) => item.curationName === curationName && item.id !== outfits.id,
              )
            }
          >
            {"Save"}
          </Button>
          <Button buttontype="secondary" size="default" onClick={() => handleChoiceResult(false)}>
            {"Cancel"}
          </Button>
        </ButtonContainter>
      </ModalContainer>
    </Overflow>
  );
}
