import ActivewearNeutral from "./activewear_neutral.png";
import AccessoriesNeutral from "./accessories_neutral.png";
import BeautyNeutral from "./beauty_neutral.png";
import BeachwearNeutral from "./beachwear_neutral.png";
import DenimNeutral from "../women/denim_women.png";
import DressesNeutral from "./dresses_neutral.png";
import LoungewearNeutral from "../women/loungewear_women.png";
import MiscellaneousNeutral from "../men/miscellaneous_men.png";
import OuterwearNeutral from "./outerwear_neutral.png";
import PantsNeutral from "./pants_neutral.png";
import ShirtsNeutral from "../men/shirts_men.png";
import ShoesNeutral from "./shoes_neutral.png";
import ShortsNeutral from "../women/shorts_women.png";
import SkirtsNeutral from "../women/skirts_women.png";
import SweatersNeutral from "./sweaters_neutral.png";
import TopsNeutral from "./tops_neutral.png";
import HangerNeutral from "./hanger_neutral.png";
import HomeNeutral from "./home_neutral.png";

export default {
  ActivewearNeutral,
  AccessoriesNeutral,
  BeachwearNeutral,
  BeautyNeutral,
  DenimNeutral,
  DressesNeutral,
  LoungewearNeutral,
  MiscellaneousNeutral,
  OuterwearNeutral,
  PantsNeutral,
  ShirtsNeutral,
  ShoesNeutral,
  ShortsNeutral,
  SkirtsNeutral,
  SweatersNeutral,
  TopsNeutral,
  HangerNeutral,
  HomeNeutral,
};
