import { useState } from "react";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import ImageUpload from "./ImageUpload";
import { useStyles } from "./styles";
import "./styles.css";
import CategoriesDropDown from "../../../../components/categoriesDropDown";
import noImageLogo from "../../../../assets/images/no-image.jpg";
import IconComponent from "../../../../components/fields/icon";
import { validatePrice, validateTags } from "../../../../components/helpers/validate";

export default function ProductDetails(props) {
  const {
    item,
    index,
    selectedItems,
    setSelectedItems,
    handleSelectItem,
    inputErrors,
    setInputErrors,
    categoriesList,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();

  function handleClick(event) {
    return setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleMenuClick(e) {
    selectedItems[index] = { ...item, category: e };
    setSelectedItems([...selectedItems]);

    if (inputErrors[index]["category"]) {
      inputErrors[index]["category"] = false;
      setInputErrors(JSON.parse(JSON.stringify(inputErrors)));
    }
    handleClose();
  }

  function handleValueChange(value, type) {
    if (type === "size") {
      if (value.length > 7) {
        return;
      }
      value = value.toUpperCase();
    } else if (type === "tags") {
      if (
        (value.length === 1 && value === " ") ||
        (value.charAt(value.length - 2) === " " && value.charAt(value.length - 1) === " ")
      ) {
        return;
      } else if (value.length === 1 && value !== "#") {
        value = "#" + value;
      } else if (value.charAt(value.length - 2) === " " && value.charAt(value.length - 1) !== "#") {
        value = value.substring(0, value.length - 1) + "#" + value.charAt(value.length - 1);
      }
      // to handle when user paste the tags
      if (value.match(/^[^#]|\s\s+|\s+[^#]/gm)) {
        value = validateTags(value.trim());
      }
    } else if (type === "price") {
      if (value.length === 1 && value === ".") {
        value = "$0" + value;
      } else if (value.length === 1 && value.match(/\d/g)) {
        value = "$" + value;
      } else if (value !== "" && !validatePrice(value)) {
        return;
      }
    }

    selectedItems[index][type] = value;
    setSelectedItems(structuredClone(selectedItems));
    if (inputErrors[index][type]) {
      inputErrors[index][type] = false;
      setInputErrors(JSON.parse(JSON.stringify(inputErrors)));
    }
  }
  const handleImageSelected = (selectedImage) => {
    handleValueChange(selectedImage.fileImage, "src");
  };

  return (
    <div className={classes.itemBody}>
      <div className={classes.details}>
        <div className={classes.imgWrapper}>
          <IconComponent
            iconTitle="CrossIcon"
            className={classes.crossIcon}
            onClick={(event) => {
              event.stopPropagation();
              handleSelectItem(item, item.index - 1);
            }}
          />
          <img
            className={classes.imgBody}
            src={`${
              item?.src?.name
                ? URL.createObjectURL(item.src)
                : item.src
            }`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = noImageLogo;
            }}
          />
          <ImageUpload className={classes.imgBody} handleImageSelected={handleImageSelected} />
        </div>
        <div className={classes.inputLabel}>{"Item Name:"}</div>
        <div className={`${classes.inputWrapper} ${classes.validInputWrapper}`}>
          <input
            className={classes.textInput}
            type="text"
            id={`item${index}`}
            value={item.itemName ?? ""}
            onChange={(e) => handleValueChange(e.target.value, "itemName")}
          />
        </div>

        <div className={classes.inputLabel}>
          {"Category: "}
          <span>{"*"}</span>
        </div>
        <div
          id={`category${index}`}
          className={`${classes.selectInputWrapper} ${
            inputErrors[index].category ? classes.errorInputWrapper : classes.validInputWrapper
          }`}
        >
          <CategoriesDropDown
            selectedCategory={item.category ?? ""}
            handleOpen={handleClick}
            handleClose={handleClose}
            open={open}
            categoriesList={categoriesList}
            handleMenuClick={handleMenuClick}
          />
        </div>

        <div className={classes.infoWrapper}>
          <div className={classes.inputLabel}>{"Size:"}</div>
          <a
            data-tooltip-id="sizeInfo-tooltip"
            data-tooltip-content='If size is not available please enter "NA"'
          >
            <IconComponent iconTitle={"InfoIcon"} />
          </a>
        </div>
        <div className={`${classes.inputWrapper} ${classes.validInputWrapper}`}>
          <input
            className={classes.textInput}
            type="text"
            id={`size${index}`}
            value={item.size ?? ""}
            onChange={(e) => handleValueChange(e.target.value, "size")}
          />
        </div>
        <div className={classes.inputLabel}>{"Brand Name:"}</div>
        <div className={`${classes.inputWrapper} ${classes.validInputWrapper}`}>
          <input
            className={classes.textInput}
            type="text"
            id={`brand${index}`}
            value={item.brand ?? ""}
            onChange={(e) => handleValueChange(e.target.value, "brand")}
          />
        </div>
        <div className={classes.inputLabel}>{"Store Name:"}</div>
        <div className={`${classes.inputWrapper} ${classes.validInputWrapper}`}>
          <input
            className={classes.textInput}
            type="text"
            id={`store${index}`}
            value={item.store ?? ""}
            onChange={(e) => handleValueChange(e.target.value, "store")}
          />
        </div>

        <div className={classes.inputLabel}>{"Color:"}</div>
        <div className={`${classes.inputWrapper} ${classes.validInputWrapper}`}>
          <input
            className={classes.textInput}
            type="text"
            id={`color${index}`}
            value={item.color ?? ""}
            onChange={(e) => handleValueChange(e.target.value, "color")}
          />
        </div>

        <div className={classes.inputLabel}>{"Price:"}</div>
        <div className={`${classes.inputWrapper} ${classes.validInputWrapper}`}>
          <input
            className={classes.textInput}
            type="text"
            id={`price${index}`}
            value={item.price ?? ""}
            onChange={(e) => handleValueChange(e.target.value, "price")}
          />
        </div>

        <div className={classes.inputLabel}>{"Date Purchased:"}</div>
        <div className={`${classes.inputWrapper} ${classes.validInputWrapper}`}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              className={classes.datePicker}
              inputFormat="MM/DD/YYYY"
              disableFuture
              orientation="portrait"
              value={item.datePurchased ?? null}
              onChange={(value) => handleValueChange(value, "datePurchased")}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>

        <div className={classes.inputLabel}>{"Link to Item:"}</div>
        <div className={`${classes.inputWrapper} ${classes.validInputWrapper}`}>
          <input
            className={classes.textInput}
            type="text"
            id={`productLink${index}`}
            value={item.productLink ?? ""}
            onChange={(e) => handleValueChange(e.target.value, "productLink")}
          />
        </div>

        <div className={classes.inputLabel}>{"Tags:"}</div>
        <div className={`${classes.inputWrapper} ${classes.validInputWrapper}`}>
          <input
            className={classes.textInput}
            type="text"
            id={`tags${index}`}
            value={item.tags ?? ""}
            placeholder="#tag_name1 #tag_name2"
            onChange={(e) => handleValueChange(e.target.value, "tags")}
          />
        </div>
      </div>
    </div>
  );
}
