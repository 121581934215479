import styled from "styled-components";
import GlobalStyles from "../../styles/global";

const { mediaQueries } = GlobalStyles;

export const Overflow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 20;
`;

export const ModalContainer = styled.div`
  font-family: var(--primaryRegularFont);
  position: absolute;
  width: 478px;
  min-height: 400px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  padding: 35px;
  display: flex;
  flex-direction: column;
  background: #ffffff;

  @media screen and (${mediaQueries.tablet}) {
    width: 400px;
    min-height: 370px;
    // height: 450px;
    padding: 18px 24px;
  }

  @media screen and (${mediaQueries.mobile}) {
    width: 300px;
    padding: 20px 12px;
    min-height: 330px;
    max-height: 80%;
  }
`;

export const MainText = styled.div`
  font-family: var(--primaryHeaderFont);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  // line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  justify-content: center;
  color: #000000;
  margin-bottom: 16px;

  @media screen and (${mediaQueries.tablet}) {
    font-size: 22px;
  }

  @media (${mediaQueries.mobile}) {
    margin-bottom: 12px;
    font-size: 20px;
  }
`;

export const SubText = styled.div`
  font-family: var(--primaryRegularFont);
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.03em;
  color: #000000;
  padding-bottom: 30px;

  @media screen and (${mediaQueries.tablet}) {
    font-size: 18px;
    line-height: 20px;
    padding-bottom: 20px;
  }

  @media screen and (${mediaQueries.mobile}) {
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 15px;
  }
`;

export const ButtonContainter = styled.div`
  display: flex;
  column-gap: 24px;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  margin-top: auto;
  margin-bottom: 15px;

  @media (${mediaQueries.mobile}) {
    padding-top: 20px;
  }
`;

export const SvgContainer = styled.div`
  width: 100%;
  // height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SmallSvgContainer = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CircleSvg = styled.div`
  background: #f7f7f7;
  height: 82px;
  width: 82px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  > i {
    > svg {
      height: 42px;
      width: 42px;
    }
  }

  @media (${mediaQueries.mobile}) {
    width: 48px;
    height: 48px;

    i {
      width: 24px;
      height: 24px;
    }
  }
`;
